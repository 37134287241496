import React, { useCallback, useMemo, useState } from 'react';
import Datatable from '@core/table/Datatable';
import GreenBadge from '@core/badges/GreenBadge';
import RedBadge from '@core/badges/RedBadge';
import { useTranslation } from 'react-i18next';
import CreateDelivery from '@components/Delivery/CreateDelivery';
import DeliveryService from '@services/DeliveryService';
import _ from 'lodash';
import DeliveryActions from '@components/Delivery/partials/DeliveryActions';

const DeliveriesIndex = ({ selectedTender, selectedLot }) => {
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState();
  const [updatedTable, setUpdatedTable] = useState(0);
  const [data, setData] = useState([]);
  const [multiplier, setMultiplier] = useState(0);

  const { t } = useTranslation();

  const unitConversion = useCallback(() => {
    if (selectedLot?.product === 'OIL') {
      return t('t');
    }
    return t('nm3');
  }, [selectedLot?.product, t]);

  const headers = useMemo(
    () => [
      {
        id: 'title',
        name: t('name'),
        cell: (row) => row.name,
        sortable: true
      },
      {
        id: 'amount',
        name: unitConversion(),
        cell: (row) => row.amount,
        sortable: true
      },
      {
        id: 'barrels',
        name: t('barrels'),
        cell: (row) => `${row.barrels.toFixed(2)}`,
        sortable: true
      },
      {
        id: 'totalPrice',
        name: t('totalPrice'),
        cell: (row) => `$ ${row.totalPrice.toFixed(2)}`,
        sortable: true
      },
      {
        id: 'status',
        name: t('status'),
        cell: (row) =>
          row.status === 'delivered' ? (
            <GreenBadge text={t('delivered')} />
          ) : (
            <RedBadge text={t('notDelivered')} />
          ),
        sortable: true,
        width: '150px'
      },
      {
        id: 'actions',
        name: t('actions'),
        cell: (row) => (
          <DeliveryActions
            selectedTender={selectedTender}
            selectedLot={selectedLot}
            multiplier={multiplier}
            delivery={row}
            onEdited={() => setUpdatedTable((prev) => prev + 1)}
            onDeleted={() => setUpdatedTable((prev) => prev + 1)}
            onUpdated={() => setUpdatedTable((prev) => prev + 1)}
            onSuccess={() => setUpdatedTable((prev) => prev + 1)}
          />
        )
      }
    ],
    [multiplier, selectedLot, selectedTender, t, unitConversion]
  );

  const getData = (params) => {
    setLoading(true);
    DeliveryService.all(params, selectedLot.id).then((response) => {
      const data = _.get(response, 'data.data');
      setMultiplier(data.multiplier);
      setMetaData(data.totalRecords);
      setData(data.items);
      setLoading(false);
    });
  };

  return (
    <div className="px-4">
      <div className=" bg-white rounded justify-between items-center">
        <div className="mx-3 my-5">
          <div className="flex w-1/2 sm:w-1/4 md:w-1/6 mt-5">
            <CreateDelivery
              multiplier={multiplier}
              selectedTender={selectedTender}
              label={`${t('addDelivery')}`}
              selectedLot={selectedLot}
              onAdded={() => setUpdatedTable((prev) => prev + 1)}
            />
          </div>
          <div className="hidden lg:block">
            <Datatable
              data={data}
              headers={headers}
              totalRows={metaData}
              getData={getData}
              isLoading={loading}
              extraDependencies={[updatedTable]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveriesIndex;
