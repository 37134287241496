import React from 'react';
import { useTranslation } from 'react-i18next';
import StatisticsCard from '@components/Home/partials/StatisticsCard';

const HomeOverview = ({ cards }) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="flex border-b-2 pt-8 pb-2 mb-8 font-semibold text-2xl">{t('overview')}</h1>
      <div className="flex space-x-4 pb-8">
        {cards?.map((card) => (
          <StatisticsCard title={t(card.title.toLowerCase())} data={card.listItems} />
        ))}
      </div>
    </>
  );
};

export default HomeOverview;
