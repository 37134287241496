import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const useHasPermission = ({ roles = [] }) => {
  const currentRole = useSelector((state) => _.get(state, 'meReducer.role', null));

  return useMemo(() => {
    return roles.length > 0 && currentRole && roles?.includes(currentRole);
  }, [currentRole, roles]);
};

export default useHasPermission;
