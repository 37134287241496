import React, { useEffect, useState } from 'react';
import ViewModal from '@core/modals/ViewModal';
import ViewButton from '@core/buttons/atoms/ViewButton';
import UserService from '@services/UserService';
import RowDetails from '@hoc/cruds/RowDetails';
import { useTranslation } from 'react-i18next';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { internalSystemRoles } from '@constants/roles';

const ShowUser = ({ user }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      UserService.find(user.id).then((response) => {
        setUserData(response.data.data.user);
      });
  }, [user.id, showModal]);

  return (
    <PermissionHandler roles={internalSystemRoles}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal show={showModal} title={`${t('overview')}`} onClose={closeModal}>
        <div className="flex flex-col mt-5">
          <RowDetails label={t('name')} value={userData.firstName} />
          <RowDetails label={t('lastName')} value={userData.lastName} />
          <RowDetails label={t('email')} value={userData.email} />
          <RowDetails label={t('phone')} value={userData.phone} />
          <RowDetails label={t('role')} value={userData.role} />
          <RowDetails label={t('businessUnitCode')} value={userData.businessUnitCode} />
          <RowDetails label={t('operatorCode')} value={userData.operatorCode} />
          <RowDetails label={t('tcrCode')} value={userData.tcrCode} />
          <RowDetails label={t('tcrMaintainerCode')} value={userData.tcrMaintainerCode} />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowUser;
