import React from 'react';
import AuthLayout from '@hoc/layouts/AuthLayout';
import ForgotPasswordForm from '@components/Auth/partials/ForgotPasswordForm';

const PasswordConfirmationPage = () => (
  <AuthLayout>
    <ForgotPasswordForm />
  </AuthLayout>
);

export default PasswordConfirmationPage;
