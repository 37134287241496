const loginConstraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_email'
    },
    email: {
      message: 'errors.invalid_email'
    }
  },
  password: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_password'
    }
  }
};
export default loginConstraints;
