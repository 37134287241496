const userDataConstraints = {
  firstName: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_name'
    }
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_lastname'
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_email'
    },
    email: {
      message: 'errors.invalid_email'
    }
  },
  phoneNumber: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_phone_number'
    }
  },
  businessUnitCode: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_business_unit_code'
    }
  },
  operatorCode: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_operator_code'
    }
  },
  tcrCode: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_tcr_code'
    }
  },
  tcrMaintainerCode: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_tcr_maintainer_code'
    }
  },
  role: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_role'
    }
  }
};
export default userDataConstraints;
