import React, { useEffect, useState } from 'react';
import ViewModal from '@core/modals/ViewModal';
import ViewButton from '@core/buttons/atoms/ViewButton';
import RowDetails from '@hoc/cruds/RowDetails';
import { useTranslation } from 'react-i18next';
import DocumentService from '@services/DocumentService';
import GreenBadge from '@core/badges/GreenBadge';
import RedBadge from '@core/badges/RedBadge';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { internalSystemRoles } from '@constants/roles';

const ShowDocument = ({ document }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal)
      DocumentService.find(document.id).then((response) => {
        setData(response.data.data.documentType);
      });
  }, [document.id, showModal]);

  return (
    <PermissionHandler roles={internalSystemRoles}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal show={showModal} title={`${t('overview')}`} onClose={closeModal}>
        <div className="flex flex-col mt-5">
          <RowDetails label={t('name')} value={data.name} />
          <RowDetails label={t('documentType')} value={t(data.usage)} />
          <RowDetails
            label={t('required')}
            value={
              data.required === true ? (
                <GreenBadge text={t('true')} />
              ) : (
                <RedBadge text={t('false')} />
              )
            }
          />
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowDocument;
