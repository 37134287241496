import React, { useEffect, useState } from 'react';
import DashboardService from '@services/DashboardService';
import { showError } from '@utils/helpers';
import HomeCharts from '@components/Home/HomeCharts';
import HomeOverview from '@components/Home/HomeOverview';
import useCanAccessRoute from '@hooks/useCanAccessRoute';
import { useNavigate } from 'react-router';

const HomePage = ({ roles = [] }) => {
  const navigate = useNavigate();
  const [cardsData, setCardsData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const { hasAccess, redirect } = useCanAccessRoute({ roles });

  const fetchStatistics = () => {
    DashboardService.statistics()
      .then(async (response) => {
        setCardsData(response.data.data.cards);
        setGraphData(response.data.data.graphics[1].listItems);
      })
      .catch((err) => {
        showError(err.response.data.message);
      });
  };

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirect);
    }
  }, [hasAccess, navigate, redirect]);

  useEffect(() => {
    fetchStatistics();
  }, []);

  return (
    <div className="flex flex-col px-12">
      <HomeCharts graphs={graphData} />
      <HomeOverview cards={cardsData} />
    </div>
  );
};

export default HomePage;
