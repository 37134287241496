import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TenderService from '@services/TenderService';
import _ from 'lodash';

const TendersSidebar = ({ selectedTender, setSelectedTender }) => {
  const { t } = useTranslation();

  const [closedTenders, setClosedTenders] = useState([]);

  useEffect((params) => {
    TenderService.all({ ...params, filters: { status: 'closed' } }).then((response) => {
      const closedTenders = _.get(response, 'data.data.items');
      setClosedTenders(closedTenders);
    });
  }, []);

  return (
    <aside className="h-screen sticky min-w-max w-84 bg-white overflow-y-scroll border">
      <div className="flex my-6 mx-3 justify-between">
        <label className="text-lg font-bold text-gray-800 border-b-2 border-b-gray-800">
          {t('closedTenders')}
        </label>
      </div>
      <div className="flex justify-between p-4">
        <h1>{t('tender')}</h1>
        <h6>{t('createdAt')}</h6>
      </div>
      <div>
        {closedTenders.map((closedTender) => (
          <div
            className={`flex justify-between py-2 px-4 hover:bg-gray-200 cursor-pointer ${
              closedTender.id === selectedTender?.id && 'bg-gray-200'
            }`}
            key={closedTender.id}
            onClick={() => setSelectedTender(closedTender)}>
            <h1 className="text-sm font-semibold">{closedTender.name}</h1>
            <h1 className="text-xs mt-1">{closedTender.publishDate}</h1>
          </div>
        ))}
      </div>
    </aside>
  );
};

export default TendersSidebar;
