const documentDataConstraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_name'
    }
  },
  usage: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_usage'
    }
  }
};
export default documentDataConstraints;
