import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GreenBadge from '@core/badges/GreenBadge';
import RedBadge from '@core/badges/RedBadge';
import RowDetails from '@hoc/cruds/RowDetails';
import RedButton from '@core/buttons/electrons/RedButton';
import GreenButton from '@core/buttons/electrons/GreenButton';
import DataTable from 'react-data-table-component';
import DefaultBadge from '@core/badges/DefaultBadge';
import TenderService from '@services/TenderService';
import { showError, showSuccess } from '@utils/helpers';
import { BiBadgeCheck } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { FaEnvelopeOpen, FaFileInvoice, FaTrash } from 'react-icons/fa';

const LotBid = ({
  lotBid,
  commission,
  isCommissionDirector,
  isCommission,
  status,
  onSuccess,
  hasWinner,
  onWinner
}) => {
  const { t } = useTranslation();

  const userId = useSelector((state) => _.get(state, 'meReducer.id', ''));

  const [extraClasses, setExtraClasses] = useState('');
  const [isWinner, setIsWinner] = useState(lotBid?.isWinner);
  const [currentVote, setCurrentVote] = useState('');
  const [hasContract, setHasContract] = useState(false);

  useEffect(() => {
    setIsWinner(lotBid.isWinner);
    const vote = lotBid?.votes?.find((vote) => vote?.commissionMember?.id === userId);
    setCurrentVote(vote?.status);
    if (lotBid?.contract) {
      setHasContract(true);
    }
  }, [lotBid, userId]);

  const voteBadge = useCallback(
    (id) => {
      const vote = lotBid?.votes?.find((vote) => vote?.commissionMember?.id === id);
      if (vote) {
        if (vote.status === 'accepted') {
          return <GreenBadge text={t('approved')} />;
        }
        return <RedBadge text={t('rejected')} />;
      }
      return <DefaultBadge text={t('hasNotVoted')} />;
    },
    [lotBid?.votes, t]
  );

  const headers = useMemo(
    () => [
      {
        id: 'fullName',
        name: t('fullName'),
        cell: (row) => `${row.firstName} ${row.lastName}`,
        sortable: true
      },
      {
        id: 'role',
        name: t('role'),
        cell: (row) => row.role,
        sortable: true,
        minWidth: '300px'
      },
      {
        id: 'vote',
        name: t('vote'),
        cell: (row) => voteBadge(row.id),
        sortable: true,
        width: '150px'
      }
    ],
    [t, voteBadge]
  );

  const onAccept = useCallback(() => {
    TenderService.confirmBid(lotBid.id)
      .then(() => {
        showSuccess(t('tenderCompletedSuccessfully'));
        onSuccess();
        onWinner(true);
        setIsWinner(true);
      })
      .catch((err) => {
        showError(err.response.data.message);
      });
  }, [lotBid.id, onSuccess, onWinner, t]);

  const onRevert = useCallback(() => {
    TenderService.revertBid(lotBid.id)
      .then(() => {
        showSuccess(t('tenderRevertedSuccessfully'));
        onSuccess();
        onWinner(false);
        setIsWinner(false);
      })
      .catch((err) => {
        showError(err.response.data.message);
      });
  }, [lotBid.id, onSuccess, onWinner, t]);

  const vote = useCallback(
    (vote) => {
      TenderService.vote(lotBid.id, vote)
        .then(() => {
          showSuccess(t('tenderVotedSuccessfully'));
          onSuccess();
          setCurrentVote(vote);
        })
        .catch((err) => {
          showError(err.response.data.message);
        });
    },
    [lotBid.id, onSuccess, t]
  );

  useEffect(() => {
    if (status === 'closed') {
      if (lotBid?.isWinner) {
        setExtraClasses('border-2 border-emerald-500 shadow-[2px_3px_5px_2px_rgba(16,185,129,1)]');
      } else setExtraClasses('border-red-500 shadow-[2px_3px_5px_2px_rgba(239,68,68,1)]');
    } else setExtraClasses('border-gray-300 shadow-lg');
  }, [isWinner, lotBid?.isWinner, status]);

  useEffect(() => {
    if (isWinner) {
      setExtraClasses('border-2 border-emerald-500 shadow-[2px_3px_5px_2px_rgba(16,185,129,1)]');
    } else setExtraClasses('border-gray-300 shadow-lg');
  }, [isWinner, lotBid?.isWinner, status]);

  const getContract = useCallback(() => {
    if (hasContract) {
      TenderService.downloadContract(lotBid?.contract?.id)
        .then((res) => {
          showSuccess(t('contractDownloadedSuccessfully'));
          onSuccess();
          const contractUrl = _.get(res, 'data.data.uploadedContract');
          window.open(contractUrl);
        })
        .catch((err) => {
          showError(err.response.data.message);
        });
    } else {
      TenderService.generateContract(lotBid?.id)
        .then(() => {
          showSuccess(t('contractSentSuccessfully'));
          onSuccess();
          setHasContract(true);
        })
        .catch((err) => {
          showError(err.response.data.message);
        });
    }
  }, [hasContract, lotBid?.contract?.id, lotBid?.id, onSuccess, t]);

  const sendContract = useCallback(() => {
    TenderService.sendContract(lotBid?.contract?.id)
      .then((res) => {
        showSuccess(t('contractGeneratedSuccessfully'));
        onSuccess();
        const contractUrl = _.get(res, 'data.data.uploadedContract');
        window.open(contractUrl);
      })
      .catch((err) => {
        showError(err.response.data.message);
      });
  }, [lotBid?.contract?.id, onSuccess, t]);

  const deleteContract = useCallback(() => {
    TenderService.deleteContract(lotBid?.contract?.id)
      .then(() => {
        showSuccess(t('contractDeletedSuccessfully'));
        onSuccess();
        setHasContract(false);
      })
      .catch((err) => {
        showError(err.response.data.message);
      });
  }, [lotBid?.contract?.id, onSuccess, t]);

  const renderContractActions = useCallback(() => {
    if (status === 'closed' && isWinner) {
      return (
        <div className="flex items-center w-3/4 justify-center mx-auto mt-5">
          {hasContract && (
            <div className="mr-3 w-full">
              <DefaultButton
                title={t('sendContract')}
                sm
                label={
                  <div className="flex items-center">
                    <FaEnvelopeOpen className="mr-2" />
                    {t('sendContract')}
                  </div>
                }
                bgColor="bg-teal-100"
                bgColorHover="hover:bg-teal-200"
                textColor="text-teal-700"
                onClick={sendContract}
              />
            </div>
          )}
          <DefaultButton
            title={hasContract ? t('downloadContract') : t('generateContract')}
            sm
            label={
              <div className="flex items-center">
                <FaFileInvoice className="mr-2" />
                {hasContract ? t('downloadContract') : t('generateContract')}
              </div>
            }
            bgColor="bg-orange-100"
            bgColorHover="hover:bg-orange-200"
            textColor="text-primary"
            onClick={getContract}
          />
          {hasContract && (
            <div className="ml-3 w-full">
              <DefaultButton
                title={t('deleteContract')}
                sm
                label={
                  <div className="flex items-center">
                    <FaTrash className="mr-2" />
                    {t('deleteContract')}
                  </div>
                }
                bgColor="bg-red-100"
                bgColorHover="hover:bg-red-200"
                textColor="text-red-700"
                onClick={deleteContract}
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  }, [deleteContract, getContract, hasContract, isWinner, sendContract, status, t]);

  const renderBadge = () => {
    if (status === 'closed') {
      if (isWinner) {
        return (
          <span className="ml-3 inline-flex items-center justify-center p-2 text-xs font-medium leading-4 rounded-full w-30 text-emerald-800 bg-emerald-100">
            <BiBadgeCheck className="text-xl mr-2" />
            <span>{t('winner')}</span>
          </span>
        );
      }
      return <RedBadge text={t('notWinner')} />;
    }
    return null;
  };

  const renderCommissionerActions = useCallback(() => {
    if (status !== 'closed' && isCommission) {
      if (currentVote === 'accepted') {
        return (
          <div className="inline-flex mt-2">
            <RedButton
              label={t('reject')}
              onClick={() => {
                vote('rejected');
              }}
            />
          </div>
        );
      }
      if (currentVote === 'rejected') {
        return (
          <div className="inline-flex mt-2">
            <GreenButton
              label={t('confirm')}
              onClick={() => {
                vote('accepted');
              }}
            />
          </div>
        );
      }
      return (
        <div className="inline-flex mt-2">
          <RedButton
            label={t('reject')}
            onClick={() => {
              vote('rejected');
            }}
          />
          <div className="ml-3" />
          <GreenButton
            label={t('confirm')}
            onClick={() => {
              vote('accepted');
            }}
          />
        </div>
      );
    }
    return null;
  }, [currentVote, isCommission, status, t, vote]);

  const renderCommissionerDirectorActions = useCallback(() => {
    if (status !== 'closed' && isCommissionDirector) {
      if (hasWinner === false) {
        return (
          <div className="inline-flex mt-2">
            <GreenButton label={t('accept')} onClick={onAccept} />
          </div>
        );
      }
      if (isWinner) {
        return (
          <div className="inline-flex mt-2">
            <RedButton label={t('revert')} onClick={onRevert} />
          </div>
        );
      }
      if (lotBid?.isWinner) {
        return (
          <div className="inline-flex mt-2">
            <RedButton label={t('revert')} onClick={onRevert} />
          </div>
        );
      }
      return null;
    }
    return null;
  }, [hasWinner, isCommissionDirector, isWinner, lotBid?.isWinner, onAccept, onRevert, status, t]);

  return (
    <div className="flex flex-col w-full">
      <div
        className={`ml-10 mr-5 my-5 bg-white m-5 rounded-md font-semibold leading-5 p-5 border ${extraClasses}`}>
        <div className="flex justify-between px-5 items-center align-middle mt-3">
          <h1 className="flex font-semibold text-xl">
            <span className="text-primary font-semibold">{t('companyName')}:</span>
            <span className="ml-5">{lotBid?.offer.client.companyName}</span>
          </h1>
          {renderCommissionerActions()}
          {renderCommissionerDirectorActions()}
          {renderBadge()}
        </div>
        <button />
        <div className="mt-5 grid grid-cols-2">
          <RowDetails label={t('nuis')} value={lotBid?.offer.client.nuis} />
          <RowDetails label={t('multiplier')} value={lotBid.multiplier} />
        </div>
        <div className="mt-5 px-5">
          <h1 className="flex font-semibold text-xl mt-5 text-primary mb-4">{t('votes')}</h1>
          <DataTable columns={headers} data={commission} />
        </div>
        {renderContractActions()}
      </div>
    </div>
  );
};
export default LotBid;
