import React, { useMemo, useState } from 'react';
import DeleteModal from '@core/modals/DeleteModal';
import { randomCode, showError, showSuccess } from '@utils/helpers';
import DeleteButton from '@core/buttons/atoms/DeleteButton';
import { useTranslation } from 'react-i18next';
import CodeInput from '@core/inputs/CodeInput';
import useHasPermission from '@hooks/useHasPermission';

const DeleteEntity = ({ service, id, onDeleted, isTender = false, roles = [] }) => {
  const { t } = useTranslation();
  const canAccess = useHasPermission({ roles });

  const [showModal, setShowModal] = useState(false);
  const [codeInput, setCodeInput] = useState('');

  const code = useMemo(() => {
    if (showModal) {
      return randomCode();
    }
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
    setCodeInput(null);
  };

  const onSubmit = () => {
    if (isTender === false || code === codeInput) {
      service
        .destroy(id)
        .then(() => {
          showSuccess(t('deletedSuccessfully'));
          onDeleted();
          closeModal();
        })
        .catch((err) => {
          showError(err.response.data.message);
        });
    } else showError(t('codeDoesNotMatch'));
  };

  if (!canAccess) {
    return null;
  }

  return (
    <div>
      <DeleteButton onClick={() => setShowModal(true)} />
      <DeleteModal
        show={showModal}
        title={`${t('areYouSure')}`}
        onClose={closeModal}
        onSubmit={onSubmit}>
        <div className="text-lg my-3 ml-5 text-center">
          <span>{t('areYouSureYouWantToDeleteThisEntity')}</span>
        </div>
        {isTender && (
          <div className="flex flex-col justify-center mx-auto pt-3">
            <h1 className="flex py-2 font-semibold text-xl text-center justify-center mx-auto">
              {t('matchToDeleteTender')}
            </h1>
            <div className="py-2 text-center">{code}</div>
            <div className="flex py-4 justify-center mx-auto">
              <CodeInput value={codeInput} onChange={setCodeInput} fields={6} />
            </div>
          </div>
        )}
      </DeleteModal>
    </div>
  );
};

export default DeleteEntity;
