import React, { useMemo, useState } from 'react';
import { randomCode, showError, showSuccess } from '@utils/helpers';
import { useTranslation } from 'react-i18next';
import CodeInput from '@core/inputs/CodeInput';
import TenderService from '@services/TenderService';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import BlankModal from '@core/modals/BlankModal';
import { FaDownload } from 'react-icons/fa';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { internalSystemRoles } from '@constants/roles';

const EvaluateTender = ({ id, onEvaluate }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [codeInput, setCodeInput] = useState('');

  const code = useMemo(() => {
    if (showModal) {
      return randomCode();
    }
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
    setCodeInput(null);
  };

  const onSubmit = () => {
    if (code === codeInput) {
      TenderService.evaluateStatus(id)
        .then(() => {
          showSuccess(t('tenderStatusChangedSuccessfully'));
          onEvaluate();
          closeModal();
        })
        .catch((err) => {
          showError(err.response.data.message);
        });
    } else showError(t('codeDoesNotMatch'));
  };

  return (
    <PermissionHandler roles={internalSystemRoles}>
      <DefaultButton
        title={t('evaluate')}
        sm
        label={
          <div className="flex items-center">
            <FaDownload />
          </div>
        }
        bgColor="bg-orange-100"
        bgColorHover="hover:bg-orange-300"
        textColor="text-primary"
        onClick={() => setShowModal(true)}
      />
      <BlankModal
        width="w-1/3"
        show={showModal}
        title={`${t('areYouSure')}`}
        onClose={closeModal}
        icon={<FaDownload className="text-primary" />}
        iconBg="bg-red-100"
        maxWidth="3xl"
        otherButtons={[<DefaultButton sm onClick={onSubmit} label={t('confirm')} />]}>
        <div className="text-lg my-3 ml-5 text-center">
          <span>{t('areYouSureYouWantToChangeStatusOfThisTender')}</span>
        </div>
        <div className="flex flex-col justify-center mx-auto pt-3">
          <h1 className="flex py-2 font-semibold text-xl text-center justify-center mx-auto">
            {t('matchToEvaluateTender')}
          </h1>
          <div className="py-2 text-center">{code}</div>
          <div className="flex py-4 justify-center mx-auto">
            <CodeInput value={codeInput} onChange={setCodeInput} fields={6} />
          </div>
        </div>
      </BlankModal>
    </PermissionHandler>
  );
};

export default EvaluateTender;
