const changePasswordValidator = {
  password: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_password'
    }
  },
  confirmPassword: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_password'
    },
    equality: {
      attribute: 'confirmPassword',
      message: 'errors.password_confirmation',
      comparator: (confirmPassword, password) => {
        return password && confirmPassword === password;
      }
    }
  }
};

export default changePasswordValidator;
