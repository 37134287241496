import React, { useEffect, useState } from 'react';
import ViewModal from '@core/modals/ViewModal';
import ViewButton from '@core/buttons/atoms/ViewButton';
import RowDetails from '@hoc/cruds/RowDetails';
import { useTranslation } from 'react-i18next';
import DeliveryService from '@services/DeliveryService';
import _ from 'lodash';
import moment from 'moment';

const ShowDelivery = ({ delivery }) => {
  const [showModal, setShowModal] = useState(false);
  const [deliveryData, setDeliveryData] = useState([]);

  const { t } = useTranslation();

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal)
      DeliveryService.find(delivery.id).then((response) => {
        const deliveryData = _.get(response, 'data.data');
        setDeliveryData(deliveryData);
      });
  }, [delivery.id, showModal]);

  return (
    <>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal show={showModal} title={`${t('overview')}`} onClose={closeModal}>
        <div className="flex flex-col mt-5">
          <RowDetails label={t('name')} value={deliveryData.name} />
          <RowDetails
            label={t('pickupDate')}
            value={moment(deliveryData.date).format('YYYY-MM-DD')}
          />
          <RowDetails label={t('amount')} value={deliveryData.amount} />
          <RowDetails label={t('barrels')} value={deliveryData.barrels?.toFixed(2)} />
          <RowDetails label={t('multiplier')} value={deliveryData.multiplier} />
          <RowDetails label={t('price')} value={`$ ${deliveryData.price}`} />
          <RowDetails label={t('totalPrice')} value={`$ ${deliveryData.totalPrice}`} />
          <RowDetails
            label={t('status')}
            value={deliveryData.status === 'not_delivered' ? t('notDelivered') : t('delivered')}
          />
        </div>
      </ViewModal>
    </>
  );
};

export default ShowDelivery;
