import React from 'react';
import { useNavigate } from 'react-router';
import EditButton from '@core/buttons/atoms/EditButton';
import ViewButton from '@core/buttons/atoms/ViewButton';

const MyTenderActions = ({ tender, hasOwnBids }) => {
  const navigate = useNavigate();

  if (tender.status === 'published') {
    return (
      <div className="flex justify-between items-center">
        <div className="mr-2">
          <EditButton
            onClick={() => {
              navigate(`/my-tenders/tender/${tender.id}`, {
                state: { disabled: false, hasOwnBids }
              });
            }}
          />
        </div>
      </div>
    );
  }
  if (tender.status === 'closed') {
    return (
      <div className="flex justify-between items-center">
        <div className="mr-2">
          <ViewButton
            onClick={() => {
              navigate(`/my-tenders/tender/${tender.id}`, {
                state: { disabled: true, hasOwnBids }
              });
            }}
          />
        </div>
      </div>
    );
  }
  if (tender.status === 'canceled') {
    return (
      <div className="flex justify-between items-center">
        <div className="mr-2">
          <ViewButton
            onClick={() => {
              navigate(`/my-tenders/tender/${tender.id}`, {
                state: { disabled: true, hasOwnBids }
              });
            }}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default MyTenderActions;
