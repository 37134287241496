import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { FaArrowLeft, FaSpinner, FaStopCircle } from 'react-icons/fa';
import TenderService from '@services/TenderService';
import { useNavigate, useParams } from 'react-router';
import RowDetails from '@hoc/cruds/RowDetails';
import TenderOverview from '@components/Tender/partials/TenderOverview';
import LotBid from '@components/Tender/partials/LotBid';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { showError, showSuccess } from '@utils/helpers';
import useCanAccessRoute from '@hooks/useCanAccessRoute';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { admin } from '@constants/roles';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const EvaluateOffersPage = ({ roles = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tenderId } = useParams();
  const { state } = useLocation();

  const { hasAccess, redirect } = useCanAccessRoute({ roles });

  const [details, setDetails] = useState({});
  const [lots, setLots] = useState([]);
  const [clickedLotIndex, setClickedLotIndex] = useState(0);
  const [clickedLot, setClickedLot] = useState({});
  const [commission, setCommission] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasWinner, setHasWinner] = useState(false);

  const currentRole = useSelector((state) => _.get(state, 'meReducer.role', null));

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirect);
    }
  }, [hasAccess, navigate, redirect]);

  useEffect(() => {
    if (!tenderId) {
      navigate('/');
    }
  }, [navigate, tenderId]);

  const findHasWinner = useCallback((lot) => {
    const winningLot = lot?.lotBids?.find((lot) => lot.isWinner === true);
    if (winningLot) {
      setHasWinner(true);
    } else setHasWinner(false);
  }, []);

  const getData = useCallback(() => {
    if (state?.status === 'closed') {
      TenderService.lotBids(tenderId).then((res) => {
        const { data } = res.data;
        setDetails(data);
        setLots(data.lots);
        setCommission(data.commission);
        if (Object.keys(clickedLot).length === 0) {
          setClickedLot(data?.lots[0]);
          findHasWinner(data?.lots[0]);
        }
      });
    } else {
      TenderService.offers(tenderId).then((res) => {
        const { data } = res.data;
        setDetails(data);
        setLots(data.lots);
        setCommission(data.commission);
        if (Object.keys(clickedLot).length === 0) {
          setClickedLot(data?.lots[0]);
          findHasWinner(data?.lots[0]);
        }
      });
    }
  }, [clickedLot, findHasWinner, state?.status, tenderId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onCancel = () => {
    setLoading(true);
    TenderService.cancel(tenderId)
      .then(() => {
        showSuccess(t('tenderCancelledSuccessfully'));
        navigate('/tenders');
        getData();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onComplete = () => {
    TenderService.complete(tenderId)
      .then(() => {
        showSuccess(t('tenderCompletedSuccessfully'));
        navigate('/tenders');
        getData();
      })
      .catch((err) => {
        showError(err.response.data.message);
      });
  };

  return (
    <>
      <div className="min-h-screen flex flex-col bg-gray-100 mb-5">
        <h1 className="flex font-semibold text-xl mt-5 mx-10 pl-2 justify-between">
          {t('title')} : {details?.name}
          <div className="font-semibold leading-5 mr-5 flex items-center self-center">
            {t('offersReceived')} :
            <span className="ml-5">{details?.offersReceived ? details?.offersReceived : '0'}</span>
            {state.status !== 'closed' && (
              <div className="ml-5">
                <PermissionHandler roles={admin}>
                  <DefaultButton
                    title={t('cancelTender')}
                    sm
                    label={
                      <div className="flex items-center">
                        {loading ? (
                          <FaSpinner className="animate-spin mr-1" />
                        ) : (
                          <FaStopCircle className="mr-1" />
                        )}
                        {t('cancelTender')}
                      </div>
                    }
                    bgColor="bg-red-600"
                    bgColorHover="hover:bg-red-700"
                    textColor="text-white"
                    onClick={onCancel}
                  />
                </PermissionHandler>
              </div>
            )}
          </div>
        </h1>
        <div className="flex">
          <TenderOverview details={details} />
          <div className="flex flex-col w-full">
            <div className="ml-10 mr-5 my-5 bg-white m-5 rounded-md font-semibold leading-5 p-5 border border-gray-300 shadow-lg">
              <div className="flex mb-10">
                {lots?.map((lot, index) => (
                  <div
                    onClick={() => {
                      setClickedLotIndex(index);
                      setClickedLot(lot);
                      findHasWinner(lot);
                    }}
                    className={`mr-2 px-3 py-2 border border-primary w-16 text-center rounded-lg hover:cursor-pointer hover:bg-orange-50 hover:text-black ${
                      clickedLotIndex === index && 'bg-primary text-white'
                    }`}>{`${t('lot')} ${index + 1}`}</div>
                ))}
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <RowDetails label={t('name')} value={clickedLot.name} />
                <RowDetails
                  label={t('deliveryProfile')}
                  value={`${clickedLot.startDate} - ${clickedLot.endDate}`}
                />
              </div>
              <div className="grid grid-cols-3 gap-5 mt-5">
                <div className="flex flex-col">
                  <RowDetails
                    label={t('amount')}
                    value={`${
                      clickedLot?.originalUnit === 'bbl' ? clickedLot?.barrels : clickedLot?.amount
                    } ${t(clickedLot.originalUnit)}`}
                  />
                  <div className="mx-10 mb-4 text-xs pt-2 text-semibold">{`${t('itIsEqualTo')}  ${
                    clickedLot?.originalUnit === 'bbl' ? clickedLot?.amount : clickedLot?.barrels
                  } ${
                    // eslint-disable-next-line no-nested-ternary
                    clickedLot?.originalUnit === 'bbl'
                      ? clickedLot?.product === 'OIL'
                        ? t('t')
                        : t('nm3')
                      : t('barrels')
                  }`}</div>
                </div>
                <RowDetails label={t('product')} value={clickedLot.product} />
                <RowDetails label={t('pickUp')} value={clickedLot.pickUp} />
              </div>
            </div>
            {clickedLot?.lotBids?.map((lotBid) => (
              <LotBid
                onWinner={setHasWinner}
                hasWinner={hasWinner}
                onSuccess={getData}
                lotBid={lotBid}
                status={details?.status}
                commission={commission}
                isCommission={details?.isCommission}
                isCommissionDirector={details?.isCommissionDirector}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="sticky w-full shadow border-t bottom-0 bg-white flex items-center justify-end py-3 px-3">
        <Link to="/tenders">
          <div className="flex cursor-pointer mr-5">
            <FaArrowLeft className="mt-1.5 mr-2" />
            {t('goBack')}
          </div>
        </Link>
        {state?.status !== 'closed' &&
          (currentRole === 'administrator' || details?.isCommissionDirector) && (
            <div className="flex w-1/6">
              <SubmitButton label={t('closeTender')} loading={loading} onClick={onComplete} />
            </div>
          )}
      </div>
    </>
  );
};
export default EvaluateOffersPage;
