import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useCanAccessRoute from '@hooks/useCanAccessRoute';
import ClientsIndex from '@components/Client/ClientsIndex';

const ClientsPage = ({ roles = [] }) => {
  const navigate = useNavigate();

  const { hasAccess, redirect } = useCanAccessRoute({ roles });

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirect);
    }
  }, [hasAccess, navigate, redirect]);

  return <ClientsIndex />;
};

export default ClientsPage;
