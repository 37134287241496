import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import TenderOverview from '@components/Tender/partials/TenderOverview';
import TenderOfferLot from '@components/Tender/partials/TenderOfferLots';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import { showError, showSuccess } from '@utils/helpers';
import ClientTenderService from '@services/ClientTenderService';
import TenderService from '@services/TenderService';
import useCanAccessRoute from '@hooks/useCanAccessRoute';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { client } from '@constants/roles';

const MakeTenderOfferPage = ({ roles = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { tenderId } = useParams();

  const { hasAccess, redirect } = useCanAccessRoute({ roles });

  const [details, setDetails] = useState({});
  const [lots, setLots] = useState([]);
  const [lotBids, setLotBids] = useState([]);
  const [offerId, setOfferId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirect);
    }
  }, [hasAccess, navigate, redirect]);

  useEffect(() => {
    if (!tenderId) {
      navigate('/');
    }
  }, [navigate, tenderId]);

  const getData = useCallback(() => {
    if (state.hasOwnBids) {
      ClientTenderService.find(tenderId).then((res) => {
        const data = res.data.data.procurement;
        setDetails(data);
        setLots(data.lots);
        if (res.data.data.procurement.offer.lotBids) {
          setOfferId(res.data.data.procurement.offer.id);
          const existingLotBids = data.offer.lotBids?.map((bid, index) => {
            return {
              index: index + 1,
              ...bid
            };
          });
          setLotBids(existingLotBids);
        }
      });
    } else {
      TenderService.find(tenderId).then((res) => {
        const data = res.data.data.procurement;
        setDetails(data);
        setLots(data.lots);
      });
    }
  }, [state.hasOwnBids, tenderId]);

  const uploadedLotBid = useCallback(
    (id) => {
      if (lotBids.length !== 0) {
        return lotBids.find((bid) => bid.lotId === id);
      }
    },
    [lotBids]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const updateBid = useCallback(
    (index, bid) => {
      const newBid = [...lotBids];
      newBid[index] = bid;
      setLotBids((prev) => {
        const exists = prev.find((rec) => rec.index === bid.index);
        if (exists) {
          return prev.map((rec) => {
            if (rec.index === bid.index) {
              return bid;
            }
            return rec;
          });
        }
        return [...prev, bid];
      });
    },
    [lotBids]
  );

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const bid = lotBids.map(({ index, ...rest }) => {
      return rest;
    });
    if (state.hasOwnBids) {
      ClientTenderService.updateOffer(Number(tenderId), offerId, bid)
        .then(() => {
          showSuccess(t('offerEditedSuccessfully'));
          navigate('/my-tenders');
        })
        .catch((err) => {
          showError(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ClientTenderService.createOffer(Number(tenderId), bid)
        .then(() => {
          showSuccess(t('offerCreatedSuccessfully'));
          navigate('/my-tenders');
        })
        .catch((err) => {
          showError(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <PermissionHandler roles={client}>
      <div className="min-h-screen flex flex-col bg-gray-100">
        <h1 className="flex font-semibold text-xl mt-5 mx-10 pl-2 justify-start">
          {t('title')} : {details.name}
        </h1>
        <div className="flex">
          <TenderOverview details={details} />
          <div className="flex flex-col w-full">
            {lots.map((lot, index) => (
              <TenderOfferLot
                status={details?.status}
                disabled={state.disabled}
                lot={lot}
                index={index}
                updateBid={updateBid}
                bid={uploadedLotBid(lot.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="sticky w-full shadow border-t bottom-0 bg-white flex items-center justify-end py-3 px-3">
        <Link to={state?.hasOwnBids ? '/my-tenders' : '/published-tenders'}>
          <div className="flex cursor-pointer mr-5">
            <FaArrowLeft className="mt-1.5 mr-2" />
            {t('goBack')}
          </div>
        </Link>
        {!state.disabled && (
          <div className="flex w-1/6">
            <SubmitButton
              label={state.hasOwnBids ? t('edit') : t('submit')}
              onClick={onSubmit}
              loading={loading}
            />
          </div>
        )}
      </div>
    </PermissionHandler>
  );
};
export default MakeTenderOfferPage;
