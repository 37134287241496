import React from 'react';
import OilTanker from '@assets/images/OilTankers.jpeg';
import logo from '@assets/images/logo.png';

const AuthLayout = ({ children }) => {
  return (
    <div>
      <div className="min-h-screen flex bg-gray-100">
        <div
          className="w-3/5 max-h-full"
          style={{
            backgroundImage: `url(${OilTanker})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}>
          <div className="flex flex-col relative pl-20 space-y-100 h-full justify-around">
            <img src={logo} alt="Alb petrol logo" className="w-60" />
            <h1 className="text-gray-200 text-2xl mt-20">Copyrights AP 2023</h1>
          </div>
        </div>
        <div className=" w-2/5 space-y-8 px-14 bg-white">{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
