import React from 'react';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import DeliveryService from '@services/DeliveryService';
import ShowDelivery from '@components/Delivery/ShowDelivery';
import EditDelivery from '@components/Delivery/EditDelivery';
import { admin } from '@constants/roles';
import DeliveryInvoiceGeneration from '@components/Delivery/partials/DeliveryInvoiceGeneration';
import SendInvoice from '@components/Delivery/partials/SendInvoice';
import DownloadInvoice from '@components/Delivery/partials/DownloadInvoice';
import DeleteInvoice from '@components/Delivery/partials/DeleteInvoice';

const DeliveryActions = ({
  selectedTender,
  selectedLot,
  delivery,
  onEdited,
  onDeleted,
  multiplier,
  onSuccess
}) => (
  <div className="flex justify-between items-center">
    {!delivery?.invoice && delivery?.contract && (
      <div className="mr-2">
        <DeliveryInvoiceGeneration onSuccess={onSuccess} delivery={delivery} />
      </div>
    )}
    {delivery?.invoice && (
      <>
        <div className="mr-2">
          <SendInvoice delivery={delivery} onSuccess={onSuccess} />
        </div>
        <div className="mr-2">
          <DownloadInvoice delivery={delivery} onSuccess={onSuccess} />
        </div>
        <div className="mr-2">
          <DeleteInvoice delivery={delivery} onDeleted={onDeleted} />
        </div>
      </>
    )}
    <div className="mr-2">
      <ShowDelivery delivery={delivery} />
    </div>
    <div className="mr-2">
      <EditDelivery
        selectedTender={selectedTender}
        selectedLot={selectedLot}
        multiplier={multiplier}
        delivery={delivery}
        onEdited={onEdited}
      />
    </div>
    <DeleteEntity roles={admin} service={DeliveryService} id={delivery.id} onDeleted={onDeleted} />
  </div>
);

export default DeliveryActions;
