import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const useCanAccessRoute = ({ roles = [] }) => {
  const currentRole = useSelector((state) => _.get(state, 'meReducer.role', null));

  const hasAccess = useMemo(() => {
    return !(roles.length > 0 && currentRole && !roles?.includes(currentRole));
  }, [currentRole, roles]);

  const redirect = useMemo(() => {
    return currentRole === 'klient' ? '/published-tenders' : '/';
  }, [currentRole]);

  return { hasAccess, redirect };
};

export default useCanAccessRoute;
