import React, { useState } from 'react';
import FileInput from '@core/inputs/FileInput';
import { fileToBase64, showError } from '@utils/helpers';
import File from '@assets/animations/file.json';
import CDefaultDatePicker from '@core/inputs/dateTime/CDefaultDatePicker';
import DeleteButton from '@core/buttons/atoms/DeleteButton';
import ViewButton from '@core/buttons/atoms/ViewButton';
import { useTranslation } from 'react-i18next';
import ClientService from '@services/ClientService';
import moment from 'moment';

const DocumentFileUpload = ({
  requiredDocument,
  upload,
  updateUpload,
  index,
  handleDelete,
  disabled = false,
  clientId
}) => {
  const fileTypes = ['JPG', 'PNG', 'PDF', 'JPEG'];
  const { t } = useTranslation();
  const { id } = requiredDocument;
  const currentDate = moment(upload?.documentType?.createdAt).format('YYYY-MM-DD');
  const [date, setDate] = useState(currentDate);
  const [uploadedDocument, setUploadedDocument] = useState(upload);
  const [selectedFileName, setSelectedFileName] = useState(upload ? t('uploaded') : '');
  const [showDelete, setShowDelete] = useState(!disabled && uploadedDocument);
  const [showView, setShowView] = useState(upload?.isExisting);

  const base64ToObject = (base64) => {
    const base64SplitArray = base64.split(';base64,');
    const extSplitArray = base64SplitArray[0].split('/');
    return { documentTypeId: id, base64: base64SplitArray[1], extension: extSplitArray[1] };
  };

  const handleDocumentSelect = async (file) => {
    const base64POA = await fileToBase64(file);
    const base64Object = base64ToObject(base64POA);
    setUploadedDocument(base64Object);
    updateUpload(index, base64Object);
    setShowDelete(true);
  };

  const handleDateChange = (val) => {
    setDate(val);
    const currentUpload = { ...uploadedDocument, expiresAt: val };
    updateUpload(index, currentUpload);
  };

  const viewDocument = () => {
    ClientService.getDocument(clientId, upload.id)
      .then((res) => {
        window.open(res.data.data);
      })
      .catch((err) => {
        showError(err.response.data.message);
      });
  };

  return (
    <div className="flex flex-col">
      <div className="flex px-3 py-4 mb-0 justify-between">
        <label className="text-base font-semibold flex justify-center">
          {requiredDocument.name}
        </label>
        <CDefaultDatePicker
          value={date}
          onChange={(val) => handleDateChange(val)}
          disabled={disabled}
        />
      </div>
      <FileInput
        fileName={selectedFileName}
        onChangeFile={setSelectedFileName}
        disabled={disabled}
        classname={`mt-3 border border-gray-300 bg-gray-200 outline-2 ${
          !disabled && 'hover:opacity-50'
        } justify-center`}
        handleChange={handleDocumentSelect}
        loop
        autoplay
        animation={File}
        types={fileTypes}
      />
      <div className="flex justify-end items-center">
        {showView && (
          <div className="mr-2">
            <ViewButton
              onClick={() => {
                viewDocument();
              }}
            />
          </div>
        )}
        {showDelete && (
          <div>
            <DeleteButton
              onClick={() => {
                handleDelete(id);
                setDate('');
                setSelectedFileName('');
                setShowDelete(false);
                setShowView(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentFileUpload;
