const deactivationValidator = {
  reason: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_reason'
    }
  }
};

export default deactivationValidator;
