import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SalesChart from '@components/Home/partials/SalesChart';
import moment from 'moment';

const HomeCharts = ({ graphs }) => {
  const { t } = useTranslation();
  const [weekDates, setWeekDates] = useState([]);

  useEffect(() => {
    const weekDates = [];

    const generateFourPreviousWeekDates = () => {
      // eslint-disable-next-line no-plusplus
      for (let weeksAgo = 4; weeksAgo > 0; weeksAgo--) {
        const weekStartDate = moment().subtract(weeksAgo, 'week').toDate();
        const weekEndDate = moment(weekStartDate).add(6, 'days').toDate();
        weekDates.push(
          `${moment(weekStartDate).format('D MMM')} - ${moment(weekEndDate).format('D MMM')}`
        );
      }
      return weekDates;
    };
    setWeekDates(generateFourPreviousWeekDates);
  }, []);

  return (
    <>
      <h1 className="flex border-b-2 pt-4 pb-2 mb-8 font-semibold text-2xl">{t('home')}</h1>
      <div className="flex justify-around">
        {graphs.map((graph) => (
          <SalesChart
            title={t(graph.product)}
            labels={weekDates}
            measurementUnit={graph.productUnit}
            values={graph?.value}
            label={`${t(graph.product)} ${t('bought')}`}
          />
        ))}
      </div>
    </>
  );
};

export default HomeCharts;
