import React from 'react';
import { useTranslation } from 'react-i18next';
import TenderService from '@services/TenderService';
import { useParams } from 'react-router';
import TenderStatus from '@components/Tender/partials/TenderStatus';
import RowDetails from '@hoc/cruds/RowDetails';

const TenderOverview = ({ details }) => {
  const { t } = useTranslation();
  const { tenderId } = useParams();

  const generateTermsAndConditions = () => {
    TenderService.downloadTermsAndConditions(tenderId).then((res) => {
      window.open(res.data.data.termsAndConditions);
    });
  };

  return (
    <div className="w-1/3 bg-white ml-10 my-5 rounded-md border border-gray-300 shadow-lg h-96">
      <div className="flex justify-between mt-7 xl:mt-12 mb-2 mb-14 px-5">
        <h1 className="flex font-semibold text-base xl:text-xl">
          {t('tenderDetails')}
          <span className="ml-3">#{details?.id}</span>
        </h1>
        <TenderStatus status={details?.status}>{details?.status}</TenderStatus>
      </div>
      <div className="mb-5">
        <RowDetails label={t('tenderId')} value={details?.id} />
        <RowDetails label={t('publishDate')} value={details?.publishDate} />
        <RowDetails label={t('deadlineToReceiveOffers')} value={details?.endDate} />
        <RowDetails
          label={t('offerValidityInterval')}
          value={`${details?.offerValidityInterval} ${t('days')}`}
        />
        {details?.creator && (
          <RowDetails
            label={t('creator')}
            value={`${details?.creator?.firstName} ${details?.creator?.lastName}`}
          />
        )}
        <div
          className="mt-8 rounded-md flex text-primary hover:cursor-pointer justify-end p-3 font-semibold uppercase leading-5 pr-5"
          onClick={generateTermsAndConditions}>
          {t('termsAndConditions')}
        </div>
      </div>
    </div>
  );
};
export default TenderOverview;
