import GuestAPI from '@utils/plugins/GuestAPI';

const TwoFactorAuthenticationService = {
  validate: (token) => {
    return GuestAPI.post(
      'validate-token',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  },
  authenticate: (token, code) => {
    return GuestAPI.post(
      '2fa/authenticate',
      { code },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  }
};

export default TwoFactorAuthenticationService;
