import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useActiveMenuItem from '@hooks/useActiveMenuItem';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const NavbarItem = ({ icon, label, link, exact = false, roles = [] }) => {
  const navigate = useNavigate();
  const isActive = useActiveMenuItem(link, [], exact);
  const [hasAccess, setHasAccess] = useState(false);

  const currentRole = useSelector((state) => _.get(state, 'meReducer.role', null));

  useEffect(() => {
    if (roles.length > 0 && currentRole && roles?.includes(currentRole)) {
      setHasAccess(true);
    }
  }, [currentRole, roles]);

  const handleClick = () => {
    if (link) {
      return navigate(link);
    }
  };

  return hasAccess ? (
    <li className="mx-2 list-none">
      <span
        onClick={handleClick}
        className={`flex items-center h-10 transform space-x-2 hover:translate-y-1 transition-transform ease-in duration-200 cursor-pointer ${
          isActive ? 'text-primary border-primary border-b-2' : 'text-gray-500 hover:text-gray-700'
        }`}>
        <span className="inline-flex items-center justify-center text-xl">{icon}</span>
        <span className="text-lg text-gray-500">{label}</span>
      </span>
    </li>
  ) : null;
};

export default NavbarItem;
