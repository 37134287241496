import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import DeliveryService from '@services/DeliveryService';
import { FaDownload } from 'react-icons/fa';
import { showError, showSuccess } from '@utils/helpers';
import BlankModal from '@core/modals/BlankModal';
import SubmitButton from '@core/buttons/atoms/SubmitButton';

const DownloadInvoice = ({ delivery, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    DeliveryService.downloadInvoice(delivery?.invoice?.id)
      .then(() => {
        showSuccess(t('invoiceDownloadedSuccessfully'));
        closeModal();
        onSuccess();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <DefaultButton
        title={t('downloadInvoice')}
        bgColor="bg-emerald-100"
        bgColorHover="hover:bg-emerald-200"
        textColor="text-emerald-700"
        sm
        label={<FaDownload />}
        onClick={() => setShowModal(true)}
      />
      <BlankModal
        otherButtons={[<SubmitButton loading={loading} onClick={onSubmit} label={t('download')} />]}
        onSubmit={onSubmit}
        show={showModal}
        title={`${t('download')}`}
        loading={loading}
        icon={<FaDownload className="text-orange-800" />}
        onClose={closeModal}>
        <div className="text-lg mb-5">
          <span>{`${t('areYouSureYouWantToDownloadInvoice')}`}</span>
        </div>
      </BlankModal>
    </>
  );
};

export default DownloadInvoice;
