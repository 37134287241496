import React from 'react';
import Input from '@core/inputs/Input';
import { useTranslation } from 'react-i18next';
import Country from '@components/Client/partials/CountrySelect';
import PhoneNumberInput from '@core/inputs/PhoneNumberInput';

const ClientDetails = ({
  showModal,
  clientData,
  onChangeClient,
  onChangeUser,
  userData,
  clearError,
  errors
}) => {
  const { t } = useTranslation();

  const handleChangeUser = (value, name) => {
    onChangeUser({ ...userData, [name]: value });
  };

  const handleChangeClient = (value, name) => {
    onChangeClient({ ...clientData, [name]: value });
  };

  return (
    showModal && (
      <>
        <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-xl">
          {t('usersDetails')}
        </h1>
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label={t('firstName')}
            placeholder={t('firstName')}
            value={userData?.firstName}
            onChange={(value) => {
              clearError('firstName');
              handleChangeUser(value, 'firstName');
            }}
            error={errors('firstName')}
          />
          <Input
            height="h-10"
            label={t('lastName')}
            placeholder={t('lastName')}
            value={userData?.lastName}
            onChange={(value) => {
              clearError('lastName');
              handleChangeUser(value, 'lastName');
            }}
            error={errors('lastName')}
          />
          <Input
            height="h-10"
            label={t('email')}
            placeholder={t('email')}
            value={userData?.email}
            onChange={(value) => {
              clearError('email');
              handleChangeUser(value, 'email');
            }}
            error={errors('email')}
          />
          <PhoneNumberInput
            phoneNumber={userData?.phone}
            onChange={(value) => {
              clearError('phoneNumber');
              handleChangeUser(value, 'phone');
            }}
            placeholder={t('phone')}
            label={t('phone')}
            error={errors('phoneNumber')}
            dependency={showModal}
          />
        </div>
        <div>
          <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-xl">
            {t('clientsDetails')}
          </h1>
          <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
            <Input
              height="h-10"
              label={t('companyName')}
              placeholder={t('companyName')}
              value={clientData?.companyName}
              onChange={(value) => {
                clearError('companyName');
                handleChangeClient(value, 'companyName');
              }}
              error={errors('companyName')}
            />
            <Input
              height="h-10"
              label={t('nuis')}
              placeholder={t('nuis')}
              value={clientData?.nuis}
              onChange={(value) => {
                clearError('nuis');
                handleChangeClient(value, 'nuis');
              }}
              error={errors('nuis')}
            />
            <Input
              height="h-10"
              label={t('administrator')}
              placeholder={t('administrator')}
              value={clientData?.administrator}
              onChange={(value) => {
                clearError('administrator');
                handleChangeClient(value, 'administrator');
              }}
              error={errors('administrator')}
            />
            <Input
              height="h-10"
              label={t('email')}
              placeholder={t('email')}
              value={clientData?.email}
              onChange={(value) => {
                clearError('userEmail');
                handleChangeClient(value, 'email');
              }}
              error={errors('userEmail')}
            />
            <Input
              height="h-10"
              label={t('alternativeEmail')}
              placeholder={t('alternativeEmail')}
              value={clientData?.alternativeEmail}
              onChange={(value) => {
                clearError('alternativeEmail');
                handleChangeClient(value, 'alternativeEmail');
              }}
              error={errors('alternativeEmail')}
            />
            <Input
              height="h-10"
              label={t('fullAddress')}
              placeholder={t('fullAddress')}
              value={clientData?.address}
              onChange={(value) => {
                clearError('fullAddress');
                handleChangeClient(value, 'address');
              }}
              error={errors('fullAddress')}
            />
            <div>
              <div className="text-sm font-semibold mb-2">{t('country')}</div>
              <Country
                showErrorText
                openModal={showModal}
                onSelect={(value) => {
                  clearError('country');
                  handleChangeClient(value, 'country');
                }}
                error={errors('country')}
                selected={clientData?.country}
              />
            </div>
            <Input
              height="h-10"
              label={t('city')}
              placeholder={t('city')}
              value={clientData?.city}
              onChange={(value) => {
                clearError('city');
                handleChangeClient(value, 'city');
              }}
              error={errors('city')}
            />
          </div>
        </div>
        <div>
          <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-xl">
            {t('authorizedPerson')}
          </h1>
          <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
            <Input
              height="h-10"
              label={t('authorizedPerson')}
              placeholder={t('authorizedPerson')}
              value={clientData?.authorizedPerson}
              onChange={(value) => {
                clearError('authorizedPerson');
                handleChangeClient(value, 'authorizedPerson');
              }}
              error={errors('authorizedPerson')}
            />
            <Input
              height="h-10"
              label={t('email')}
              placeholder={t('email')}
              value={clientData?.authorizedPersonEmail}
              onChange={(value) => {
                clearError('authorizedPersonEmail');
                handleChangeClient(value, 'authorizedPersonEmail');
              }}
              error={errors('authorizedPersonEmail')}
            />
            <PhoneNumberInput
              phoneNumber={clientData?.authorizedPersonPhone}
              onChange={(value) => {
                clearError('authorizedPersonPhone');
                handleChangeClient(value, 'authorizedPersonPhone');
              }}
              placeholder={t('phone')}
              label={t('phone')}
              error={errors('authorizedPersonPhone')}
              dependency={showModal}
            />
          </div>
        </div>
      </>
    )
  );
};

export default ClientDetails;
