import React from 'react';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import DocumentService from '@services/DocumentService';
import EditDocument from '@components/Document/EditDocument';
import ShowDocument from '@components/Document/ShowDocument';
import { admin } from '@constants/roles';

const DocumentActions = ({ document, onEdited, onDeleted }) => (
  <div className="flex justify-between items-center">
    <div className="mr-2">
      <ShowDocument document={document} />
    </div>
    <div className="mr-2">
      <EditDocument document={document} onEdited={onEdited} />
    </div>
    <DeleteEntity service={DocumentService} id={document.id} onDeleted={onDeleted} roles={admin} />
  </div>
);

export default DocumentActions;
