import React from 'react';

const RowDetails = ({ value, label, color }) => {
  return (
    <div className="flex justify-between mx-10 border-b mb-4">
      <div className="text-xs xl:text-sm text-gray-500">{label}</div>
      <div className={`text-xs xl:text-sm text-${color}-700 font-semibold`}>{value}</div>
    </div>
  );
};
export default RowDetails;
