const deliveryDataConstraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_name'
    }
  },
  status: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_status'
    }
  },
  unit: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_unit'
    }
  },
  amount: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_amount'
    }
  },
  price: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_price'
    }
  },
  operatorPrice: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_operatorPrice'
    }
  },
  totalPrice: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_totalPrice'
    }
  }
};
export default deliveryDataConstraints;
