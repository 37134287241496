import React, { useState } from 'react';
import { showError } from '@utils/helpers';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import AuthService from '@services/AuthService';
import Input from '@core/inputs/Input';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import PasswordInput from '@core/inputs/PasswordInput';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '@components/LanguageSelector/LanguageSelector';
import { Link, useNavigate } from 'react-router-dom';
import useFormErrors from '@hooks/useFormErrors';
import loginConstraints from '@utils/validators/loginConstraints';

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clearError, getError, validateErrors } = useFormErrors();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    const errors = validateErrors(
      {
        email,
        password
      },
      loginConstraints
    );
    if (errors) {
      return;
    }

    dispatch(showSpinner(t('pleaseWait')));
    AuthService.login(email, password)
      .then(async (response) => {
        const token = _.get(response, 'data.data._2faToken');
        navigate(`/two-factor-authentication/${token}`);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  return (
    <div className="flex flex-col w-full justify-center h-screen">
      <div className="ml-6 pt-4 pb-6 flex justify-end">
        <LanguageSelector />
      </div>
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-gray-900">{t('welcomeTitle')}</h2>
        <p className="text-sm font-light">{t('pleaseSignInToAccessTheSystem')}</p>
      </div>
      <form className="mt-8 space-y-2" onSubmit={onSubmit}>
        <div className="mb-6 space-y-6">
          <Input
            value={email}
            placeholder={t('email')}
            className="rounded-b-none "
            onChange={(value) => clearError('email', value, setEmail)}
            error={getError('email')}
            height="h-13"
            extraClasses="text-2xl"
          />
          <div className="relative">
            <div className="w-full mt-2">
              <PasswordInput
                placeholder={t('password')}
                value={password}
                onChange={(value) => clearError('password', value, setPassword)}
                error={getError('password')}
              />
            </div>
          </div>
          <div>
            <Link
              className="text-sm text-blue-900 hover:text-indigo-900 underline"
              to="/forgot-password">
              {t('forgotPassword')}
            </Link>
          </div>
        </div>
        <DefaultButton
          md
          type="submit"
          bgColorHover="bg-primary"
          bgColor="hover:bg-orange-600 hover:ease-in hover:duration-200"
          label={t('login')}
        />
      </form>
    </div>
  );
};

export default LoginForm;
