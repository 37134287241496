import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, Search } from 'tabler-icons-react';
import Datatable from '@core/table/Datatable';
import BlankModal from '@core/modals/BlankModal';
import { FaPlus } from 'react-icons/fa';
import UserService from '@services/UserService';
import UserStatus from '@components/User/partials/UserStatus';

const SearchCommission = ({ onAdded, commissions, commissionDirector }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState();
  const [commission, setCommission] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };

  const headers = useMemo(
    () => [
      {
        id: 'fullName',
        name: t('fullName'),
        cell: (row) => `${row.firstName} ${row.lastName}`,
        sortable: true
      },
      {
        id: 'email',
        name: t('email'),
        cell: (row) => row.email,
        sortable: true
      },
      {
        id: 'phone',
        name: t('phone'),
        cell: (row) => row.phone,
        sortable: true
      },
      {
        id: 'role',
        name: t('role'),
        cell: (row) => row.role,
        sortable: true,
        minWidth: '300px'
      },
      {
        id: 'status',
        name: t('status'),
        cell: (row) => <UserStatus status={row.status}>{row.status}</UserStatus>,
        sortable: true,
        width: '150px'
      },
      {
        id: 'actions',
        name: t('actions'),
        width: '80px',
        cell: (row) => (
          <button
            className="text-green-600 cursor-pointer"
            onClick={() => {
              onAdded(row);
              closeModal();
            }}>
            <Plus />
          </button>
        )
      }
    ],
    [onAdded, t]
  );

  const getData = (params) => {
    setLoading(true);
    UserService.all({ ...params, filters: { internal: true } }).then((response) => {
      setMetaData(response.data.data.totalRecords);
      setData(response.data.data.items);
      setLoading(false);
    });
  };

  useEffect(() => {
    const selectedCommissionIds = commissions?.map((commission) => commission.id);
    const selectedDirectorIds = commissionDirector?.map((commission) => commission.id);
    const selectedCommission = selectedCommissionIds.concat(selectedDirectorIds);
    const filteredCommission = data.filter(
      (commission) => !selectedCommission?.includes(commission.id)
    );
    setCommission(filteredCommission);
  }, [commissionDirector, commissions, data]);

  return (
    <div>
      <button onClick={() => setShowModal(true)}>
        <div className="flex text-primary items-center">
          <Search />
          <div className="ml-2 font-semibold">{t('searchCommission')}</div>
        </div>
      </button>
      <BlankModal
        icon={<FaPlus className="text-primary" />}
        show={showModal}
        title={t('addCommission')}
        onClose={closeModal}
        loading={loading}>
        <Datatable
          data={commission}
          headers={headers}
          totalRows={metaData}
          getData={getData}
          isLoading={loading}
          extraDependencies={[showModal]}
        />
      </BlankModal>
    </div>
  );
};

export default SearchCommission;
