import GreenBadge from '@core/badges/GreenBadge';
import RedBadge from '@core/badges/RedBadge';
import DefaultBadge from '@core/badges/DefaultBadge';
import { useTranslation } from 'react-i18next';

const TenderStatus = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case 'draft':
      return <DefaultBadge text={t('draft')} bgColor="text-yellow-800" textColor="bg-yellow-100" />;
    case 'decryptingOffers':
      return (
        <DefaultBadge
          text={t('decryptingOffers')}
          bgColor="text-orange-800"
          textColor="bg-orange-100"
        />
      );
    case 'evaluatingOffers':
      return (
        <DefaultBadge
          text={t('evaluatingOffers')}
          bgColor="text-indigo-800"
          textColor="bg-indigo-100"
        />
      );
    case 'approved':
      return <GreenBadge text={t('approved')} />;
    case 'published':
      return <GreenBadge text={t('published')} />;
    case 'closed':
      return (
        <DefaultBadge text={t('closed')} bgColor="text-orange-800" textColor="bg-orange-200" />
      );
    case 'canceled':
      return <RedBadge text={t('canceled')} />;
    case 'evaluatingExpired':
      return <RedBadge text={t('evaluatingExpired')} />;
    default:
      return <DefaultBadge text={t('pending')} />;
  }
};

export default TenderStatus;
