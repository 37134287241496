const en = {
  welcomeTitle: 'Welcome',
  pleaseSignInToAccessTheSystem: 'Please sign-in to your account to access the system.',
  forgotPassword: 'Forgot password?',
  email: 'Email',
  password: 'Password',
  quickLogin: 'Quick login',
  login: 'Login',
  noData: 'No Data',
  rememberMe: 'Remember me',
  home: 'Home',
  client: 'Client',
  clients: 'Clients',
  tender: 'Tender',
  tenders: 'Tenders',
  user: 'User',
  users: 'Users',
  invoice: 'Invoice',
  invoices: 'Invoices',
  documentTypes: 'Document types',
  oil: 'Oil',
  litres: 'Litres',
  nm3: 'NM3',
  naturalGas: 'Natural gas',
  oilBought: 'Oil bought',
  bought: 'bought',
  naturalGasBought: 'Natural gas bought',
  gas: 'Gas',
  overview: 'Overview',
  ton: 'Ton',
  activeTenders: 'Active tenders',
  name: 'Name',
  publishDate: 'Publish date',
  closureDate: 'Closure date',
  evaluationDuration: 'Evaluation duration',
  status: 'Status',
  actions: 'Actions',
  companyName: 'Company name',
  country: 'Country',
  administrator: 'Administrator',
  active: 'Active',
  inactive: 'Inactive',
  pending: 'Pending',
  total: 'Total',
  fullName: 'Full name',
  phone: 'Phone',
  role: 'Role',
  search: 'Search',
  logout: 'Logout',
  submit: 'Submit',
  confirmPassword: 'Confirm password',
  activateUser: 'Activate user',
  enterPassword: 'Enter password',
  backToLoginPage: 'Back to login page',
  twoStepAuthentication: 'Two-step Authentication',
  enterTheSixDigitCodeThatYouReceivedOnYourApp:
    'Enter the six digit code that you received on your app',
  scanTheQrToReceiveTheSixDigitCode: 'Scan the QR code to receive the six digit code',
  closed: 'Closed',
  processing: 'Processing',
  date: 'Date',
  paid: 'Paid',
  remaining: 'Remaining',
  tenderId: 'Tender ID',
  language: 'Language',
  addUser: 'Add user',
  userCreatedSuccessfully: 'User created successfully',
  edit: 'Edit',
  editUser: 'Edit user',
  userEditedSuccessfully: 'User edited successfully',
  firstName: 'First name',
  lastName: 'Last name',
  businessUnitCode: 'Business unit code',
  operatorCode: 'Operator code',
  tcrCode: 'TCR code',
  tcrMaintainerCode: 'TCR maintainer code',
  add: 'Add',
  delete: 'Delete',
  close: 'Close',
  areYouSure: 'Are you sure?',
  areYouSureYouWantToDeleteThisEntity: 'Are you sure you want to delete this entity?',
  send: 'Send',
  resendActivationLink: 'Resend activation link',
  areYouSureYouWantToResendActivationLink: 'Are you sure you want to resend activation link?',
  linkSent: 'Link sent',
  deletedSuccessfully: 'Deleted successfully',
  addClient: 'Add Client',
  clientCreatedSuccessfully: 'Client created successfully',
  clientDetails: 'Client`s details',
  shareholders: 'Shareholders',
  usersDetails: 'User`s details',
  nuis: 'NUIS',
  alternativeEmail: 'Alternative Email',
  fullAddress: 'Full Address',
  authorizedPerson: 'Authorized Person',
  clientsDetails: 'Client`s details',
  clientEditedSuccessfully: 'Client edited successfully',
  addShareholder: 'Add Shareholder',
  citizenship: 'Citizenship',
  documents: 'Documents',
  city: 'City',
  addTender: 'Add Tender',
  draft: 'Draft',
  decryptingOffers: 'Decrypting Offers',
  evaluatingOffers: 'Evaluating Offers',
  approved: 'Approved',
  published: 'Published',
  canceled: 'Canceled',
  evaluatingExpired: 'Evaluating Expired',
  days: 'days',
  details: 'Details',
  title: 'Title',
  description: 'Description',
  endDate: 'End Date',
  offerValidityInterval: 'Offer Validity Interval',
  termsAndConditions: 'Terms and Conditions',
  lots: 'Lots',
  lot: 'Lot',
  addLot: 'Add Lot',
  startDate: 'Start Date',
  amount: 'Amount',
  product: 'Product',
  pickUp: 'Pick Up',
  save: 'Save',
  rank: 'Rank',
  commission: 'Commission',
  searchCommission: 'Search Commission',
  goBack: 'Go Back',
  publishTender: 'Publish Tender',
  matchToDeleteTender: 'Match to delete tender',
  matchToPublishTender: 'Match to publish tender',
  tenderCreatedSuccessfully: 'Tender created successfully',
  tenderEditedSuccessfully: 'Tender edited successfully',
  tenderPublishedSuccessfully: 'Tender published successfully',
  chooseProduct: 'Choose Product',
  notActivated: 'Not Activated',
  areYouSureYouWantToCancelThisTender: 'Are you sure you want to cancel this tender?',
  matchToCancelTender: 'Match to cancel tender',
  cancel: 'Cancel',
  deadlineToReceiveOffers: 'Deadline to receive offers',
  creator: 'Creator',
  deliveryProfile: 'Delivery Profile',
  tenderCancelledSuccessfully: 'Tender cancelled successfully',
  tenderDetails: 'Tender details',
  editClient: 'Edit Client',
  unpaid: 'Unpaid',
  tenderTitle: 'Tender title',
  assignARole: 'Assign a role',
  enterYourEmailToRecievePasswordResetLink: 'Enter your email to receive the password reset link',
  sendLink: 'Send link',
  resetPassword: 'Reset password',
  changePassword: 'Change password',
  addDocument: 'Add Document',
  documentType: 'Document Type',
  required: 'Required',
  chooseDocumentType: 'Choose Document Type',
  clientDocument: 'Client Document',
  paymentDocument: 'Payment Document',
  editDocument: 'Edit Document',
  true: 'True',
  false: 'False',
  documentCreatedSuccessfully: 'Document created successfully',
  documentEditedSuccessfully: 'Document edited successfully',
  clientDeactivatedSuccessfully: 'Client deactivated successfully',
  deactivateClient: 'Deactivate client',
  areYouSureYouWantToDeactivateThisClient: 'Are you sure you want to deactivate this client?',
  reason: 'Reason',
  giveAReason: 'Give a reason',
  deactivate: 'Deactivate',
  activateClient: 'Activate client',
  activate: 'Activate',
  areYouSureYouWantToActivateThisClient: 'Are you sure you want to activate this client?',
  clientActivatedSuccessfully: 'Client activated successfully',
  offersReceived: 'Offers Received',
  commissionDirector: 'Commission Director',
  makeCommissioner: 'Make Commissioner',
  makeDirector: 'Make Director',
  remove: 'Remove',
  publishedTenders: 'Published Tenders',
  myTenders: 'My Tenders',
  multiplier: 'Multiplier',
  show: 'Show',
  publish: 'Publish',
  evaluate: 'Evaluate',
  offerCreatedSuccessfully: 'Offer created successfully',
  offerEditedSuccessfully: 'Offer edited successfully',
  tenderStatusChangedSuccessfully: 'Tender status changed successfully',
  areYouSureYouWantToChangeStatusOfThisTender:
    'Are you sure you want to change status of this tender to evaluating offers?',
  matchToEvaluateTender: 'Match to change status to evaluating offers',
  confirm: 'Confirm',
  hasNotVoted: 'Has not voted',
  rejected: 'Rejected',
  vote: 'Vote',
  votes: 'Votes',
  pleaseWait: 'Please wait...',
  reject: 'Reject',
  closeTender: 'Close Tender',
  accept: 'Accept',
  cancelTender: 'Cancel tender',
  winner: 'Winner',
  notWinner: 'Not winner',
  generateContract: 'Generate contract',
  downloadContract: 'Download contract',
  contractDownloadedSuccessfully: 'Contract downloaded successfully',
  contractGeneratedSuccessfully: 'Contract generated successfully',
  contractSentSuccessfully: 'Contract sent successfully',
  sendContract: 'Send contract',
  tenderVotedSuccessfully: 'Tender voted successfully',
  tenderRevertedSuccessfully: 'Tender reverted successfully',
  revert: 'Revert',
  tenderCompletedSuccessfully: 'Tender completed successfully',
  thisSystemOnlySupportsDesktopBrowser:
    'This system only supports desktop browser, Please switch to a desktop browser before proceeding!',
  deactivated: 'Deactivated',
  activated: 'Activated',
  unit: 'Unit',
  barrels: 'Barrels',
  chooseUnit: 'Choose Unit',
  bbl: 'Barrels',
  t: 'Ton',
  itIsEqualTo: 'It is equal to',
  deleteContract: 'Delete Contract',
  contractDeletedSuccessfully: 'Contract deleted successfully',
  deliveries: 'Deliveries',
  quantity: 'Quantity',
  pickUpLocation: 'Pick up location',
  createdAt: 'Created at',
  delivered: 'Delivered',
  notDelivered: 'Not delivered',
  addDelivery: 'Add delivery',
  deliveryCreatedSuccessfully: 'Delivery created successfully',
  editDelivery: 'Edit delivery',
  deliveryEditedSuccessfully: 'Delivery edited successfully',
  oilPrice: 'Oil price',
  closedTenders: 'Closed tenders',
  price: 'Price',
  gasPrice: 'Gas price',
  chooseATender: 'Choose tender',
  totalPrice: 'Total price',
  chooseStatus: 'Choose status',
  deliveryDetails: 'Delivery Details',
  paymentDetails: 'Payment Details',
  value: 'Value',
  valueWithoutVat: 'Value without vat',
  vat: 'Vat',
  deliveryName: 'Delivery Name',
  invoiceSentSuccessfully: 'Invoice sent successfully',
  areYouSureYouWantToSendInvoiceByEmail: 'Are you sure you want to send invoice by email?',
  sendInvoice: 'Send invoice',
  invoiceDownloadedSuccessfully: 'Invoice downloaded successfully',
  downloadInvoice: 'Download invoice',
  download: 'download',
  areYouSureYouWantToDownloadInvoice: 'Are you sure you want to download invoice?',
  invoiceDeletedSuccessfully: 'Invoice deleted successfully',
  deleteInvoice: 'Delete invoice',
  areYouSureYouWantToDeleteInvoice: 'Are you sure you want to delete invoice?',
  generateInvoice: 'Generate invoice',
  generateDeliveryInvoice: 'Generate Delivery Invoice',
  areYouSureYouWantToActivateThisUser: 'Are you sure you want to activate this user?',
  userActivatedSuccessfully: 'User activated successfully',
  userDeactivatedSuccessfully: 'User deactivated successfully',
  deactivateUser: 'Deactivate user',
  areYouSureYouWantToDeactivateThisUser: 'Are you sure you want to deactivate this user',
  areYouSureYouWantToGenerateInvoice: 'Are you sure you want to generate invoice?',
  pickUpDate: 'Pick up date',
  addCommission: 'Add commission',
  makeOffer: 'Make offer',

  errors: {
    empty_name: 'Name cannot be empty',
    empty_lastname: 'Last Name cannot be empty',
    invalid_email: 'Invalid form email address',
    empty_phoneNumber: 'Phone Number cannot be empty',
    empty_email: 'Email cannot be empty',
    empty_businessUnitCode: 'Business Unit Code cannot be empty',
    empty_operatorCode: 'Operator code cannot be empty',
    empty_tcrCode: 'Trc code cannot be empty',
    empty_tcrMaintainerCode: 'Trc Maintainer code cannot be empty',
    empty_role: 'Please provide a role',
    empty_password: 'Password cannot be empty',
    password_confirmation: 'Passwords do not match',
    empty_companyName: 'Company Name cannot be empty',
    empty_nuis: 'Nuis cannot be empty',
    empty_administrator: 'Administrator cannot be empty',
    empty_fullAddress: 'Address cannot be empty',
    empty_country: 'Please select a country',
    empty_city: 'City cannot be empty',
    empty_authorizedPerson: 'Authorized person cannot be empty',
    empty_usage: 'Please provide a type',
    empty_reason: 'Please provide deactivation reason',
    empty_citizenship: 'Citizenship cannot be empty',
    empty_commission: 'Commission cannot be empty',
    empty_title: 'Title cannot be empty',
    empty_description: 'Description cannot be empty',
    empty_publish_date: 'Publish date cannot be empty',
    empty_end_date: 'End date cannot be empty',
    empty_offer_validity_interval: 'Offer validity interval cannot be empty',
    empty_terms_and_conditions: 'Terms and conditions cannot be empty',
    empty_product: 'Product cannot be empty',
    empty_shareholders: 'Shareholder information cannot be empty',
    empty_status: 'Status cannot be empty',
    empty_amount: 'Amount cannot be empty',
    empty_price: 'Price cannot be empty',
    empty_operatorPrice: 'Operator price cannot be empty',
    empty_totalPrice: 'Total price cannot be empty',
    empty_pick_up: 'Pick up cannot be empty',
    empty_unit: 'Unit cannot be empty'
  }
};

export default en;
