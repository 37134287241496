import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '@core/inputs/Input';
import CDefaultDatePicker from '@core/inputs/dateTime/CDefaultDatePicker';
import NumericalInput from '@core/inputs/NumericalInput';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TextArea from '@core/textareas/TextArea';
import moment from 'moment';

const TenderDetails = ({ disabled = false, data, onChange, clearError, errors }) => {
  const { t } = useTranslation();
  const today = moment().format('YYYY-MM-DD');

  const handleChange = (value, name) => {
    onChange({ ...data, [name]: value });
  };

  return (
    <div className="px-20">
      <h1 className="flex border-b-2 pt-4 pb-2 mb-2 font-semibold text-xl">{t('details')}</h1>
      <div className="my-4 w-1/2">
        <Input
          height="h-10"
          label={t('title')}
          placeholder={t('title')}
          value={data?.name}
          onChange={(value) => {
            clearError('title');
            handleChange(value, 'name');
          }}
          error={errors('title')}
        />
      </div>
      <div className="my-4 w-2/3">
        <TextArea
          height="h-10"
          label={t('description')}
          placeholder={t('description')}
          value={data?.description}
          onChange={(value) => {
            clearError('description');
            handleChange(value, 'description');
          }}
          error={errors('description')}
        />
      </div>
      <div className="mt-6 mb-8 grid grid-cols-3 gap-x-5">
        <div>
          <div className="text-sm font-semibold mb-2">{t('publishDate')}</div>
          <CDefaultDatePicker
            minDate={today}
            disabled={disabled}
            value={data?.publishDate}
            onChange={(value) => {
              clearError('publishDate');
              handleChange(value, 'publishDate');
            }}
            error={errors('publishDate')}
          />
        </div>
        <div>
          <div className="text-sm font-semibold mb-2">{t('endDate')}</div>
          <CDefaultDatePicker
            minDate={data?.publishDate}
            disabled={disabled}
            value={data?.endDate}
            onChange={(value) => {
              clearError('endDate');
              handleChange(value, 'endDate');
            }}
            error={errors('endDate')}
          />
        </div>
        <NumericalInput
          height="h-10"
          label={t('offerValidityInterval')}
          placeholder={t('offerValidityInterval')}
          value={data?.offerValidityInterval}
          onChange={(value) => {
            clearError('offerValidityInterval');
            handleChange(parseFloat(value), 'offerValidityInterval');
          }}
          error={errors('offerValidityInterval')}
        />
      </div>
      <div className="my-4">
        <CKEditor
          editor={ClassicEditor}
          config={{ placeholder: t('termsAndConditions') }}
          data={data?.termsAndConditions}
          onBlur={(event, editor) => {
            const val = editor.getData();
            handleChange(val, 'termsAndConditions');
          }}
        />
        {errors('termsAndConditions') && (
          <div className="text-xs text-red-400 pt-2">{errors('termsAndConditions')}</div>
        )}
      </div>
    </div>
  );
};

export default TenderDetails;
