import React, { useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import DeliveriesIndex from '@components/Delivery/DeliveriesIndex';
import { useTranslation } from 'react-i18next';

const Lot = ({ selectedLot, selectedTender }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`flex justify-between mx-6 mt-5 ${
          !isOpen ? 'mb-5 bg-white' : 'bg-gray-100'
        } border rounded-xl py-3 px-10  cursor-pointer shadow-md hover:bg-gray-100`}>
        <div className="flex justify-start space-x-8 text-gray-600 text-sm">
          <h1>
            {t('product')}: {selectedLot.product}
          </h1>
          <h1>
            {t('amount')}:{' '}
            {selectedLot.originalUnit === 'bbl'
              ? selectedLot.barrels.toFixed(2)
              : selectedLot.amount.toFixed(2)}{' '}
            {selectedLot.originalUnit}
          </h1>
          <h1>
            {t('startDate')}: {selectedLot.startDate}
          </h1>
          <h1>
            {t('pickUpLocation')}: {selectedLot.pickUp}
          </h1>
        </div>
        {!isOpen ? (
          <FaArrowDown className="text-primary text-xl" />
        ) : (
          <FaArrowUp className="text-primary text-xl" />
        )}
      </div>
      {isOpen && <DeliveriesIndex selectedTender={selectedTender} selectedLot={selectedLot} />}
    </>
  );
};
export default Lot;
