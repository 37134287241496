const emailConstraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_email'
    },
    email: {
      message: 'errors.invalid_email'
    }
  }
};
export default emailConstraints;
