import API from '@utils/plugins/API';
import GuestAPI from '@utils/plugins/GuestAPI';

const UserService = {
  all: (params) => {
    return API.get('users', { params });
  },
  create: (
    firstName,
    lastName,
    email,
    phone,
    roleId,
    businessUnitCode,
    operatorCode,
    tcrCode,
    tcrMaintainerCode
  ) => {
    return API.post('users', {
      firstName,
      lastName,
      email,
      phone,
      roleId,
      businessUnitCode,
      operatorCode,
      tcrCode,
      tcrMaintainerCode
    });
  },
  update: (
    id,
    firstName,
    lastName,
    email,
    phone,
    roleId,
    businessUnitCode,
    operatorCode,
    tcrCode,
    tcrMaintainerCode
  ) => {
    return API.put(`users/${id}`, {
      firstName,
      lastName,
      email,
      phone,
      roleId,
      businessUnitCode,
      operatorCode,
      tcrCode,
      tcrMaintainerCode
    });
  },
  find: (id) => {
    return API.get(`users/${id}`);
  },
  destroy: (id) => {
    return API.delete(`users/${id}`);
  },
  allRoles: () => {
    return API.get('users/roles');
  },
  statistics: () => {
    return API.get(`users/statistics`);
  },
  validate: (token) => {
    return GuestAPI.post('activate-user/validate-token', { token });
  },
  activate: (password, passwordConfirmation, token) => {
    return GuestAPI.post('activate-user', {
      password,
      passwordConfirmation,
      token
    });
  },
  resendActivationLink: (id) => {
    return API.post(`users/${id}/resend-activation-email`);
  },
  changePassword: (id, password, passwordConfirmation) => {
    return API.patch(`users/${id}/change-password`, {
      password,
      passwordConfirmation
    });
  },
  activateUser: (id) => {
    return API.get(`users/${id}/activate`);
  },
  deactivate: (id) => {
    return API.get(`users/${id}/deactivate`);
  }
};

export default UserService;
