export const internalSystemRoles = [
  'administrator',
  'drejtor_organizativ',
  'drejtor_teknik',
  'drejtor_juridik',
  'drejtor_prokurimi',
  'drejtor_finance',
  'specialist'
];

export const allRoles = [
  'administrator',
  'klient',
  'drejtor_organizativ',
  'drejtor_teknik',
  'drejtor_juridik',
  'drejtor_prokurimi',
  'drejtor_finance',
  'specialist'
];

export const admin = ['administrator'];
export const client = ['klient'];
