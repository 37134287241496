import React, { useMemo, useState } from 'react';
import { randomCode, showError, showSuccess } from '@utils/helpers';
import { useTranslation } from 'react-i18next';
import CodeInput from '@core/inputs/CodeInput';
import TenderService from '@services/TenderService';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import RedButton from '@core/buttons/electrons/RedButton';
import BlankModal from '@core/modals/BlankModal';
import { FaStopCircle } from 'react-icons/fa';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { admin } from '@constants/roles';

const CancelTender = ({ id, onCancelled }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [codeInput, setCodeInput] = useState('');

  const code = useMemo(() => {
    if (showModal) {
      return randomCode();
    }
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
    setCodeInput('');
  };

  const onSubmit = () => {
    if (code === codeInput) {
      TenderService.cancel(id)
        .then(() => {
          showSuccess(t('tenderCancelledSuccessfully'));
          onCancelled();
          closeModal();
        })
        .catch((err) => {
          showError(err.response.data.message);
        });
    } else showError(t('codeDoesNotMatch'));
  };

  return (
    <PermissionHandler roles={admin}>
      <DefaultButton
        title={t('cancel')}
        sm
        label={
          <div className="flex items-center">
            <FaStopCircle />
          </div>
        }
        bgColor="bg-red-100"
        bgColorHover="hover:bg-red-300"
        textColor="text-red-700"
        onClick={() => setShowModal(true)}
      />
      <BlankModal
        width="w-1/3"
        show={showModal}
        title={`${t('areYouSure')}`}
        onClose={closeModal}
        icon={<FaStopCircle className="text-red-800" />}
        iconBg="bg-red-100"
        maxWidth="3xl"
        otherButtons={[<RedButton sm onClick={onSubmit} label={t('cancel')} />]}>
        <div className="text-lg my-3 ml-5 text-center">
          <span>{t('areYouSureYouWantToCancelThisTender')}</span>
        </div>
        <div className="flex flex-col justify-center mx-auto pt-3">
          <h1 className="flex py-2 font-semibold text-xl text-center justify-center mx-auto">
            {t('matchToCancelTender')}
          </h1>
          <div className="py-2 text-center">{code}</div>
          <div className="flex py-4 justify-center mx-auto">
            <CodeInput value={codeInput} onChange={setCodeInput} fields={6} />
          </div>
        </div>
      </BlankModal>
    </PermissionHandler>
  );
};

export default CancelTender;
