import ReactCodeInput from 'react-code-input';
import React from 'react';

const CodeInput = ({ key, value, onChange, fields = 4, inputWidth = '60px', height = '70px' }) => {
  const props = {
    inputStyle: {
      fontFamily: 'inter',
      margin: '4px',
      MozAppearance: 'textfield',
      width: inputWidth,
      borderRadius: '3px',
      border: '1px solid #E3E2FC',
      fontSize: '24px',
      height,
      paddingLeft: '7px',
      color: 'rgb(101, 104, 105)',
      boxShadow: 'rgb(222 222 222) 1px 1px 1px 1px',
      textAlign: 'center'
    }
  };

  return (
    <ReactCodeInput
      key={key}
      name="code"
      type="text"
      value={value}
      fields={fields}
      onChange={onChange}
      inputMode="numeric"
      {...props}
    />
  );
};

export default CodeInput;
