import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from '@pages/LoginPage';
import GuestRoute from '@router/GuestRoute';
import UsersPage from '@pages/UsersPage';
import AuthRoute from '@router/AuthRoute';
import HomePage from '@pages/HomePage';
import { Routes } from 'react-router';
import ClientsPage from '@pages/ClientsPage';
import PasswordConfirmationPage from '@pages/PasswordConfirmationPage';
import TendersPage from '@pages/Tender/TendersPage';
import TwoFactorAuthenticationPage from '@pages/TwoFactorAuthenticationPage';
import InvoicesPage from '@pages/InvoicesPage';
import DocumentsPage from '@pages/DocumentsPage';
import ForgotPasswordPage from '@pages/ForgotPasswordPage';
import PasswordResetPage from '@pages/PasswordResetPage';
import DeliveriesPage from '@pages/DeliveriesPage';
import PublishedTendersPage from '@pages/Tender/PublishedTendersPage';
import MyTendersPage from '@pages/Tender/MyTendersPage';
import ShowTenderPage from '@pages/Tender/ShowTender';
import EditTenderPage from '@pages/Tender/EditTenderPage';
import CreateTenderPage from '@pages/Tender/CreateTenderPage';
import MakeTenderOfferPage from '@pages/Tender/MakeTenderOfferPage';
import EvaluateOffersPage from '@pages/Tender/EvaluateOffersPage';
import MobilePage from '@pages/MobilePage';
import { allRoles, client, internalSystemRoles, admin } from '@constants/roles';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/mobile" element={<MobilePage />} />
        <Route element={<GuestRoute />}>
          <Route exact path="/login" element={<LoginPage />} />
          <Route path="/activate" element={<PasswordConfirmationPage />} />
          <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route exact path="/reset-password" element={<PasswordResetPage />} />
          <Route
            exact
            path="/two-factor-authentication/:token"
            element={<TwoFactorAuthenticationPage />}
          />
        </Route>
        <Route element={<AuthRoute />}>
          <Route exact path="/" element={<HomePage roles={internalSystemRoles} />} />
          <Route exact path="/users" element={<UsersPage roles={internalSystemRoles} />} />
          <Route exact path="/clients" element={<ClientsPage roles={internalSystemRoles} />} />
          <Route exact path="/tenders" element={<TendersPage roles={internalSystemRoles} />} />
          <Route exact path="/tenders/create-tender" element={<CreateTenderPage roles={admin} />} />
          <Route
            exact
            path="/tenders/show-tender/:tenderId"
            element={<ShowTenderPage roles={internalSystemRoles} />}
          />
          <Route
            exact
            path="/tenders/evaluate-tender/:tenderId"
            element={<EvaluateOffersPage roles={internalSystemRoles} />}
          />
          <Route
            exact
            path="/tenders/edit-tender/:tenderId"
            element={<EditTenderPage roles={admin} />}
          />
          <Route
            exact
            path="/published-tenders"
            element={<PublishedTendersPage roles={client} />}
          />
          <Route exact path="/my-tenders" element={<MyTendersPage roles={client} />} />
          <Route
            exact
            path="/published-tenders/tender/:tenderId"
            element={<MakeTenderOfferPage roles={client} />}
          />
          <Route exact path="/my-tenders/tender/:tenderId" element={<MakeTenderOfferPage />} />
          <Route exact path="/invoices" element={<InvoicesPage />} />
          <Route exact path="/documents" element={<DocumentsPage />} />
          <Route exact path="/deliveries" element={<DeliveriesPage roles={admin} />} />
          <Route
            exact
            path="/my-tenders/tender/:tenderId"
            element={<MakeTenderOfferPage roles={client} />}
          />
          <Route exact path="/invoices" element={<InvoicesPage roles={allRoles} />} />
          <Route exact path="/documents" element={<DocumentsPage roles={internalSystemRoles} />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
