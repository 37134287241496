const clientDataConstraints = {
  firstName: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_name'
    }
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_lastname'
    }
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_email'
    },
    email: {
      message: 'errors.invalid_email'
    }
  },
  phoneNumber: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_phone_number'
    }
  },
  companyName: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_company_name'
    }
  },
  nuis: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_nuis'
    }
  },
  administrator: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_administrator'
    }
  },
  userEmail: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_email'
    },
    email: {
      message: 'errors.invalid_email'
    }
  },
  alternativeEmail: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_email'
    },
    email: {
      message: 'errors.invalid_email'
    }
  },
  fullAddress: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_full_address'
    }
  },
  country: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_country'
    }
  },
  city: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_city'
    }
  },
  authorizedPerson: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_authorized_person'
    }
  },
  authorizedPersonEmail: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_email'
    },
    email: {
      message: 'errors.invalid_email'
    }
  },
  authorizedPersonPhone: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_phone_number'
    }
  }
};
export default clientDataConstraints;
