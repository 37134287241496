import API from '@utils/plugins/API';

const DashboardService = {
  time: () => {
    return API.get('dashboard/hour');
  },
  statistics: () => {
    return API.get('dashboard/statistics');
  }
};

export default DashboardService;
