import GuestAPI from '@utils/plugins/GuestAPI';
import API from '@utils/plugins/API';

const AuthService = {
  login: (email, password) => {
    return GuestAPI.post('login', {
      email,
      password
    });
  },
  passwordResetLink: (email) => {
    return API.put('password-reset/request', {
      email
    });
  },
  validate: (token) => {
    return API.post('password-reset/validate-token', {
      token
    });
  },
  passwordReset: (password, passwordConfirmation, token) => {
    return API.post('password-reset', {
      password,
      passwordConfirmation,
      token
    });
  }
};

export default AuthService;
