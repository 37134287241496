import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import TenderService from '@services/TenderService';
import { useNavigate, useParams } from 'react-router';
import RowDetails from '@hoc/cruds/RowDetails';
import TenderOverview from '@components/Tender/partials/TenderOverview';
import DataTable from 'react-data-table-component';
import useCanAccessRoute from '@hooks/useCanAccessRoute';
import UserStatus from '@components/User/partials/UserStatus';

const ShowTenderPage = ({ roles = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tenderId } = useParams();

  const { hasAccess, redirect } = useCanAccessRoute({ roles });

  const [details, setDetails] = useState({});
  const [lots, setLots] = useState([]);
  const [clickedLotIndex, setClickedLotIndex] = useState(0);
  const [clickedLot, setClickedLot] = useState({});
  const [commission, setCommission] = useState([]);
  const [commissionDirector, setCommissionDirector] = useState();

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirect);
    }
  }, [hasAccess, navigate, redirect]);

  useEffect(() => {
    if (!tenderId) {
      navigate('/');
    }
  }, [navigate, tenderId]);

  const getData = useCallback(() => {
    TenderService.find(tenderId).then((res) => {
      const data = res.data.data.procurement;
      setDetails(data);
      setLots(data.lots);
      setCommission(data.commission);
      setCommissionDirector(data.commissionDirector);
      setClickedLot(data?.lots[0]);
    });
  }, [tenderId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const headers = useMemo(
    () => [
      {
        id: 'fullName',
        name: t('fullName'),
        cell: (row) => `${row.firstName} ${row.lastName}`,
        sortable: true
      },
      {
        id: 'email',
        name: t('email'),
        cell: (row) => row.email,
        sortable: true
      },
      {
        id: 'phone',
        name: t('phone'),
        cell: (row) => row.phone,
        sortable: true
      },
      {
        id: 'role',
        name: t('role'),
        cell: (row) => row.role,
        sortable: true,
        minWidth: '300px'
      },
      {
        id: 'status',
        name: t('status'),
        cell: (row) => <UserStatus status={row.status}>{row.status}</UserStatus>,
        sortable: true,
        width: '150px'
      }
    ],
    [t]
  );

  return (
    <>
      <div className="min-h-screen flex flex-col bg-gray-100">
        <h1 className="flex font-semibold text-xl mt-5 mx-10 pl-2 justify-between">
          {t('title')} : {details.name}
          <div className="font-semibold leading-5 mr-5">
            {t('offersReceived')} :
            <span className="ml-5">{details?.offersReceived ? details?.offersReceived : '0'}</span>
          </div>
        </h1>
        <div className="flex">
          <TenderOverview details={details} />
          <div className="flex flex-col w-full">
            <div className="ml-10 mr-5 my-5 bg-white m-5 rounded-md font-semibold leading-5 p-5 border border-gray-300 shadow-lg">
              <div className="flex mb-10">
                {lots?.map((lot, index) => (
                  <div
                    onClick={() => {
                      setClickedLotIndex(index);
                      setClickedLot(lot);
                    }}
                    className={`mr-2 px-3 py-2 border border-primary w-16 text-center rounded-lg hover:cursor-pointer hover:bg-orange-50 hover:text-black ${
                      clickedLotIndex === index && 'bg-primary text-white'
                    }`}>{`${t('lot')} ${index + 1}`}</div>
                ))}
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <RowDetails label={t('name')} value={clickedLot.name} />
                <RowDetails
                  label={t('deliveryProfile')}
                  value={`${clickedLot.startDate} - ${clickedLot.endDate}`}
                />
              </div>
              <div className="grid grid-cols-3 gap-5 mt-5">
                <div className="flex flex-col">
                  <RowDetails
                    label={t('amount')}
                    value={`${
                      clickedLot?.originalUnit === 'bbl' ? clickedLot?.barrels : clickedLot?.amount
                    } ${t(clickedLot.originalUnit)}`}
                  />
                  <div className="mx-10 mb-4 text-xs pt-2 text-semibold">{`${t('itIsEqualTo')}  ${
                    clickedLot?.originalUnit === 'bbl' ? clickedLot?.amount : clickedLot?.barrels
                  } ${
                    // eslint-disable-next-line no-nested-ternary
                    clickedLot?.originalUnit === 'bbl'
                      ? clickedLot?.product === 'OIL'
                        ? t('t')
                        : t('nm3')
                      : t('barrels')
                  }`}</div>
                </div>
                <RowDetails label={t('product')} value={clickedLot.product} />
                <RowDetails label={t('pickUp')} value={clickedLot.pickUp} />
              </div>
            </div>
            {commission?.length !== 0 && (
              <div className="ml-10 my-5 bg-white rounded-md font-semibold leading-5 p-5 border border-gray-300 shadow-lg">
                <div className="text-sm font-bold mb-3 flex items-center text-primary">
                  {t('commissionDirector')}
                </div>
                <div className="flex justify-between m-4">
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 border-b">{t('fullName')}</div>
                    <div className="text-xs py-3">{`${commissionDirector?.firstName} ${commissionDirector?.lastName}`}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 border-b">{t('email')}</div>
                    <div className="text-xs py-3">{commissionDirector?.email}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 border-b">{t('phone')}</div>
                    <div className="text-xs py-3">{commissionDirector?.phone}</div>
                  </div>
                  <div className="hidden xl:flex flex-col">
                    <div className="text-xs text-gray-500 border-b">{t('role')}</div>
                    <div className="text-xs py-3">{commissionDirector?.role}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 border-b">{t('status')}</div>
                    <div className="text-xs py-3">
                      <UserStatus status={commissionDirector?.status}>
                        {commissionDirector?.status}
                      </UserStatus>
                    </div>
                  </div>
                </div>
                <div className="text-sm font-bold mb-3 flex items-center text-primary mt-5">
                  {t('commission')}
                </div>
                <DataTable columns={headers} data={commission} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="sticky w-full shadow border-t bottom-0 bg-white flex items-center justify-end py-3 px-3">
        <Link to="/tenders">
          <div className="flex cursor-pointer mr-5">
            <FaArrowLeft className="mt-1.5 mr-2" />
            {t('goBack')}
          </div>
        </Link>
      </div>
    </>
  );
};
export default ShowTenderPage;
