const al = {
  welcomeTitle: 'Mirëseerdhe',
  pleaseSignInToAccessTheSystem: 'Ju lutemi kyçuni në llogarinë tuaj për të aksesuar sistemin.',
  noData: 'Ska të dhëna',
  forgotPassword: 'Harruat fjalëkalimin?',
  email: 'Email',
  password: 'Password',
  rememberMe: 'Mbaj kyçur',
  login: 'Kyçuni',
  quickLogin: 'Quick login',
  home: 'Kryefaqja',
  client: 'Klient',
  clients: 'Klientët',
  tender: 'Tender',
  tenders: 'Tenderat',
  user: 'Përdorues',
  users: 'Përdoruesit',
  invoice: 'Faturë',
  invoices: 'Faturat',
  documentTypes: 'Llojet e dokumentave',
  oil: 'Naftë',
  litres: 'Litra',
  nm3: 'NM3',
  naturalGas: 'Gaz natyror',
  gas: 'Gaz',
  oilBought: 'Naftë e blerë',
  bought: 'blerë',
  naturalGasBought: 'Gaz natyror i blerë',
  overview: 'Pasqyrë e pergjithshme',
  ton: 'Ton',
  activeTenders: 'Tenderat aktiv',
  name: 'Emri',
  publishDate: 'Data e publikimit',
  closureDate: 'Data e mbylljes',
  evaluationDuration: 'Kohëzgjatja e vlerësimit',
  status: 'Statusi',
  actions: 'Veprimet',
  companyName: 'Emri kompanisë',
  country: 'Shteti',
  administrator: 'Administratori',
  active: 'Aktiv',
  inactive: 'Jo aktiv',
  pending: 'Në pritje',
  total: 'Totali',
  fullName: 'Emri i plotë',
  phone: 'Telefoni',
  role: 'Roli',
  search: 'Kërko',
  logout: 'Dil',
  submit: 'Ruaj',
  confirmPassword: 'Konfirmo fjalëkalimin',
  activateUser: 'Aktivizo përdoruesin',
  enterPassword: 'Shkruaj fjalëkalimin',
  backToLoginPage: 'Kthehu tek faqja kryesore per tu kyçur',
  closed: 'Të mbyllur',
  processing: 'Duke u proçesuar',
  twoStepAuthentication: 'Verifikimi me dy hapa',
  enterTheSixDigitCodeThatYouReceivedOnYourApp:
    'Vendosni kodin me 6 shifra që morët në aplikacionin tuaj',
  scanTheQrToReceiveTheSixDigitCode: 'Skanoni kodin QR per të marrë kodin gjashtë shifror',
  date: 'Data',
  paid: 'Paguar',
  remaining: 'Mbetur',
  tenderId: 'Id e tenderit',
  language: 'Gjuha',
  addUser: 'Shto përdorues',
  userCreatedSuccessfully: 'Përdoruesi u krijua me sukses',
  edit: 'Modifiko',
  editUser: 'Modifiko përdoruesin',
  userEditedSuccessfully: 'Përdoruesi u modifikua me sukses',
  firstName: 'Emri',
  lastName: 'Mbiemri',
  businessUnitCode: 'Kodi i degës së biznesit',
  operatorCode: 'Kodi operatorit',
  tcrCode: 'Kodi TCR',
  tcrMaintainerCode: 'Kodi i mirëmbajtesit të TCR',
  add: 'Shto',
  delete: 'Fshi',
  close: 'Mbyll',
  areYouSure: 'A jeni i sigurt?',
  areYouSureYouWantToDeleteThisEntity: 'A jeni i sigurt që dëshironi të fshini këtë entitet?',
  send: 'Dërgo',
  resendActivationLink: 'Ridërgo linkun e aktivizimit',
  areYourSureYouWantToResendActivationLink:
    'A jeni i sigurt që dëshironi të ridërgoni linkun e aktivizimit?',
  linkSent: 'Linku u dërgua me sukses',
  deletedSuccessfully: 'U fshi me sukses',
  addClient: 'Shto Klient',
  clientCreatedSuccessfully: 'Klienti u krijua me sukses',
  clientDetails: 'Detajet e Klientit',
  shareholders: 'Aksionerët',
  usersDetails: 'Detajet e Përdoruesit',
  nuis: 'NIPT',
  alternativeEmail: 'Email Alternativ',
  fullAddress: 'Adresa e plotë',
  authorizedPerson: 'Personi i Autorizuar',
  clientsDetails: 'Detajet e Klientit',
  clientEditedSuccessfully: 'Klienti u modifikua me sukses',
  addShareholder: 'Shto aksioner të ri',
  editClient: 'Modifiko klientin',
  addTender: 'Shto Tender',
  draft: 'Draft',
  decryptingOffers: 'Deshifrimi i Ofertave',
  evaluatingOffers: 'Duke vlerësuar ofertat',
  approved: 'Aprovuar',
  published: 'Publikuar',
  canceled: 'Anulluar',
  evaluatingExpired: 'Vlerësimi i skaduar',
  days: 'ditë',
  details: 'Detajet',
  title: 'Titulli',
  description: 'Përshkrimi',
  endDate: 'Data e përfundimit',
  offerValidityInterval: 'Intervali i vlerësimit të ofertës',
  termsAndConditions: 'Termat dhe Kushtet',
  lots: 'Lote',
  lot: 'Loti',
  addLot: 'Shto Lot',
  startDate: 'Data e fillimit',
  amount: 'Sasia',
  product: 'Produkti',
  pickUp: 'Pika e grumbullimit',
  save: 'Ruaj',
  rank: 'Ranku',
  commission: 'Komisioni',
  searchCommission: 'Kërko Komision',
  goBack: 'Ktheu',
  publishTender: 'Publiko Tender',
  matchToDeleteTender: 'Përshtat për të fshirë këtë tender',
  matchToPublishTender: 'Përshtat për të publikuar këtë tender',
  tenderCreatedSuccessfully: 'Tenderi u kriijua me sukses',
  tenderEditedSuccessfully: 'Tenderi u modifiikua me sukses',
  tenderPublishedSuccessfully: 'Tenderi u publikua me sukses',
  chooseProduct: 'Zgjidh produktin',
  notActivated: 'Të pa aktivizuar',
  areYouSureYouWantToCancelThisTender: 'A jeni i sigurt që dëshironi të anulloni këtë tender?',
  matchToCancelTender: 'Përshtat për të anulluar këtë tender',
  cancel: 'Anullo',
  deadlineToReceiveOffers: 'Afati i fundit për të marrë ofertat',
  creator: 'Krijuesi',
  deliveryProfile: 'Profili i dorëzimit',
  tenderCancelledSuccessfully: 'Tenderi u anullua me sukses',
  tenderDetails: 'Detajet e tenderit',
  unpaid: 'Pa paguar',
  tenderTitle: 'Titulli i tenderit',
  assignARole: 'Zgjidhni një rol',
  enterYourEmailToRecievePasswordResetLink:
    'Vendosni email-in tuaj që të dërgojmë linkun për të rivendosur fjalëkalimin',
  sendLink: 'Dërgo linkun',
  resetPassword: 'Rivendosni fjalëkalimin',
  changePassword: 'Ndryshoni fjalëkalimin',
  addDocument: 'Shto Dokument',
  documentType: 'Llojet e dokumentave',
  required: 'E detyrueshme',
  chooseDocumentType: 'Zgjidh llojin e dokumentit',
  clientDocument: 'Dokument Klienti',
  paymentDocument: 'Dokument pagese',
  editDocument: 'Modifiko Dokumentin',
  true: 'Po',
  citizenship: 'Shtetësia',
  documents: 'Dokumenta',
  city: 'Qyteti',
  false: 'Jo',
  documentCreatedSuccessfully: 'Document u krijua me sukses',
  documentEditedSuccessfully: 'Dokumenti u modifikua me sukses',
  clientDeactivatedSuccessfully: 'Klienti u çaktivizua me sukses',
  deactivateClient: 'Çaktivizo klientin',
  areYouSureYouWantToDeactivateThisClient:
    'A jeni i sigurt që dëshironi të çaktivizoni këtë klient?',
  reason: 'Arsyeja',
  giveAReason: 'Jepni një arsye',
  deactivate: 'Çaktivizo',
  areYouSureYouWantToResendActivationLink:
    'Jeni të sigurt që dëshironi të ridërgoni linkun e aktivizimit?',
  offersReceived: 'Ofertat',
  commissionDirector: 'Drejtor i komisionit',
  makeCommissioner: 'Bëj komisioner',
  makeDirector: 'Bëj drejtor',
  remove: 'Hiq',
  publishedTenders: 'Tenderat e publikuar',
  myTenders: 'Tenderat e mi',
  multiplier: 'Koeficienti',
  show: 'Shiko',
  publish: 'Publiko',
  evaluate: 'Vlerëso',
  offerCreatedSuccessfully: 'Oferta u krijua me sukses',
  offerEditedSuccessfully: 'Oferta u modifikua me sukses',
  tenderStatusChangedSuccessfully: 'Statusi i tenderit u ndryshua me sukses',
  areYouSureYouWantToChangeStatusOfThisTender:
    'Jeni të sigurt që dëshironi të ndryshoni statusin e tenderit?',
  matchToEvaluateTender: 'Përshtat për të ndryshuar statusin e këtij tenderi',
  confirm: 'Konfirmo',
  hasNotVoted: 'Nuk ka votuar',
  rejected: 'Refuzuar',
  vote: 'Vota',
  votes: 'Votat',
  pleaseWait: 'Ju lutem prisni...',
  reject: 'Refuzo',
  closeTender: 'Mbyll tenderin',
  accept: 'Prano',
  cancelTender: 'Anullo tenderin',
  winner: 'Fitues',
  notWinner: 'Jo fitues',
  generateContract: 'Gjenero kontratë',
  downloadContract: 'Shkarko kontratë',
  contractDownloadedSuccessfully: 'Kontrata u shkarkua me sukses',
  contractGeneratedSuccessfully: 'Kontrata u gjenerua me sukses',
  contractSentSuccessfully: 'Kontrata u dërgua me sukses',
  sendContract: 'Dërgo kontratë',
  tenderVotedSuccessfully: 'Tender u votua me sukses',
  tenderRevertedSuccessfully: 'Tenderi u kthye me sukses',
  revert: 'Kthe',
  tenderCompletedSuccessfully: 'Tenderi u krye me sukses',
  activateClient: 'Aktivizo klientin',
  activate: 'Aktivizo',
  areYouSureYouWantToActivateThisClient: 'A jeni i sigurt që dëshironi të aktivizoni këtë klient?',
  clientActivatedSuccessfully: 'Klienti u aktivizua me sukses',
  deactivated: 'Çaktivizuar',
  activated: 'Aktivizuar',
  unit: 'Njësia',
  barrels: 'Barrela',
  chooseUnit: 'Zgjidh njësinë',
  bbl: 'Barrela',
  t: 'Ton',
  thisSystemOnlySupportsDesktopBrowser:
    'Ky sistem suporton vetëm shfletues në desktop, ju lutemi kaloni në një shfletues desktop përpara se të vazhdoni!',
  itIsEqualTo: 'Është e barabartë me',
  deleteContract: 'Fshi kontratën',
  contractDeletedSuccessfully: 'Kontrata u fshi me sukses',
  deliveries: 'Dorëzimet',
  quantity: 'Sasia',
  pickUpLocation: 'Vëndi dorëzimit',
  createdAt: 'Krijuar më',
  delivered: 'Dorëzuar',
  notDelivered: 'Padorëzuar',
  addDelivery: 'Shto dorëzim',
  deliveryCreatedSuccessfully: 'Dorëzimi u krijua me sukses',
  editDelivery: 'Modifiko dorëzimin',
  deliveryEditedSuccessfully: 'Dorëzimi u modifikua me sukses',
  oilPrice: 'Çmimi i naftës',
  closedTenders: 'Tenderat e mbyllur',
  price: 'Vlera',
  gasPrice: 'Çmimi i gazit',
  chooseATender: 'Zgjidhni tenderin',
  totalPrice: 'Çmimi total',
  chooseStatus: 'Zgjidhni statusin',
  deliveryDetails: 'Detajet e dorëzimit',
  paymentDetails: 'Detajet e pagesës',
  value: 'Vlera',
  valueWithoutVat: 'Vlera pa TVSH',
  vat: 'TVSH',
  deliveryName: 'Emri i dorëzimit',
  invoiceGeneratedSuccessfully: 'Fatura u gjenerua me sukses',
  generateDeliveryInvoice: 'Gjenero faturën e dorëzimit',
  generate: 'Gjenero',
  areYouSureYouWantToGenerateInvoice: 'A jeni i sigurt që dëshironi të gjeneroni faturën?',
  invoiceSentSuccessfully: 'Fatura u dërgua me sukses',
  areYouSureYouWantToSendInvoiceByEmail:
    'A jeni i sigurt qe dëshironi të dërgoni faturën me Email ?',
  sendInvoice: 'Dërgo faturën',
  invoiceDownloadedSuccessfully: 'Fatura u shkarkua me sukses',
  downloadInvoice: 'Shkarko faturën',
  download: 'Shkarko',
  areYouSureYouWantToDownloadInvoice: 'A jeni i sigurt që dëshironi të shkarkoni faturën?',
  invoiceDeletedSuccessfully: 'Fatura u fshi me sukses',
  deleteInvoice: 'Fshi faturën',
  areYouSureYouWantToDeleteInvoice: 'A jeni i sigurt që dëshironi të fshini faturën?',
  generateInvoice: 'Gjenero faturën',
  areYouSureYouWantToActivateThisUser: 'A jeni i sigurt që dëshironi të aktivizoni këtë përdorues?',
  userActivatedSuccessfully: 'Përdoruesi u aktivizua me sukses',
  userDeactivatedSuccessfully: 'Përdoruesi u çaktivizua me sukses',
  deactivateUser: 'Çaktivizo përdoruesin',
  areYouSureYouWantToDeactivateThisUser:
    'A jeni i sigurt që dëshironi të çaktivizoni këtë përdorues?',
  pickUpDate: 'Data e grumbullimit',
  addCommission: 'Shto komision',
  makeOffer: 'Bëj ofertë',

  errors: {
    empty_name: 'Emri nuk mund të jetë bosh',
    empty_lastname: 'Mbiemri nuk mund të jetë bosh',
    invalid_email: 'Adresa e emailit nuk është në formatin e duhur',
    empty_phone_number: 'Numri i telefonit nuk mund të jetë bosh',
    empty_email: 'Email nuk mund të jetë bosh',
    empty_businessUnitCode: 'Kodi i degës së biznesit nuk mund të jetë bosh',
    empty_operatorCode: 'Kodi operatorit nuk mund të jetë bosh',
    empty_tcrCode: 'Kodi TCR nuk mund të jetë bosh',
    empty_tcrMaintainerCode: 'nuk mund të jetë bosh',
    empty_role: 'Ju lutemi zgjidhni një rol',
    empty_password: 'Fjalëkalimi nuk mund të jetë bosh',
    password_confirmation: 'Fjalëkalimet nuk përputhen',
    empty_company_name: 'Emri kompanisë nuk mund të jetë bosh',
    empty_nuis: 'Nipti nuk mund të jetë bosh',
    empty_administrator: 'Administratori nuk mund të jetë bosh',
    empty_full_address: 'Adresa nuk mund të jetë bosh',
    empty_country: 'Ju lutem zgjidhni një shtet',
    empty_city: 'Qyteti nuk mund të jetë bosh',
    empty_authorized_person: 'Personi i Autorizuar nuk mund të jetë bosh',
    empty_usage: 'Ju lutem zgjidhni një përdorim',
    empty_reason: 'Ju lutemi jepni arsyen e çaktivizimit',
    empty_citizenship: 'Nënshtetësia nuk mund të jetë bosh',
    empty_shareholders: 'Informacioni i aksionerëve nuk mund të jetë bosh',
    empty_commission: 'Komisioni nuk mund të jetë bosh',
    empty_title: 'Titulli nuk mund të jetë bosh',
    empty_description: 'Përshkrimi nuk mund të jetë bosh',
    empty_publish_date: 'Data e publikimit nuk mund të jetë bosh',
    empty_end_date: 'Data e përfundimit nuk mund të jetë bosh',
    empty_offer_validity_interval: 'Intervali i vlerësimit të ofertës nuk mund të jetë bosh',
    empty_terms_and_conditions: 'Termat dhe Kushtet nuk mund të jetë bosh',
    empty_amount: 'Sasia nuk mund të jetë bosh',
    empty_product: 'Produkti nuk mund të jetë bosh',
    empty_status: 'Statusi nuk mund të jetë bosh',
    empty_price: 'Çmimi nuk mund të jetë bosh',
    empty_unit: 'Njësia nuk mund të jetë bosh',
    empty_operatorPrice: 'Çmimi i operatorit nuk mund të jetë bosh',
    empty_totalPrice: 'Çmimi total nuk mund të jetë bosh'
  }
};

export default al;
