import React, { useEffect, useState } from 'react';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import CodeInput from '@core/inputs/CodeInput';
import { useParams } from 'react-router';
import TwoFactorAuthenticationService from '@services/TwoFactorAuthenticationService';
import logo from '@assets/images/logo.png';
import LanguageSelector from '@components/LanguageSelector/LanguageSelector';
import _ from 'lodash';
import { authenticate } from '@redux/authentication/Action';
import { showError } from '@utils/helpers';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import { useDispatch } from 'react-redux';
import Lottie from '@core/Lottie';

const TwoFactorAuthentication = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [qrCode, setQrCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isFetching, setIsFetching] = useState(true);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(showSpinner(t('pleaseWait')));
    TwoFactorAuthenticationService.authenticate(token, verificationCode)
      .then((response) => {
        const authentication = _.get(response, 'data.data.authentication', '');
        const user = _.get(response, 'data.data.user', '');
        dispatch(authenticate(authentication, user));
        navigate(`/`);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  useEffect(() => {
    if (!token) {
      navigate('/');
    } else {
      setIsFetching(true);
      TwoFactorAuthenticationService.validate(token)
        .then((res) => {
          const qrCode = _.get(res, 'data.data.qrCode');
          setQrCode(qrCode);
        })
        .catch((err) => {
          navigate('/login');
          showError(err.response.data.message);
        })
        .finally(async () => {
          setIsFetching(false);
        });
    }
  }, [navigate, token]);

  return (
    <div className="flex flex-col justify-center min-h-screen items-center ">
      <div className="w-full shadow-xl border rounded-2xl p-6">
        <div className="flex justify-end mb-2">
          <LanguageSelector />
        </div>
        <img src={logo} alt="Alb petrol logo" className="w-50 mx-auto pb-4" />
        <div className="space-y-1">
          <h2 className="text-lg font-semibold text-gray-800">{t('twoStepAuthentication')}</h2>
          <p className="text-sm text-gray-500">
            {t('enterTheSixDigitCodeThatYouReceivedOnYourApp')}
          </p>
        </div>
        {qrCode && (
          <div className="flex flex-col space-y-4 p-4 items-center">
            {isFetching ? (
              <div className="flex justify-center items-center p-10">
                <Lottie width={160} animationData={require('@assets/animations/loading.json')} />
              </div>
            ) : (
              <>
                <img src={qrCode} alt="qrCode" />
                <p className="text-sm text-gray-500">{t('scanTheQrToReceiveTheSixDigitCode')}</p>
              </>
            )}
          </div>
        )}
        <form className=" space-y-2" onSubmit={onSubmit}>
          <div className="flex py-6">
            <CodeInput value={verificationCode} onChange={setVerificationCode} fields={6} />
          </div>
          <DefaultButton
            md
            type="submit"
            bgColorHover="bg-primary"
            bgColor="hover:bg-orange-600 hover:ease-in hover:duration-200"
            label={t('submit')}
          />
        </form>
        <div className="pt-4 w-full text-gray-500">
          <Link to="/">{t('backToLoginPage')}</Link>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
