import React, { useState } from 'react';
import AddButton from '@core/buttons/atoms/AddButton';
import AddModal from '@core/modals/AddModal';
import Input from '@core/inputs/Input';
import UserService from '@services/UserService';
import { showError, showSuccess } from '@utils/helpers';
import RoleSelect from '@components/Role/partials/RoleSelect';
import { useTranslation } from 'react-i18next';
import useFormErrors from '@hooks/useFormErrors';
import userDataConstraints from '@utils/validators/userDataConstraints';
import PhoneNumberInput from '@core/inputs/PhoneNumberInput';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { admin } from '@constants/roles';

const CreateUser = ({ onAdded }) => {
  const { t } = useTranslation();
  const { clearError, getError, validateErrors, clearErrors } = useFormErrors();

  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [businessUnitCode, setBusinessUnitCode] = useState('');
  const [operatorCode, setOperatorCode] = useState('');
  const [tcrCode, setTcrCode] = useState('');
  const [tcrMaintainerCode, setTcrMaintainerCode] = useState('');
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setBusinessUnitCode('');
    setOperatorCode('');
    setTcrCode('');
    setTcrMaintainerCode('');
    setRoleId();
    clearErrors();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const errors = validateErrors(
      {
        firstName,
        lastName,
        email,
        phoneNumber,
        businessUnitCode,
        operatorCode,
        tcrCode,
        tcrMaintainerCode,
        role: roleId
      },
      userDataConstraints
    );
    if (errors) {
      setLoading(false);
      return;
    }

    UserService.create(
      firstName,
      lastName,
      email,
      phoneNumber,
      roleId,
      businessUnitCode,
      operatorCode,
      tcrCode,
      tcrMaintainerCode
    )
      .then(() => {
        showSuccess(t('userCreatedSuccessfully'));
        onAdded();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PermissionHandler roles={admin}>
      <AddButton label={`${t('addUser')}`} onClick={() => setShowModal(true)} />
      <AddModal
        show={showModal}
        title={`${t('addUser')}`}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}>
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <Input
            height="h-10"
            label={t('firstName')}
            placeholder={t('firstName')}
            value={firstName}
            onChange={(value) => clearError('firstName', value, setFirstName)}
            error={getError('firstName')}
          />
          <Input
            height="h-10"
            label={t('lastName')}
            placeholder={t('lastName')}
            value={lastName}
            onChange={(value) => clearError('lastName', value, setLastName)}
            error={getError('lastName')}
          />
          <div>
            <div className="text-sm font-semibold mb-2">{t('assignARole')}</div>
            <RoleSelect
              showErrorText
              openModal={showModal}
              selected={roleId}
              onSelect={(option) => clearError('role', option, setRoleId)}
              error={getError('role')}
            />
          </div>
          <Input
            height="h-10"
            label={t('email')}
            placeholder={t('email')}
            value={email}
            onChange={(value) => clearError('email', value, setEmail)}
            error={getError('email')}
          />
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            onChange={(value) => clearError('phoneNumber', value, setPhoneNumber)}
            placeholder={t('phone')}
            label={t('phone')}
            error={getError('phoneNumber')}
            dependency={showModal}
          />
          <Input
            height="h-10"
            label={t('businessUnitCode')}
            placeholder={t('businessUnitCode')}
            value={businessUnitCode}
            onChange={(value) => clearError('businessUnitCode', value, setBusinessUnitCode)}
            error={getError('businessUnitCode')}
          />
          <Input
            height="h-10"
            label={t('operatorCode')}
            placeholder={t('operatorCode')}
            value={operatorCode}
            onChange={(value) => clearError('operatorCode', value, setOperatorCode)}
            error={getError('operatorCode')}
          />
          <Input
            height="h-10"
            label={t('tcrCode')}
            placeholder={t('tcrCode')}
            value={tcrCode}
            onChange={(value) => clearError('tcrCode', value, setTcrCode)}
            error={getError('tcrCode')}
          />
          <Input
            height="h-10"
            label={t('tcrMaintainerCode')}
            placeholder={t('tcrMaintainerCode')}
            value={tcrMaintainerCode}
            onChange={(value) => clearError('tcrMaintainerCode', value, setTcrMaintainerCode)}
            error={getError('tcrMaintainerCode')}
          />
        </div>
      </AddModal>
    </PermissionHandler>
  );
};

export default CreateUser;
