import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import CountryService from '@services/CountryService';

const CountrySelect = ({
  openModal,
  onSelect,
  selected,
  placeholder,
  disabled = false,
  error,
  showErrorText
}) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [selectedObject, setSelectedObject] = useState();
  const [options, setOptions] = useState([]);

  const handleChangeSelected = (item) => {
    setSelectedObject(item);
    return onSelect && onSelect(item.name);
  };

  useEffect(() => {
    if (openModal) {
      CountryService.all().then((response) => {
        setOptions(response.data.data);
      });
    }
  }, [openModal]);

  useEffect(() => {
    if (options) {
      const foundItem = Object.values(options)?.find((item) => item.name === selected);
      setSelectedObject(foundItem || null);
    }
  }, [selected, options]);

  return (
    <>
      <Select
        isDisabled={disabled}
        getOptionValue={(option) => option.id}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        isSearchable
        options={options}
        onChange={handleChangeSelected}
        value={selectedObject}
        placeholder={placeholder}
        getOptionLabel={(selectedObject) => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }} className="text-sm">
            <span style={{ marginLeft: 5 }}>
              {language === 'al' ? selectedObject.name : selectedObject.name}
            </span>
          </div>
        )}
      />
      {error && showErrorText && <label className="text-xs text-red-500">{error}</label>}
    </>
  );
};

export default CountrySelect;
