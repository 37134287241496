import React, { useEffect, useMemo, useState } from 'react';
import { randomCode, showError, showSuccess } from '@utils/helpers';
import { useTranslation } from 'react-i18next';
import CodeInput from '@core/inputs/CodeInput';
import TenderService from '@services/TenderService';
import BlankModal from '@core/modals/BlankModal';
import { FaUpload } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import RowDetails from '@hoc/cruds/RowDetails';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { admin } from '@constants/roles';

const PublishTender = ({ id, onPublished }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState({});
  const [lots, setLots] = useState([]);
  const [codeInput, setCodeInput] = useState('');

  const code = useMemo(() => {
    if (showModal) {
      return randomCode();
    }
  }, [showModal]);

  const closeModal = () => {
    setShowModal(false);
    setCodeInput(null);
  };

  useEffect(() => {
    if (showModal) {
      TenderService.find(id).then((res) => {
        const data = res.data.data.procurement;
        setDetails(data);
        setLots(data.lots);
      });
    }
  }, [id, showModal]);

  const onSubmit = () => {
    if (code === codeInput) {
      TenderService.publish(id)
        .then(() => {
          showSuccess(t('tenderPublishedSuccessfully'));
          onPublished();
          closeModal();
        })
        .catch((err) => {
          showError(err.response.data.message);
        });
    } else showError(t('codeDoesNotMatch'));
  };

  return (
    <PermissionHandler roles={admin}>
      <DefaultButton
        title={t('publish')}
        sm
        label={
          <div className="flex items-center">
            <FaUpload />
          </div>
        }
        bgColor="bg-orange-100"
        bgColorHover="hover:bg-orange-300"
        textColor="text-orange-700"
        onClick={() => setShowModal(true)}
      />
      <BlankModal
        width="w-3/5"
        icon={<FaUpload className="text-orange-800" />}
        iconBg="bg-orange-100"
        maxWidth="3xl"
        otherButtons={[
          <DefaultButton
            sm
            label={
              <div className="flex items-center">
                <FaUpload className="mr-1" />
                {t('publishTender')}
              </div>
            }
            bgColor="bg-orange-100"
            bgColorHover="hover:bg-orange-300"
            textColor="text-orange-700"
            onClick={onSubmit}
          />
        ]}
        show={showModal}
        title={`${t('publishTender')}`}
        onClose={closeModal}
        onSubmit={onSubmit}>
        <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
          <RowDetails label={t('publishDate')} value={details.publishDate} />
          <RowDetails label={t('endDate')} value={details.endDate} />
          {lots.map((lot) => (
            <>
              <RowDetails label={t('product')} value={lot.product} />
              <RowDetails label={t('amount')} value={`${lot.amount} ${t(lot?.originalUnit)}`} />
            </>
          ))}
        </div>
        <div className="flex p-5">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: details.termsAndConditions }} />
        </div>
        <div className="flex flex-col justify-center mx-auto pt-3">
          <h1 className="flex py-2 font-semibold text-xl text-center justify-center mx-auto">
            {t('matchToPublishTender')}
          </h1>
          <div className="py-2 text-center">{code}</div>
          <div className="flex py-4 justify-center mx-auto">
            <CodeInput value={codeInput} onChange={setCodeInput} fields={6} />
          </div>
        </div>
      </BlankModal>
    </PermissionHandler>
  );
};

export default PublishTender;
