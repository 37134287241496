import React, { useCallback, useEffect, useState } from 'react';
import TabSwitch from '@core/tabs/TabSwitch';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import TenderService from '@services/TenderService';
import { showError, showSuccess } from '@utils/helpers';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import { useNavigate, useParams } from 'react-router';
import TenderDetails from '@components/Tender/tabs/TenderDetails';
import TenderCommission from '@components/Tender/tabs/TenderCommission';
import TenderLots from '@components/Tender/tabs/TenderLots';
import useCanAccessRoute from '@hooks/useCanAccessRoute';
import useFormErrors from '@hooks/useFormErrors';
import tenderDataConstraints from '@utils/validators/tenderDataConstraints';

const EditTenderPage = ({ roles = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tenderId } = useParams();

  const { hasAccess, redirect } = useCanAccessRoute({ roles });

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState('details');
  const [details, setDetails] = useState({});
  const [lots, setLots] = useState([]);
  const [commission, setCommission] = useState([]);
  const [commissionDirector, setCommissionDirector] = useState([]);

  const { getError, clearError, validateErrors } = useFormErrors();

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirect);
    }
  }, [hasAccess, navigate, redirect]);

  useEffect(() => {
    if (!tenderId) {
      navigate('/');
    }
  }, [navigate, tenderId]);

  const getData = useCallback(() => {
    setLoading(true);
    TenderService.find(tenderId)
      .then((res) => {
        const data = res.data.data.procurement;
        setDetails(data);
        const existingLots = data?.lots?.map((lot, index) => {
          return {
            index: index + 1,
            ...lot
          };
        });
        setLots(existingLots);
        setCommission(data.commission);
        setCommissionDirector([data.commissionDirector]);
      })
      .finally(async () => {
        setLoading(false);
      });
  }, [tenderId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = () => {
    const errors = validateErrors(
      {
        title: details?.name,
        description: details?.description,
        publishDate: details?.publishDate,
        endDate: details?.endDate,
        offerValidityInterval: details?.offerValidityInterval,
        termsAndConditions: details?.termsAndConditions
      },
      tenderDataConstraints
    );

    if (commissionDirector.length === 0 || commission.length === 0) {
      showError(t('errors.empty_commission'));
    }

    if (errors || commissionDirector.length === 0 || commission.length === 0) {
      setLoading(false);
      return;
    }
    const newLots = lots.map(({ index, ...rest }) => {
      return rest;
    });
    const newCommission = commission.map(({ id }) => ({ id }));
    const newDirector = commissionDirector.map(({ id, isDirector }) => ({ id, isDirector }));
    const commissionId = newCommission.concat(newDirector);
    const payload = {
      ...details,
      lots: newLots,
      commissionId
    };
    setLoading(true);
    TenderService.update(tenderId, payload)
      .then(() => {
        showSuccess(t('tenderEditedSuccessfully'));
        navigate(`/tenders`);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <div className="flex-1">
        <div className="bg-white rounded-b-lg justify-between items-center pt-2 pb-10 px-22 shadow m-7 border border-gray-300 shadow-lg">
          <div className="p-3 flex justify-center mx-auto">
            <TabSwitch
              containerClassName="mt-10"
              items={['details', 'lots', 'commission']}
              activeTab={active}
              onChange={setActive}
            />
          </div>
          {active === 'details' && (
            <TenderDetails
              clearError={clearError}
              errors={getError}
              data={details}
              onChange={setDetails}
            />
          )}
          {active === 'lots' && (
            <TenderLots
              data={lots}
              onChange={(val) => {
                setLots((prev) => {
                  const exists = prev.find((rec) => rec.index === val.index);
                  if (exists) {
                    return prev.map((rec) => {
                      if (rec.index === val.index) {
                        return val;
                      }
                      return rec;
                    });
                  }
                  return [...prev, val];
                });
              }}
              onDelete={setLots}
            />
          )}
          {active === 'commission' && (
            <TenderCommission
              commissionDirector={commissionDirector}
              onChangeDirector={setCommissionDirector}
              data={commission}
              onChange={(val) => {
                setCommission((prev) => [...prev, val]);
              }}
              onDelete={setCommission}
            />
          )}
        </div>
      </div>
      <div className="sticky w-full shadow border-t bottom-0 bg-white flex items-center justify-end py-3 px-3">
        <Link to="/tenders">
          <div className="flex cursor-pointer mr-5">
            <FaArrowLeft className="mt-1.5 mr-2" />
            {t('goBack')}
          </div>
        </Link>
        <div className="flex w-1/6">
          <SubmitButton label={t('edit')} onClick={onSubmit} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default EditTenderPage;
