import React from 'react';
import { Plus } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import Shareholder from '@components/Client/partials/Shareholder';

const Shareholders = ({ disabled = false, data, onChange, hasError, clearErrors }) => {
  const { t } = useTranslation();

  const addShareholder = () => {
    const shareholder = {
      firstName: '',
      lastName: '',
      citizenship: ''
    };
    onChange([...data, shareholder]);
    clearErrors(false);
  };

  const handleDelete = (index) => {
    const newShareholder = data.filter((shareholder) => shareholder !== data[index]);
    onChange(newShareholder);
    clearErrors(false);
  };

  const updateShareholder = (index, operation) => {
    const newShareholder = [...data];
    newShareholder[index] = operation;
    onChange(newShareholder);
  };

  return (
    <div>
      <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-xl">{t('shareholders')}</h1>
      <div className="flex flex-col gap-4">
        {data.map((shareholder) => (
          <Shareholder
            hasError={hasError}
            disabled={disabled}
            data={shareholder}
            key={data.indexOf(shareholder)}
            onDelete={handleDelete}
            onChange={updateShareholder}
            index={data.indexOf(shareholder)}
          />
        ))}
      </div>
      {!disabled && (
        <button onClick={addShareholder}>
          <div className="flex text-primary mt-2">
            <Plus />
            <div className="ml-2 font-semibold">{t('addShareholder')}</div>
          </div>
        </button>
      )}
    </div>
  );
};

export default Shareholders;
