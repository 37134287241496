import { notify } from 'react-notify-toast';

export const showSuccess = (message = 'Success', timeout = 4000) => {
  notify.show(message, 'custom', timeout, {
    background: '#10b981',
    text: 'white'
  });
};
export const showCopiedFlash = (message = 'Success', timeout = 800) => {
  notify.show(message, 'custom', timeout, {
    background: '#EC7F00',
    text: 'white'
  });
};

export const showError = (error, timeout = 4000) => {
  notify.show(error, 'custom', timeout, {
    background: '#ef4444',
    text: 'white'
  });
};

export const showInfo = (message, timeout = 2000) => {
  notify.show(message, 'custom', timeout, {
    background: '#3b82f6',
    text: 'white'
  });
};

export const numbersOnly = (value) => {
  return value.replace(/[^0-9.]/g, '');
};

export const wait = (ms = 1000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const fileToBase64 = (file) => {
  if (!file) {
    return '';
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const randomCode = () => {
  let code = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    code += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return code;
};
