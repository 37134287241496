import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useCanAccessRoute from '@hooks/useCanAccessRoute';
import PublishedTendersIndex from '@components/PublishedTender/PublishedTendersIndex';

const PublishedTendersPage = ({ roles = [] }) => {
  const navigate = useNavigate();

  const { hasAccess, redirect } = useCanAccessRoute({ roles });

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirect);
    }
  }, [hasAccess, navigate, redirect]);

  return <PublishedTendersIndex />;
};

export default PublishedTendersPage;
