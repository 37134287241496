import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import DeliveryService from '@services/DeliveryService';
import { FaFileContract } from 'react-icons/fa';
import { showError, showSuccess } from '@utils/helpers';
import _ from 'lodash';
import BlankModal from '@core/modals/BlankModal';
import SubmitButton from '@core/buttons/atoms/SubmitButton';

const DeliverInvoiceGeneration = ({ delivery, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    DeliveryService.generateInvoice(delivery.id)
      .then((response) => {
        showSuccess(t('invoiceGeneratedSuccessfully'));
        closeModal();
        onSuccess();
        const invoice = _.get(response, 'data.data.uploadedInvoice');
        window.open(invoice);
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <DefaultButton
        title={t('generateInvoice')}
        sm
        label={<FaFileContract />}
        onClick={() => setShowModal(true)}
      />
      <BlankModal
        otherButtons={[<SubmitButton loading={loading} onClick={onSubmit} label={t('generate')} />]}
        show={showModal}
        title={`${t('generateDeliveryInvoice')}`}
        loading={loading}
        icon={<FaFileContract className="text-orange-800" />}
        onClose={closeModal}>
        <div className="text-lg mb-5">
          <span>{`${t('areYouSureYouWantToGenerateInvoice')}`}</span>
        </div>
      </BlankModal>
    </>
  );
};

export default DeliverInvoiceGeneration;
