const lotDataConstraints = {
  name: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_name'
    }
  },
  publishDate: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_publish_date'
    }
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_end_date'
    }
  },
  amount: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_amount'
    }
  },
  product: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_product'
    }
  },
  unit: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_unit'
    }
  },
  pickUp: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_pick_up'
    }
  },
  description: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_description'
    }
  }
};
export default lotDataConstraints;
