import React from 'react';
import Input from '@core/inputs/Input';
import { Trash } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';

const Shareholder = ({ data, index, onChange, disabled, onDelete, hasError }) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    onChange(index, { ...data, [name]: value });
  };

  return (
    <div key={index}>
      <div className="grid grid-cols-4 gap-5">
        <Input
          disabled={disabled}
          height="h-10"
          label={t('firstName')}
          placeholder={t('firstName')}
          value={data?.firstName}
          onChange={(val) => handleChange(val, 'firstName')}
          extraClasses="text-xs"
          error={hasError && data?.firstName === ''}
        />
        <Input
          disabled={disabled}
          height="h-10"
          label={t('lastName')}
          placeholder={t('lastName')}
          value={data?.lastName}
          onChange={(val) => handleChange(val, 'lastName')}
          extraClasses="text-xs"
          error={hasError && data?.lastName === ''}
        />
        <Input
          disabled={disabled}
          height="h-10"
          label={t('citizenship')}
          placeholder={t('citizenship')}
          value={data?.citizenship}
          onChange={(val) => handleChange(val, 'citizenship')}
          extraClasses="text-xs"
          error={hasError && data?.citizenship === ''}
        />
        {!disabled && (
          <div className="flex self-center pt-5">
            <button
              className="flex text-red-600 cursor-pointer ml-auto"
              onClick={() => onDelete(index)}>
              <Trash />
            </button>
          </div>
        )}
      </div>
      <hr className="px-5 my-3" />
    </div>
  );
};

export default Shareholder;
