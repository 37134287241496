import API from '@utils/plugins/API';

const DocumentService = {
  all: (params) => {
    return API.get('document-types', { params });
  },
  create: (name, usage, required) => {
    return API.post('document-types', { name, usage, required });
  },
  update: (id, name, usage, required) => {
    return API.put(`document-types/${id}`, { name, usage, required });
  },
  find: (id) => {
    return API.get(`document-types/${id}`);
  },
  destroy: (id) => {
    return API.delete(`document-types/${id}`);
  }
};

export default DocumentService;
