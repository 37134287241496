import React from 'react';
import RowDetails from '@hoc/cruds/RowDetails';

const StatisticsCard = ({ title, data }) => (
  <div className="flex flex-col w-1/3">
    <div className="bg-white py-8 rounded-lg shadow-lg">
      <h1 className="mb-4 pl-10 font-semibold text-xl">{title}</h1>
      {data.map((data) => (
        <RowDetails label={data?.label} value={data?.value} color={data?.color} />
      ))}
    </div>
  </div>
);

export default StatisticsCard;
