import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import Lottie from 'react-lottie';

const FileInput = ({
  disabled = false,
  classname,
  handleChange,
  animation,
  types,
  loop,
  autoplay,
  extraClasses,
  fileName,
  onChangeFile
}) => {
  const fileTypesDisplay = () => {
    let displayString = '';
    types.forEach((type, index) => {
      if (index === types.length - 1) {
        displayString = `${displayString}.${type}`;
        onChangeFile('');
      }
      displayString = `${displayString}.${type}/`;
    });
    onChangeFile(displayString);
  };

  const onChange = (file) => {
    onChangeFile(file.name);
    handleChange(file);
  };

  return (
    <div className={`flex-col ${extraClasses}`}>
      <FileUploader disabled={disabled} name="file" types={types} handleChange={onChange}>
        <div className={classname}>
          <label className="w-1/3">
            <Lottie
              height={200}
              width={200}
              options={{
                loop,
                autoplay,
                animationData: animation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
            />
          </label>
        </div>
      </FileUploader>
      <div className="flex inline mr-10">
        <p className="mt-2 text-md text-gray-400">
          {fileName || `Upload ${fileTypesDisplay()} file.`}
        </p>
      </div>
    </div>
  );
};

export default FileInput;
