import React from 'react';
import { FaPlus } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { useTranslation } from 'react-i18next';

const AddButton = ({ onClick, label, title }) => {
  const { t } = useTranslation();

  return (
    <DefaultButton
      title={title || t('add')}
      xs
      bgColorHover="bg-primary"
      bgColor="hover:bg-orange-600 hover:ease-in hover:duration-200"
      label={
        <div className="flex items-center p-1">
          <FaPlus className="mr-1" />
          {label}
        </div>
      }
      onClick={onClick}
    />
  );
};

export default AddButton;
