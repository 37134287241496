import React, { useState } from 'react';
import ViewModal from '@core/modals/ViewModal';
import ViewButton from '@core/buttons/atoms/ViewButton';
import { useTranslation } from 'react-i18next';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { allRoles } from '@constants/roles';
import RowDetails from '@hoc/cruds/RowDetails';

const ShowInvoice = ({ invoice, currentRole }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <PermissionHandler roles={allRoles}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal show={showModal} title={`${t('invoice')}`} onClose={closeModal}>
        <div className="flex flex-col m-5">
          {currentRole !== 'klient' && (
            <>
              <h1 className="flex border-b-2 pb-2 font-semibold text-xl">{t('client')}</h1>
              <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
                <RowDetails label={t('companyName')} value={invoice?.client?.companyName} />
                <RowDetails label={t('nuis')} value={invoice?.client?.nuis} />
                <RowDetails label={t('email')} value={invoice?.client?.email} />
                <RowDetails label={t('phone')} value={invoice?.client?.authorizedPersonPhone} />
              </div>
            </>
          )}
          <h1 className="flex border-b-2 pt-4 pb-2 font-semibold text-xl">
            {t('deliveryDetails')}
          </h1>
          <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
            <RowDetails label={t('deliveryName')} value={invoice?.delivery?.name} />
            <RowDetails label={t('product')} value={invoice?.delivery?.product} />
            <RowDetails
              label={t('amount')}
              value={`${invoice?.delivery?.amount} ${t(invoice?.delivery?.originalUnit)}`}
            />
            <RowDetails label={t('price')} value={`${invoice?.delivery?.price} $`} />
          </div>
          <h1 className="flex border-b-2 pt-4 pb-2 font-semibold text-xl">{t('paymentDetails')}</h1>
          <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
            <RowDetails label={t('value')} value={`${invoice?.value} $`} />
            <RowDetails label={t('paid')} value={`${invoice?.paid} $`} />
            <RowDetails label={t('valueWithoutVat')} value={`${invoice?.valueWithoutVat} $`} />
            <RowDetails label={t('vat')} value={`${invoice?.vatValue} $`} />
          </div>
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowInvoice;
