import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'tabler-icons-react';
import BlankModal from '@core/modals/BlankModal';
import { FaPlus } from 'react-icons/fa';
import Input from '@core/inputs/Input';
import TextArea from '@core/textareas/TextArea';
import CDefaultDatePicker from '@core/inputs/dateTime/CDefaultDatePicker';
import NumericalInput from '@core/inputs/NumericalInput';
import EditButton from '@core/buttons/atoms/EditButton';
import Select from 'react-select';
import SubmitButton from '@core/buttons/atoms/SubmitButton';
import moment from 'moment';
import useFormErrors from '@hooks/useFormErrors';
import lotDataConstraints from '@utils/validators/lotDataConstraints';
import { barrelsPerNM3, barrelsPerTon } from '@constants/conversions';

const Lot = ({ lot, onEdit = false, onChange, index }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [newLot, setNewLot] = useState(lot || {});
  const [selectedObject, setSelectedObject] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [convertedAmount, setConvertedAmount] = useState('');
  const [localUnit, setLocalUnit] = useState('');

  const { getError, clearError, validateErrors, clearErrors } = useFormErrors();

  useEffect(() => {
    if (newLot?.amount) {
      if (selectedUnit?.value === 'bbl' && selectedObject?.value === 'OIL') {
        // eslint-disable-next-line no-unsafe-optional-chaining
        setConvertedAmount((newLot?.amount / barrelsPerTon).toFixed(2));
        setLocalUnit('t');
      }
      if (selectedUnit?.value === 'bbl' && selectedObject?.value === 'GAS') {
        // eslint-disable-next-line no-unsafe-optional-chaining
        setConvertedAmount((newLot?.amount / barrelsPerNM3).toFixed(2));
        setLocalUnit('nm3');
      }
      if (selectedUnit?.value === 't') {
        // eslint-disable-next-line no-unsafe-optional-chaining
        setConvertedAmount((newLot?.amount * barrelsPerTon).toFixed(2));
        setLocalUnit('bbl');
      }
      if (selectedUnit?.value === 'nm3') {
        // eslint-disable-next-line no-unsafe-optional-chaining
        setConvertedAmount((newLot?.amount * barrelsPerNM3).toFixed(2));
        setLocalUnit('bbl');
      }
    } else setConvertedAmount(null);
  }, [newLot?.amount, selectedObject?.value, selectedUnit?.value]);

  const products = useMemo(
    () => [
      { label: t('oil'), value: 'OIL' },
      { label: t('naturalGas'), value: 'GAS' }
    ],
    [t]
  );

  const oilUnits = useMemo(
    () => [
      { label: t('ton'), value: 't' },
      { label: t('barrels'), value: 'bbl' }
    ],
    [t]
  );

  const naturalGasUnits = useMemo(
    () => [
      { label: 'nm3', value: 'nm3' },
      { label: t('barrels'), value: 'bbl' }
    ],
    [t]
  );

  const handleChange = (value, name) => {
    setNewLot({ ...newLot, [name]: value });
  };

  const handleChangeSelected = (item, triggeredAction) => {
    if (triggeredAction.action === 'clear') {
      setSelectedObject();
    }
    setSelectedObject(item);
    handleChange(item.value, 'product');
  };

  const handleChangeSelectedUnit = (item, triggeredAction) => {
    if (triggeredAction.action === 'clear') {
      setSelectedUnit();
    }
    setSelectedUnit(item);
    handleChange(item.value, 'originalUnit');
  };

  useEffect(() => {
    const foundItem = products?.find((item) => item.value === newLot?.product);
    const foundUnit = naturalGasUnits
      ?.concat(oilUnits)
      .find((unit) => unit.value === newLot?.originalUnit);
    setSelectedObject(foundItem || null);
    setSelectedUnit(foundUnit || null);
  }, [naturalGasUnits, newLot?.originalUnit, newLot?.product, oilUnits, products]);

  const closeModal = () => {
    setShowModal(false);
    if (!onEdit) {
      setNewLot({});
    }
    clearErrors();
  };

  const onSave = () => {
    const errors = validateErrors(
      {
        name: newLot?.name,
        publishDate: newLot?.startDate,
        endDate: newLot?.endDate,
        amount: newLot?.endDate,
        product: newLot?.product,
        unit: newLot?.originalUnit,
        pickUp: newLot?.pickUp,
        description: newLot?.description
      },
      lotDataConstraints
    );

    if (errors) {
      return;
    }
    setShowModal(false);
    if (!onEdit) setNewLot({});
    onChange({ ...newLot, index: lot?.index || index });
  };

  return (
    <div>
      {onEdit ? (
        <EditButton onClick={() => setShowModal(true)} />
      ) : (
        <button onClick={() => setShowModal(true)}>
          <div className="flex text-primary items-center">
            <Plus />
            <div className="ml-2 font-semibold">{t('addLot')}</div>
          </div>
        </button>
      )}
      <BlankModal
        otherButtons={[<SubmitButton onClick={onSave} label={t('save')} />]}
        icon={<FaPlus className="text-primary" />}
        show={showModal}
        title={t('addLot')}
        onClose={closeModal}>
        <div className="px-20">
          <h1 className="flex border-b-2 pt-4 pb-2 mb-2 font-semibold text-xl">{t('lot')}</h1>
          <div className="mt-3 grid grid-cols-2 gap-x-5">
            <div>
              <Input
                height="h-10"
                label={t('name')}
                placeholder={t('name')}
                value={newLot?.name || ''}
                onChange={(value) => {
                  clearError('name');
                  handleChange(value, 'name');
                }}
                error={getError('name')}
              />
            </div>
            <Input
              height="h-10"
              label={t('pickUp')}
              placeholder={t('pickUp')}
              value={newLot?.pickUp || ''}
              onChange={(value) => {
                clearError('pickUp');
                handleChange(value, 'pickUp');
              }}
              error={getError('pickUp')}
            />
          </div>
          <div className="mt-3 grid grid-cols-2 gap-x-5">
            <div>
              <div className="text-sm font-semibold mb-2">{t('startDate')}</div>
              <CDefaultDatePicker
                minDate={moment().format('YYYY-MM-DD')}
                value={newLot?.startDate || ''}
                onChange={(value) => {
                  clearError('publishDate');
                  handleChange(value, 'startDate');
                }}
                error={getError('publishDate')}
              />
            </div>
            <div>
              <div className="text-sm font-semibold mb-2">{t('endDate')}</div>
              <CDefaultDatePicker
                minDate={newLot?.startDate}
                value={newLot?.endDate || ''}
                onChange={(value) => {
                  clearError('endDate');
                  handleChange(value, 'endDate');
                }}
                error={getError('endDate')}
              />
            </div>
          </div>
          <div className="mt-2 grid grid-cols-3 gap-x-5">
            <div>
              <div className="text-sm font-semibold mb-2">{t('product')}</div>
              <Select
                placeholder={t('chooseProduct')}
                value={selectedObject}
                isSearchable={false}
                onChange={handleChangeSelected}
                getOptionValue={(option) => option.value}
                options={products}
                getOptionLabel={(product) => (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ marginLeft: 5 }}>{product.label}</span>
                  </div>
                )}
              />
              {getError('product') && (
                <div className="text-xs text-red-400 pt-2">{getError('product')}</div>
              )}
            </div>
            <NumericalInput
              height="h-10"
              label={t('amount')}
              placeholder={t('amount')}
              value={newLot?.amount || ''}
              onChange={(value) => {
                clearError('amount');
                handleChange(parseFloat(value), 'amount');
              }}
              error={getError('amount')}
            />
            <div>
              <div className="text-sm font-semibold mb-2">{t('unit')}</div>
              <Select
                placeholder={t('chooseUnit')}
                value={selectedUnit}
                isSearchable={false}
                onChange={handleChangeSelectedUnit}
                getOptionValue={(option) => option.value}
                options={selectedObject?.value === 'OIL' ? oilUnits : naturalGasUnits}
                getOptionLabel={(unit) => (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ marginLeft: 5 }}>{unit.label}</span>
                  </div>
                )}
              />
              {getError('unit') && (
                <div className="text-xs text-red-400 pt-2">{getError('unit')}</div>
              )}
              {convertedAmount && (
                <div className="text-xs pt-2 text-semibold ml-2">{`${t(
                  'itIsEqualTo'
                )} ${convertedAmount} ${t(localUnit)}`}</div>
              )}
            </div>
          </div>
          <div className="mt-2">
            <TextArea
              height="h-10"
              label={t('description')}
              placeholder={t('description')}
              value={newLot?.description || ''}
              onChange={(value) => {
                clearError('description');
                handleChange(value, 'description');
              }}
              error={getError('description')}
            />
          </div>
        </div>
      </BlankModal>
    </div>
  );
};

export default Lot;
