import React, { useState } from 'react';
import { CountriesArray } from '@utils/countries';
import DownChevron from '@assets/svg/down_chevron_gray.svg';

const CountrySelect = ({ updatePrefix, prefix = '+355' }) => {
  const [showOptions, setShowOptions] = useState(false);

  const prefixes = () => {
    return CountriesArray.map((country) => {
      return (
        <div
          title={country.country}
          className="hover:bg-orange-100 hover:text-black px-3 flex flex-row items-center cursor-pointer py-1"
          key={country.country}
          onClick={() => {
            updatePrefix(country.code);
          }}>
          {country.code}
        </div>
      );
    });
  };

  const onClickOutsideListener = () => {
    setShowOptions(false);
    document.removeEventListener('click', onClickOutsideListener);
  };

  const addOnClickOutsideListener = () => {
    document.addEventListener('click', onClickOutsideListener);
  };

  return (
    <div onBlur={() => addOnClickOutsideListener()}>
      <button
        className="flex justify-between appearance-none h-10 relative block w-full px-3 py-2 border border-black-200 shadow-sm placeholder-gray-500 rounded text-gray-900 focus:outline-none focus:ring-primary-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm items-center self-center"
        onClick={() => setShowOptions(!showOptions)}>
        {prefix}
        <img src={DownChevron} className="xs:ml-1 sm:ml-2" alt="" />
      </button>
      <div
        className={`${
          !showOptions ? 'hidden' : ''
        } border-1 absolute z-40 bg-white max-h-50 w-24 overflow-y-scroll mt-0.5`}>
        {prefixes()}
      </div>
    </div>
  );
};

export default CountrySelect;
