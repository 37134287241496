import React, { useMemo, useState } from 'react';
import AddButton from '@core/buttons/atoms/AddButton';
import AddModal from '@core/modals/AddModal';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '@utils/helpers';
import DocumentService from '@services/DocumentService';
import Select from 'react-select';
import useFormErrors from '@hooks/useFormErrors';
import documentDataConstraints from '@utils/validators/documentDataConstraints';
import Input from '@core/inputs/Input';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { admin } from '@constants/roles';

const CreateDocument = ({ onAdded }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [usage, setUsage] = useState('');
  const [required, setRequired] = useState(false);
  const [selectedObject, setSelectedObject] = useState();

  const { clearError, getError, validateErrors, clearErrors } = useFormErrors();

  const usages = useMemo(
    () => [
      { label: t('clientDocument'), value: 'client' },
      { label: t('paymentDocument'), value: 'payment' }
    ],
    [t]
  );

  const closeModal = () => {
    setShowModal(false);
    setName('');
    setUsage(null);
    setSelectedObject(null);
    setRequired(false);
    clearErrors();
  };

  const handleChangeSelected = (item, triggeredAction) => {
    if (triggeredAction.action === 'clear') {
      setSelectedObject(null);
    }
    setSelectedObject(item);
    setUsage(item.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const errors = validateErrors(
      {
        name,
        usage
      },
      documentDataConstraints
    );
    if (errors) {
      setLoading(false);
      return;
    }
    DocumentService.create(name, usage, required)
      .then(() => {
        showSuccess(t('documentCreatedSuccessfully'));
        onAdded();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PermissionHandler roles={admin}>
      <AddButton label={`${t('addDocument')}`} onClick={() => setShowModal(true)} />
      <AddModal
        width="w-1/2"
        show={showModal}
        title={`${t('addDocument')}`}
        onClose={closeModal}
        loading={loading}
        onSubmit={onSubmit}>
        <div className="mt-4 sm:mt-8 mb-2 flex flex-col px-5">
          <Input
            height="h-10"
            label={t('name')}
            placeholder={t('name')}
            value={name}
            onChange={(value) => clearError('name', value, setName)}
            error={getError('name')}
          />
          <div>
            <div className="text-sm font-semibold mb-2 mt-2">{t('documentType')}</div>
            <Select
              placeholder={t('chooseDocumentType')}
              value={selectedObject}
              isSearchable={false}
              onChange={handleChangeSelected}
              getOptionValue={(option) => option.value}
              options={usages}
              getOptionLabel={(usage) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ marginLeft: 5 }}>{usage.label}</span>
                </div>
              )}
            />
            {getError('usage') && (
              <label className="text-xs text-red-500">{getError('usage')}</label>
            )}
          </div>
          <div className="flex items-center my-5">
            <input
              type="checkbox"
              checked={required}
              onClick={() => {
                setRequired(!required);
              }}
              className="w-4 h-4 text-primary-700 bg-gray-100 rounded border-gray-300 focus:ring-primary focus:ring-2 accent-[#e47424]"
            />
            <label className="ml-2 text-base text-gray-700">{t('required')}</label>
          </div>
        </div>
      </AddModal>
    </PermissionHandler>
  );
};

export default CreateDocument;
