import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useCanAccessRoute from '@hooks/useCanAccessRoute';
import TendersIndex from '@components/Tender/TendersIndex';

const TendersPage = ({ roles = [] }) => {
  const navigate = useNavigate();

  const { hasAccess, redirect } = useCanAccessRoute({ roles });

  useEffect(() => {
    if (!hasAccess) {
      navigate(redirect);
    }
  }, [hasAccess, navigate, redirect]);

  return <TendersIndex />;
};

export default TendersPage;
