import API from '@utils/plugins/API';

const ClientService = {
  all: (params) => {
    return API.get('clients', { params });
  },
  create: (payload) => {
    return API.post('clients', {
      ...payload
    });
  },
  statistics: () => {
    return API.get(`clients/statistics`);
  },
  update: (id, payload) => {
    return API.put(`clients/${id}`, {
      ...payload
    });
  },
  find: (id) => {
    return API.get(`clients/${id}`);
  },
  destroy: (id) => {
    return API.delete(`clients/${id}`);
  },
  getDocument: (id, documentId) => {
    return API.get(`clients/${id}/documents/${documentId}`);
  },
  deactivate: (id, reason) => {
    return API.post(`clients/${id}/deactivate`, { reason });
  },
  activate: (id) => {
    return API.post(`clients/${id}/activate`);
  }
};

export default ClientService;
