import DefaultBadge from '@core/badges/DefaultBadge';
import { useTranslation } from 'react-i18next';
import GreenBadge from '@core/badges/GreenBadge';
import React from 'react';
import RedBadge from '@core/badges/RedBadge';

const UserStatus = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case 'activated':
      return <GreenBadge text={t('activated')} />;
    case 'deactivated':
      return <RedBadge text={t('deactivated')} />;
    default:
      return (
        <DefaultBadge
          bgColor="bg-yellow-100"
          text={t('notActivated')}
          textColor="text-yellow-700"
        />
      );
  }
};

export default UserStatus;
