import React, { useEffect, useMemo, useState } from 'react';
import Datatable from '@core/table/Datatable';
import { useTranslation } from 'react-i18next';
import GreenBadge from '@core/badges/GreenBadge';
import RedBadge from '@core/badges/RedBadge';
import _ from 'lodash';
import InvoiceService from '@services/InvoiceService';
import DefaultBadge from '@core/badges/DefaultBadge';
import moment from 'moment';
import TableStatistic from '@hoc/cruds/TableStatistic';
import { useSelector } from 'react-redux';
import InvoiceActions from '@components/Invoice/partials/InvoiceActions';

const InvoicesIndex = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState([]);
  const [updatedTable] = useState(0);
  const [data, setData] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [showSkeleton, setShowskeleton] = useState(true);

  const currentRole = useSelector((state) => _.get(state, 'meReducer.role', null));

  const renderBadge = ({ status }) => {
    if (status === 'paid') {
      return <GreenBadge text="Paid" />;
    }
    if (status === 'unpaid') {
      return <RedBadge text="Unpaid" />;
    }
    return <DefaultBadge bgColor="bg-yellow-100" text="Pending" textColor="text-yellow-700" />;
  };

  const headers = useMemo(
    () => [
      {
        id: 'deliveryName',
        name: t('deliveryName'),
        cell: (row) => row?.delivery?.name,
        sortable: true
      },
      currentRole !== 'klient' && {
        id: 'companyName',
        name: t('companyName'),
        cell: (row) => row.client.companyName,
        sortable: true
      },
      {
        id: 'date',
        name: t('date'),
        cell: (row) => moment(row.createdAt).format('MMMM D YYYY'),
        sortable: true
      },
      {
        id: 'total',
        name: t('total'),
        cell: (row) => `${row.valueWithoutVat + row.vatValue} $`,
        sortable: true
      },
      {
        id: 'paid',
        name: t('paid'),
        cell: (row) => `${row.paid} $`,
        sortable: true
      },
      {
        id: 'remaining',
        name: t('remaining'),
        cell: (row) => `${row.remaining} $`,
        sortable: true
      },
      {
        id: 'status',
        name: t('status'),
        cell: renderBadge
      },
      {
        id: 'actions',
        name: t('actions'),
        cell: (row) => <InvoiceActions invoice={row} currentRole={currentRole} />
      }
    ],
    [currentRole, t]
  );

  const getData = (params) => {
    setLoading(true);
    if (currentRole === 'klient') {
      InvoiceService.clientAll(params).then((response) => {
        const data = _.get(response, 'data.data.items');
        const metaData = _.get(response, 'data.data.totalRecords');
        setData(data);
        setMetaData(metaData);
        setLoading(false);
      });
    }
    if (currentRole !== 'klient') {
      InvoiceService.all(params).then((response) => {
        const data = _.get(response, 'data.data.items');
        const metaData = _.get(response, 'data.data.totalRecords');
        setData(data);
        setMetaData(metaData);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    setShowskeleton(true);
    if (currentRole === 'klient') {
      InvoiceService.clientStatistics().then((response) => {
        const statistics = _.get(response, 'data.data');
        setStatistics(statistics);
        setShowskeleton(false);
      });
    }
    if (currentRole !== 'klient') {
      InvoiceService.statistics().then((response) => {
        const statistics = _.get(response, 'data.data');
        setStatistics(statistics);
        setShowskeleton(false);
      });
    }
  }, [currentRole, updatedTable]);

  return (
    <div className="px-12">
      <h1 className="flex border-b-2 pt-8 pb-2 mb-8 font-semibold text-2xl">{t('invoices')}</h1>
      <div className="flex justify-center">
        <TableStatistic
          showSkeleton={showSkeleton}
          value={statistics.sumAmount}
          label={t('total')}
          valueTextColor="text-blue-500"
        />
        <TableStatistic
          showSkeleton={showSkeleton}
          value={statistics.paidAmount}
          label={t('paid')}
          valueTextColor="text-green-600"
        />
        <TableStatistic
          showSkeleton={showSkeleton}
          value={statistics.unpaidAmount}
          label={t('unpaid')}
          valueTextColor="text-red-500"
        />
      </div>
      <div className="border bg-white rounded justify-between items-center mb-5 py-2">
        <div className="mx-3 my-5">
          <label className="text-lg font-bold">{t('invoices')}</label>
          <div className="hidden lg:block">
            <Datatable
              data={data}
              headers={headers}
              totalRows={metaData}
              getData={getData}
              isLoading={loading}
              extraDependencies={[updatedTable]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesIndex;
