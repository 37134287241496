import React, { useMemo, useState } from 'react';
import Datatable from '@core/table/Datatable';
import { useTranslation } from 'react-i18next';
import DocumentService from '@services/DocumentService';
import CreateDocument from '@components/Document/CreateDocument';
import GreenBadge from '@core/badges/GreenBadge';
import RedBadge from '@core/badges/RedBadge';
import DocumentActions from '@components/Document/partials/DocumentActions';

const DocumentsIndex = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState();
  const [updatedTable, setUpdatedTable] = useState(0);
  const [data, setData] = useState([]);

  const headers = useMemo(
    () => [
      {
        id: 'name',
        name: t('name'),
        cell: (row) => row.name,
        sortable: true
      },
      {
        id: 'documentType',
        name: t('documentType'),
        cell: (row) => t(row.usage),
        sortable: true
      },
      {
        id: 'required',
        name: t('required'),
        cell: (row) =>
          row.required === true ? <GreenBadge text={t('true')} /> : <RedBadge text={t('false')} />,
        sortable: true
      },
      {
        id: 'actions',
        name: t('actions'),
        cell: (row) => (
          <DocumentActions
            document={row}
            onEdited={() => setUpdatedTable((prev) => prev + 1)}
            onDeleted={() => setUpdatedTable((prev) => prev + 1)}
          />
        )
      }
    ],
    [t]
  );

  const getData = (params) => {
    setLoading(true);
    DocumentService.all(params).then((response) => {
      setMetaData(response.data.data.totalRecords);
      setData(response.data.data.items);
      setLoading(false);
    });
  };

  return (
    <div className="px-12">
      <h1 className="flex border-b-2 pt-8 pb-2 mb-8 font-semibold text-2xl">
        {t('documentTypes')}
      </h1>
      <div className="border bg-white rounded justify-between items-center mb-5 py-2">
        <div className="mx-3 my-5">
          <div className="flex w-1/2 sm:w-1/4 md:w-1/6 mt-3 mr-2">
            <CreateDocument
              onAdded={() => setUpdatedTable((prev) => prev + 1)}
              label={`${t('addDocument')}`}
            />
          </div>
          <div className="hidden lg:block">
            <Datatable
              data={data}
              headers={headers}
              totalRows={metaData}
              getData={getData}
              isLoading={loading}
              extraDependencies={[updatedTable]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsIndex;
