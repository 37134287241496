import React from 'react';
import { useNavigate } from 'react-router';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import EditButton from '@core/buttons/atoms/EditButton';
import { FaTag } from 'react-icons/fa';
import ViewButton from '@core/buttons/atoms/ViewButton';
import { useTranslation } from 'react-i18next';

const PublishedTenderActions = ({ hasOwnBids, tender }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (tender.status === 'published') {
    if (hasOwnBids) {
      return (
        <div className="flex justify-between items-center">
          <div className="mr-2">
            <EditButton
              onClick={() => {
                navigate(`/my-tenders/tender/${tender.id}`, {
                  state: { disabled: false, hasOwnBids }
                });
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="flex justify-between items-center">
        <div className="mr-2">
          <DefaultButton
            title={t('makeOffer')}
            sm
            label={
              <div className="flex items-center">
                <FaTag />
              </div>
            }
            bgColor="bg-orange-100"
            bgColorHover="hover:bg-orange-200"
            textColor="text-primary"
            onClick={() => {
              navigate(`/published-tenders/tender/${tender.id}`, {
                state: { disabled: false, hasOwnBids }
              });
            }}
          />
        </div>
      </div>
    );
  }
  if (tender.status === 'closed') {
    return (
      <div className="flex justify-between items-center">
        <div className="mr-2">
          <ViewButton
            onClick={() => {
              navigate(`/my-tenders/tender/${tender.id}`, {
                state: { disabled: true, hasOwnBids }
              });
            }}
          />
        </div>
      </div>
    );
  }
  if (tender.status === 'canceled') {
    return (
      <div className="flex justify-between items-center">
        <div className="mr-2">
          <ViewButton
            onClick={() => {
              navigate(`/my-tenders/tender/${tender.id}`, {
                state: { disabled: true, hasOwnBids }
              });
            }}
          />
        </div>
      </div>
    );
  }
};

export default PublishedTenderActions;
