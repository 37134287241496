import React, { useCallback, useEffect, useState } from 'react';
import ViewModal from '@core/modals/ViewModal';
import ViewButton from '@core/buttons/atoms/ViewButton';
import RowDetails from '@hoc/cruds/RowDetails';
import { useTranslation } from 'react-i18next';
import ClientService from '@services/ClientService';
import TabSwitch from '@core/tabs/TabSwitch';
import DocumentFileUpload from '@components/Client/partials/DocumentFileUpload';
import DocumentService from '@services/DocumentService';
import { showError } from '@utils/helpers';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { internalSystemRoles } from '@constants/roles';

const ShowClient = ({ client }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState('clientDetails');
  const [userDetails, setUserDetails] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [shareholders, setShareholder] = useState([]);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);

  const closeModal = () => {
    setActive('clientDetails');
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      DocumentService.all({ type: 'client' })
        .then((res) => {
          setRequiredDocuments(res.data.data.items);
        })
        .catch((err) => {
          showError(err.response.data.message);
        });
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal)
      ClientService.find(client.id).then((response) => {
        setClientDetails(response.data.data.client);
        setUserDetails(response.data.data.client.users[0]);
        setShareholder(response.data.data.client.shareholders);
        const systemDocuments = response.data.data.client.documents.map((document) => {
          return {
            isExisting: true,
            ...document
          };
        });
        setDocuments(systemDocuments);
      });
  }, [client.id, showModal]);

  const uploadedDocument = useCallback(
    (id) => {
      if (documents) {
        return documents.find((document) => document.documentType.id === id);
      }
    },
    [documents]
  );

  return (
    <PermissionHandler roles={internalSystemRoles}>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal show={showModal} title={`${t('overview')}`} onClose={closeModal}>
        <TabSwitch
          containerClass="mt-2 justify-center"
          items={['clientDetails', 'shareholders', 'documents']}
          activeTab={active}
          onChange={setActive}
        />
        <div className="bg-white rounded-b-lg justify-between items-center pt-2 pb-10 px-10 shadow">
          {active === 'clientDetails' && (
            <>
              <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-xl">
                {t('usersDetails')}
              </h1>
              <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
                <RowDetails label={t('firstName')} value={userDetails?.firstName} />
                <RowDetails label={t('lastName')} value={userDetails?.lastName} />
                <RowDetails label={t('email')} value={userDetails?.email} />
                <RowDetails label={t('phone')} value={userDetails?.phone} />
              </div>
              <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-xl">
                {t('clientsDetails')}
              </h1>
              <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
                <RowDetails label={t('companyName')} value={clientDetails?.companyName} />
                <RowDetails label={t('nuis')} value={clientDetails?.nuis} />
                <RowDetails label={t('administrator')} value={clientDetails?.administrator} />
                <RowDetails label={t('email')} value={clientDetails?.email} />
                <RowDetails label={t('alternativeEmail')} value={clientDetails?.alternativeEmail} />
                <RowDetails label={t('fullAddress')} value={clientDetails?.address} />
                <RowDetails label={t('country')} value={clientDetails?.country} />
                <RowDetails label={t('city')} value={clientDetails?.city} />
              </div>
              <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-xl">
                {t('authorizedPerson')}
              </h1>
              <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
                <RowDetails label={t('authorizedPerson')} value={clientDetails?.authorizedPerson} />
                <RowDetails label={t('email')} value={clientDetails?.authorizedPersonEmail} />
                <RowDetails label={t('phone')} value={clientDetails?.authorizedPersonPhone} />
              </div>
            </>
          )}
          {active === 'shareholders' && (
            <>
              <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-xl">
                {t('shareholders')}
              </h1>
              <div className="flex flex-col gap-4">
                {shareholders.map((shareholder) => (
                  <>
                    <div className="mt-4 sm:mt-8 mb-2 grid grid-cols-2 gap-5">
                      <RowDetails label={t('firstName')} value={shareholder.firstName} />
                      <RowDetails label={t('lastName')} value={shareholder.lastName} />
                      <RowDetails label={t('citizenship')} value={shareholder.citizenship} />
                    </div>
                    <hr className="flex border-b-2 mb-2 font-semibold text-xl" />
                  </>
                ))}
              </div>
            </>
          )}
          {active === 'documents' && (
            <div className="3xl:max-w-screen-lg rounded-sm mx-auto mt-12 relative">
              <div className="flex grid grid-cols-2 gap-5 px-10">
                {requiredDocuments &&
                  requiredDocuments.map((requiredDocument) => (
                    <DocumentFileUpload
                      clientId={client.id}
                      requiredDocument={requiredDocument}
                      upload={uploadedDocument(requiredDocument.id)}
                      index={requiredDocuments.indexOf(requiredDocument)}
                      disabled
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </ViewModal>
    </PermissionHandler>
  );
};

export default ShowClient;
