import React, { useMemo, useState } from 'react';
import AddButton from '@core/buttons/atoms/AddButton';
import AddModal from '@core/modals/AddModal';
import { useTranslation } from 'react-i18next';
import Input from '@core/inputs/Input';
import Select from 'react-select';
import useFormErrors from '@hooks/useFormErrors';
import CDefaultDatePicker from '@core/inputs/dateTime/CDefaultDatePicker';
import deliveryDataConstraints from '@utils/validators/deliveryDataConstraints';
import { showError, showSuccess } from '@utils/helpers';
import DeliveryService from '@services/DeliveryService';
import moment from 'moment';
import { shippingCost, percentage } from '@constants/formula';
import { barrelsPerNM3, barrelsPerTon } from '@constants/conversions';

const today = moment().format('YYYY-MM-DD');

const CreateDelivery = ({ selectedTender, selectedLot, onAdded, multiplier }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [pickUpDate, setPickUpDate] = useState('');
  const [price, setPrice] = useState(0);
  const [status, setStatus] = useState('');
  const [unit, setUnit] = useState('');
  const [selectedStatusObject, setSelectedStatusObject] = useState();
  const [selectedUnitObject, setSelectedUnitObject] = useState();

  const { t } = useTranslation();
  const { clearError, getError, validateErrors, clearErrors } = useFormErrors();

  const totalPrice = useMemo(() => {
    if (unit === 't') {
      return percentage * price + shippingCost * amount * barrelsPerTon + multiplier;
    }
    if (unit === 'bbl') {
      return percentage * price + shippingCost * amount + multiplier;
    }
    if (unit === 'nm3') {
      return percentage * price + shippingCost * amount * barrelsPerNM3 + multiplier;
    }
  }, [amount, multiplier, price, unit]);

  const statuses = useMemo(
    () => [
      { label: t('notDelivered'), value: 'not_delivered' },
      { label: t('delivered'), value: 'delivered' }
    ],
    [t]
  );

  const oilUnits = useMemo(
    () => [
      { label: t('t'), value: 't' },
      { label: t('bbl'), value: 'bbl' }
    ],
    [t]
  );

  const naturalGasUnits = useMemo(
    () => [
      { label: t('nm3'), value: 'nm3' },
      { label: t('bbl'), value: 'bbl' }
    ],
    [t]
  );

  const closeModal = () => {
    setAmount(0);
    setPrice(0);
    setShowModal(false);
    setName('');
    setStatus(null);
    setUnit(null);
    setPickUpDate('');
    setSelectedStatusObject(null);
    setSelectedUnitObject(null);
    clearErrors();
  };

  const handleChangeStatus = (item, triggeredAction) => {
    if (triggeredAction.action === 'clear') {
      setSelectedStatusObject(null);
    }
    setSelectedStatusObject(item);
    setStatus(item.value);
  };

  const handleChangeUnit = (item, triggeredAction) => {
    if (triggeredAction.action === 'clear') {
      setSelectedUnitObject(null);
    }
    setSelectedUnitObject(item);
    setUnit(item.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const errors = validateErrors(
      {
        name,
        pickUpDate,
        status,
        amount,
        price,
        totalPrice,
        unit
      },
      deliveryDataConstraints
    );
    if (errors) {
      setLoading(false);
    }

    DeliveryService.create(
      Number(amount),
      unit,
      name,
      Number(price),
      pickUpDate,
      status,
      selectedLot.id,
      selectedTender.id
    )
      .then(() => {
        showSuccess(t('deliveryCreatedSuccessfully'));
        onAdded();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <AddButton label={`${t('addDelivery')}`} onClick={() => setShowModal(true)} />
      <AddModal
        width="w-1/2"
        show={showModal}
        title={`${t('addDelivery')}`}
        onClose={closeModal}
        loading={loading}
        onSubmit={onSubmit}>
        <div className="mt-4 sm:mt-8 mb-2 flex flex-col px-5">
          <div className="grid grid-cols-2 gap-3">
            <Input
              height="h-10"
              label={t('name')}
              placeholder={t('name')}
              value={name}
              onChange={(value) => clearError('name', value, setName)}
              error={getError('name')}
            />
            <div>
              <div className="text-sm font-semibold mb-2">{t('date')}</div>
              <CDefaultDatePicker value={pickUpDate} onChange={setPickUpDate} minDate={today} />
            </div>
            <Input disabled height="h-10" label={t('tender')} value={selectedTender?.name} />
            <Input disabled height="h-10" label={t('lot')} value={selectedLot?.name} />
            <Input disabled height="h-10" label={t('product')} value={selectedLot?.product} />
            <div className="space-y-2">
              <div className="text-sm font-semibold">{t('unit')}</div>
              <Select
                placeholder={t('chooseUnit')}
                value={selectedUnitObject}
                isSearchable={false}
                onChange={handleChangeUnit}
                getOptionValue={(option) => option.value}
                options={selectedLot.product === 'OIL' ? oilUnits : naturalGasUnits}
                getOptionLabel={(unit) => (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ marginLeft: 5 }}>{unit.label}</span>
                  </div>
                )}
              />
              {getError('unit') && (
                <label className="text-xs text-red-500">{getError('unit')}</label>
              )}
            </div>
            <Input
              height="h-10"
              label={t('amount')}
              placeholder={t('amount')}
              value={amount}
              onChange={(value) => clearError('quantity', value, setAmount)}
              error={getError('quantity')}
            />
            <Input
              height="h-10"
              label={selectedLot.product === 'OIL' ? t('oilPrice') : t('gasPrice')}
              placeholder={selectedLot.product === 'OIL' ? t('oilPrice') : t('gasPrice')}
              value={price}
              onChange={(value) => clearError('price', value, setPrice)}
              error={getError('price')}
            />
          </div>
          <div className="mt-2 space-y-2">
            <div className="text-sm font-semibold mb-2 mt-2">{t('status')}</div>
            <Select
              className="w-1/2"
              placeholder={t('chooseStatus')}
              value={selectedStatusObject}
              isSearchable={false}
              onChange={handleChangeStatus}
              getOptionValue={(option) => option.value}
              options={statuses}
              getOptionLabel={(status) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ marginLeft: 5 }}>{status.label}</span>
                </div>
              )}
            />
            {getError('status') && (
              <label className="text-xs text-red-500">{getError('status')}</label>
            )}

            <Input
              height="h-10"
              label={t('total')}
              placeholder={t('total')}
              value={totalPrice?.toFixed(2)}
              disabled
              error={getError('totalPrice')}
            />
          </div>
        </div>
      </AddModal>
    </>
  );
};

export default CreateDelivery;
