import useHasPermission from '@hooks/useHasPermission';

const PermissionHandler = ({ roles = [], children }) => {
  const canAccess = useHasPermission({ roles });

  if (!canAccess) {
    return null;
  }
  return children;
};
export default PermissionHandler;
