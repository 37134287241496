import API from '@utils/plugins/API';

const InvoiceService = {
  all: (params) => {
    return API.get('clients/invoices/all', { params });
  },
  statistics: () => {
    return API.get('clients/invoices/all/statistics');
  },
  clientStatistics: () => {
    return API.get('me/invoices/statistics');
  },
  clientAll: (params) => {
    return API.get('me/invoices', { params });
  }
};

export default InvoiceService;
