import API from '@utils/plugins/API';

const ClientTenderService = {
  all: (params) => {
    return API.get('procurements/published', { params });
  },
  find: (id) => {
    return API.get(`me/procurements/${id}`);
  },
  myTenders: (params) => {
    return API.get('me/procurements', { params });
  },
  createOffer: (procurementId, lotBids) => {
    return API.post('offers', { procurementId, lotBids });
  },
  updateOffer: (procurementId, offerId, lotBids) => {
    return API.put(`me/procurements/${procurementId}/offers/${offerId}`, {
      procurementId,
      lotBids
    });
  }
};

export default ClientTenderService;
