import React from 'react';
import { useTranslation } from 'react-i18next';
import Lot from '@components/Delivery/partials/Lot';

const Lots = ({ selectedTender }) => {
  const { t } = useTranslation();

  return (
    <div className="px-12 w-full shadow-2xl">
      <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-2xl">{t('deliveries')}</h1>
      <div className="border bg-white rounded justify-between items-center">
        <div className="mx-3 my-5">
          {!selectedTender && (
            <div className="flex items-center w-1/2">
              <div className="text-lg font-semibold text-gray-600">{t('chooseATender')}</div>
              <hr className="border border-gray-400 flex-1 ml-4" />
            </div>
          )}
          {selectedTender?.lots.map((lot) => (
            <Lot selectedTender={selectedTender} selectedLot={lot} key={lot.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Lots;
