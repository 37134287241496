import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addRoles } from '@redux/selects/Role/Action';
import _ from 'lodash';
import UserService from '@services/UserService';

const RoleSelect = ({
  openModal,
  onSelect,
  selected,
  placeholder,
  disabled = false,
  error,
  showErrorText
}) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [selectedObject, setSelectedObject] = useState();

  const options = useSelector((state) => _.get(state, 'rolesReducer', []));

  const handleChangeSelected = (item) => {
    setSelectedObject(item);
    return onSelect && onSelect(item.id);
  };

  useEffect(() => {
    if (openModal) {
      UserService.allRoles().then((response) => {
        const roles = _.get(response, 'data.data');
        dispatch(addRoles(roles));
      });
    }
  }, [dispatch, openModal]);

  useEffect(() => {
    if (options) {
      const foundItem = Object.values(options)?.find((item) => item.id === selected);
      setSelectedObject(foundItem || null);
    }
  }, [selected, options]);

  return (
    <>
      <Select
        isDisabled={disabled}
        getOptionValue={(option) => option.id}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        isSearchable={false}
        options={options}
        onChange={handleChangeSelected}
        value={selectedObject}
        placeholder={placeholder}
        getOptionLabel={(selectedObject) => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }} className="text-sm">
            <span style={{ marginLeft: 5 }}>
              {language === 'al' ? selectedObject.name : selectedObject.name}
            </span>
          </div>
        )}
      />
      {error && showErrorText && <label className="text-xs text-red-500">{error}</label>}
    </>
  );
};

export default RoleSelect;
