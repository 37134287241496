import React from 'react';
import AuthLayout from '@hoc/layouts/AuthLayout';
import PasswordResetForm from '@components/Auth/partials/PasswordResetForm';

const PasswordResetPage = () => (
  <AuthLayout>
    <PasswordResetForm />
  </AuthLayout>
);
export default PasswordResetPage;
