import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

const DefaultButton = ({
  label,
  link,
  to,
  onClick,
  bgColor,
  bgColorHover,
  textColor,
  disabled = false,
  xs = false,
  sm = false,
  width = 'flex w-full',
  title,
  ...props
}) => {
  let sizingClasses = 'py-2 px-4 text-sm';
  if (xs) {
    sizingClasses = 'py-1 px-2 text-xs';
  }
  if (sm) {
    sizingClasses = 'py-2 px-3 text-xs';
  }

  const className = `group relative ${width} justify-center border border-transparent font-medium rounded-md ${
    textColor || 'text-white'
  } ${sizingClasses} 
  ${disabled ? 'bg-gray-400' : bgColor || 'bg-primary'}  ${
    disabled ? '' : bgColorHover || 'hover:bg-orange-600 hover:ease-in hover:duration-200'
  } focus:outline-none`;

  if (link) {
    // TODO: check disabled prop on link
    return (
      <Link
        disabled={disabled}
        data-testid="default-button-link"
        to={to}
        className={className}
        {...props}>
        {label}
      </Link>
    );
  }

  return (
    <Tooltip title={title}>
      <button
        onClick={onClick}
        data-testid="default-btn"
        className={className}
        disabled={disabled}
        {...props}>
        {label}
      </button>
    </Tooltip>
  );
};

export default DefaultButton;
