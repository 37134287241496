import React from 'react';
import AuthLayout from '@hoc/layouts/AuthLayout';
import TwoFactorAuthentication from '@components/Auth/TwoFactorAuthentication';

const TwoFactorAuthenticationPage = () => (
  <AuthLayout>
    <TwoFactorAuthentication />
  </AuthLayout>
);

export default TwoFactorAuthenticationPage;
