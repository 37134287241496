const tenderDataConstraints = {
  title: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_title'
    }
  },
  description: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_description'
    }
  },
  publishDate: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_publish_date'
    }
  },
  endDate: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_end_date'
    }
  },
  offerValidityInterval: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_offer_validity_interval'
    }
  },
  termsAndConditions: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_terms_and_conditions'
    }
  }
};
export default tenderDataConstraints;
