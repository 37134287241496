import React from 'react';
import EditUser from '@components/User/EditUser';
import ShowUser from '@components/User/ShowUser';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import UserService from '@services/UserService';
import ResendActivationLink from '@components/User/partials/ResendActivationLink';
import { admin } from '@constants/roles';
import ToggleUser from '@components/User/ToggleUser';

const UserActions = ({ user, onEdited, onDeleted, onToggle }) => {
  return (
    <div className="flex justify-between items-center">
      {user?.status === 'not_activated' && (
        <div className="mr-2">
          <ResendActivationLink user={user} />
        </div>
      )}
      {user.status !== 'not_activated' && (
        <div className="mr-2">
          <ToggleUser user={user} onToggle={onToggle} />
        </div>
      )}
      <div className="mr-2">
        <ShowUser user={user} />
      </div>
      <div className="mr-2">
        <EditUser user={user} onEdited={onEdited} />
      </div>
      <DeleteEntity service={UserService} id={user.id} onDeleted={onDeleted} roles={admin} />
    </div>
  );
};

export default UserActions;
