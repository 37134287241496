import React from 'react';
import { useTranslation } from 'react-i18next';

const TabSwitch = ({ items = [], activeTab, onChange, containerClass }) => {
  const { t } = useTranslation();
  return (
    <div className="font-medium text-center text-gray-500">
      <ul className={`flex ${containerClass}`}>
        {items.map((item) => (
          <li
            role="presentation"
            key={item}
            className={`inline-block p-2 cursor-pointer text-base mx-2 px-3 py-2 ${
              activeTab === item
                ? 'text-primary border-b-2 border-primary bg-white'
                : 'hover:bg-orange-50 hover:rounded-full hover:cursor-pointer'
            }`}
            onClick={() => {
              onChange(item);
            }}>
            {t(item)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabSwitch;
