import React, { useEffect, useState } from 'react';
import DashboardService from '@services/DashboardService';
import { showError } from '@utils/helpers';

const Time = () => {
  const [serverTime, setServerTime] = useState(0);

  const formatNumber = (number) => {
    return number.length === 1 ? `0${number}` : number;
  };

  const splitTime = () => {
    const hours = Math.floor((serverTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((serverTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((serverTime % (1000 * 60)) / 1000);

    return {
      hours: formatNumber(hours.toString()),
      minutes: formatNumber(minutes.toString()),
      seconds: formatNumber(seconds.toString())
    };
  };

  const fetchTime = () => {
    DashboardService.time()
      .then(async (response) => {
        const serverTime = response.data.data;
        const { hoursInMilliseconds, minutesInMilliseconds, secondsInMilliseconds } = {
          hoursInMilliseconds: +serverTime.split(':')[0] * 1000 * 60 * 60,
          minutesInMilliseconds: +serverTime.split(':')[1] * 1000 * 60,
          secondsInMilliseconds: +serverTime.split(':')[2] * 1000
        };
        setServerTime(hoursInMilliseconds + minutesInMilliseconds + secondsInMilliseconds);
      })
      .catch((err) => {
        showError(err.response.data.message);
      });
  };

  useEffect(() => {
    const time = setInterval(() => setServerTime(serverTime + 1000), 1000);
    return () => {
      clearInterval(time);
    };
  }, [serverTime]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        fetchTime();
      }
    });
  }, []);

  useEffect(() => {
    fetchTime();
  }, []);

  return (
    <div className="mx-2 h-10 flex items-center text-xl text-blue-900 tracking-wide border rounded-full p-3 border-primary">
      <span>
        {splitTime().hours}:{splitTime().minutes}:
        <span className="text-lg text-primary">{splitTime().seconds}</span>
      </span>
    </div>
  );
};

export default Time;
