import React, { useState } from 'react';
import TendersSidebar from '@components/Tender/partials/TendersSidebar';
import Lots from '@components/Delivery/partials/Lots';

const DeliveriesPage = () => {
  const [selectedTender, setSelectedTender] = useState();

  return (
    <div className="flex">
      <TendersSidebar setSelectedTender={setSelectedTender} selectedTender={selectedTender} />
      <Lots selectedTender={selectedTender} />
    </div>
  );
};

export default DeliveriesPage;
