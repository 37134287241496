import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import DeliveryService from '@services/DeliveryService';
import { FaMailBulk } from 'react-icons/fa';
import { showError, showSuccess } from '@utils/helpers';
import BlankModal from '@core/modals/BlankModal';
import SubmitButton from '@core/buttons/atoms/SubmitButton';

const SendInvoice = ({ delivery, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    DeliveryService.sendInvoice(delivery?.invoice?.id)
      .then(() => {
        showSuccess(t('invoiceSentSuccessfully'));
        closeModal();
        onSuccess();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <DefaultButton
        title={t('sendInvoice')}
        bgColor="bg-yellow-100"
        bgColorHover="hover:bg-yellow-200"
        textColor="text-yellow-700"
        sm
        label={<FaMailBulk />}
        onClick={() => setShowModal(true)}
      />
      <BlankModal
        otherButtons={[<SubmitButton loading={loading} onClick={onSubmit} label={t('send')} />]}
        onSubmit={onSubmit}
        show={showModal}
        title={`${t('sendInvoice')}`}
        loading={loading}
        icon={<FaMailBulk className="text-orange-800" />}
        onClose={closeModal}>
        <div className="text-lg mb-5">
          <span>{`${t('areYouSureYouWantToSendInvoiceByEmail')}`}</span>
        </div>
      </BlankModal>
    </>
  );
};

export default SendInvoice;
