import React, { useCallback, useEffect, useState } from 'react';
import CountrySelect from '@core/dropdowns/CountrySelect';
import Input from '@core/inputs/Input';
// eslint-disable-next-line import/no-extraneous-dependencies
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

const PhoneNumberInput = ({ placeholder, label, onChange, error, phoneNumber }) => {
  const [prefix, setPrefix] = useState('+355');
  const [number, setNumber] = useState('');

  useEffect(() => {
    if (phoneNumber) {
      const currentNumber = parsePhoneNumber(phoneNumber);
      setPrefix(`+${currentNumber?.countryCallingCode}`);
      setNumber(currentNumber?.nationalNumber);
    } else {
      setNumber('');
      setPrefix('+355');
    }
  }, [phoneNumber]);

  const handleChangeNumber = useCallback(
    (val) => {
      setNumber(val);
      if (isValidPhoneNumber(`${prefix}${val}`)) {
        onChange(`${prefix}${val}`);
      }
    },
    [onChange, prefix]
  );

  const handleChangePrefix = useCallback(
    (val) => {
      setPrefix(val);
      if (isValidPhoneNumber(`${val}${number}`)) {
        onChange(`${val}${number}`);
      }
    },
    [number, onChange]
  );

  return (
    <div className="flex flex-col">
      {label && <div className="text-sm font-semibold mb-2">{label}</div>}
      <div className="flex">
        <div className="w-28">
          <CountrySelect
            updatePrefix={(val) => {
              handleChangePrefix(val);
            }}
            prefix={prefix}
          />
        </div>
        <Input
          extraClasses="flex w-full"
          height="h-10 mt-0"
          placeholder={placeholder}
          value={number}
          onChange={(val) => {
            handleChangeNumber(val);
          }}
        />
      </div>
      {error && <label className="text-xs text-red-500 pt-2">{error}</label>}
    </div>
  );
};

export default PhoneNumberInput;
