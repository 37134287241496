import React, { useCallback, useEffect, useState } from 'react';
import EditModal from '@core/modals/EditModal';
import EditButton from '@core/buttons/atoms/EditButton';
import { useTranslation } from 'react-i18next';
import TabSwitch from '@core/tabs/TabSwitch';
import ClientDetails from '@components/Client/partials/ClientDetails';
import Shareholders from '@components/Client/partials/Shareholders';
import ClientService from '@services/ClientService';
import { showError, showSuccess } from '@utils/helpers';
import DocumentFileUpload from '@components/Client/partials/DocumentFileUpload';
import DocumentService from '@services/DocumentService';
import clientDataConstraints from '@utils/validators/clientDataConstraints';
import shareHolderDataConstraints from '@utils/validators/shareHolderDataConstraints';
import useFormErrors from '@hooks/useFormErrors';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { admin } from '@constants/roles';

const EditClient = ({ client, onEdited }) => {
  const { t } = useTranslation();
  const { getError, clearError, validateErrors } = useFormErrors();

  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState('clientDetails');
  const [userDetails, setUserDetails] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const [shareholders, setShareholder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [hasShareHolderError, setHasShareHolderError] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setActive('clientDetails');
  };

  useEffect(() => {
    if (showModal) {
      DocumentService.all({ type: 'client' })
        .then((res) => {
          setRequiredDocuments(res.data.data.items);
        })
        .catch((err) => {
          showError(err.response.data.message);
        });
    }
  }, [showModal]);

  const onSubmit = () => {
    setLoading(true);

    const errors = validateErrors(
      {
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        email: userDetails?.email,
        phoneNumber: userDetails?.phone,
        companyName: clientDetails?.companyName,
        nuis: clientDetails?.nuis,
        administrator: clientDetails?.administrator,
        userEmail: clientDetails?.email,
        alternativeEmail: clientDetails?.alternativeEmail,
        fullAddress: clientDetails?.address,
        country: clientDetails?.country,
        city: clientDetails?.city,
        authorizedPerson: clientDetails?.authorizedPerson,
        authorizedPersonEmail: clientDetails?.authorizedPersonEmail,
        authorizedPersonPhone: clientDetails?.authorizedPersonPhone
      },
      clientDataConstraints
    );
    let shareHolderError;
    shareholders.forEach(({ firstName, lastName, citizenship }) => {
      shareHolderError = validateErrors(
        { shareHolderFirstName: firstName, shareHolderLastName: lastName, citizenship },
        shareHolderDataConstraints
      );
      if (shareHolderError) {
        showError(t('errors.empty_shareHolders'));
        setHasShareHolderError(true);
      }
    });

    if (errors || shareHolderError) {
      setLoading(false);
      return;
    }

    delete clientDetails.users;
    delete clientDetails.shareholders;
    delete clientDetails.documents;
    const payload = {
      ...clientDetails,
      users: [userDetails],
      documents,
      shareholders
    };
    ClientService.update(client.id, payload)
      .then(() => {
        showSuccess(t('clientEditedSuccessfully'));
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      ClientService.find(client.id).then((response) => {
        setClientDetails(response.data.data.client);
        setUserDetails(response.data.data.client.users[0]);
        setShareholder(response.data.data.client.shareholders);
        const systemDocuments = response.data.data.client.documents.map((document) => {
          return {
            isExisting: true,
            ...document
          };
        });
        setDocuments(systemDocuments);
      });
  }, [client.id, showModal]);

  const updateUpload = useCallback(
    (index, upload) => {
      const newUpload = [...documents];
      newUpload[index] = upload;
      setDocuments((prev) => {
        const exists = prev.find((rec) => rec.id === upload.id);
        if (exists) {
          return prev.map((rec) => {
            if (rec.id === upload.id) {
              return upload;
            }
            return rec;
          });
        }
        return [...prev, upload];
      });
    },
    [documents]
  );

  const uploadedDocument = useCallback(
    (id) => {
      if (documents) {
        return documents.find((document) => document?.documentType?.id === id);
      }
    },
    [documents]
  );

  const handleDelete = (index) => {
    const newDocument = documents.filter((document) => document.id !== index);
    setDocuments(newDocument);
  };

  return (
    <PermissionHandler roles={admin}>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        onSubmit={onSubmit}
        show={showModal}
        title={t('editClient')}
        onClose={closeModal}
        loading={loading}
        label={t('edit')}>
        <TabSwitch
          containerClass="mt-2 justify-center"
          items={['clientDetails', 'shareholders', 'documents']}
          activeTab={active}
          onChange={setActive}
        />
        <div className="bg-white rounded-b-lg justify-between items-center pt-2 pb-10 px-10 shadow">
          {active === 'clientDetails' && (
            <ClientDetails
              clearError={clearError}
              userData={userDetails}
              onChangeUser={setUserDetails}
              clientData={clientDetails}
              onChangeClient={setClientDetails}
              showModal={showModal}
              errors={getError}
            />
          )}
          {active === 'shareholders' && (
            <Shareholders
              clearErrors={setHasShareHolderError}
              data={shareholders}
              onChange={setShareholder}
              hasError={hasShareHolderError}
            />
          )}
          {active === 'documents' && (
            <div className="3xl:max-w-screen-lg rounded-sm mx-auto mt-12 relative">
              <div className="flex grid grid-cols-2 gap-5 px-10">
                {requiredDocuments &&
                  requiredDocuments.map((requiredDocument) => (
                    <DocumentFileUpload
                      clientId={client.id}
                      handleDelete={handleDelete}
                      requiredDocument={requiredDocument}
                      upload={uploadedDocument(requiredDocument.id)}
                      index={requiredDocuments.indexOf(requiredDocument)}
                      updateUpload={updateUpload}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      </EditModal>
    </PermissionHandler>
  );
};

export default EditClient;
