import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import Input from '@core/inputs/Input';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '@components/LanguageSelector/LanguageSelector';
import { Link, useNavigate } from 'react-router-dom';
import logo from '@assets/images/logo.png';
import AuthService from '@services/AuthService';
import { showError, showSuccess } from '@utils/helpers';
import useFormErrors from '@hooks/useFormErrors';
import emailConstraints from '@utils/validators/emailConstraints';

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clearError, getError, validateErrors } = useFormErrors();

  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = validateErrors(
      {
        email
      },
      emailConstraints
    );
    if (errors) {
      return;
    }
    dispatch(showSpinner(t('pleaseWait')));
    AuthService.passwordResetLink(email)
      .then(() => {
        showSuccess();
        navigate('/');
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  return (
    <div className="flex flex-col w-full justify-center h-screen">
      <div className="ml-6 pt-4 pb-6 flex justify-end">
        <LanguageSelector />
      </div>
      <img src={logo} alt="Alb petrol logo" className="w-50 mx-auto py-10" />
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-gray-900">{t('forgotPassword')}</h2>
        <p className="text-sm font-light">{t('enterYourEmailToReceivePasswordResetLink')}</p>
      </div>
      <form className="mt-8 space-y-2" onSubmit={onSubmit}>
        <div className="mb-6 space-y-6">
          <Input
            value={email}
            placeholder={t('Email')}
            className="rounded-b-none "
            onChange={(value) => clearError('email', value, setEmail)}
            error={getError('email')}
            height="h-13"
            extraClasses="text-2xl"
          />
        </div>
        <DefaultButton
          md
          type="submit"
          bgColorHover="bg-primary"
          bgColor="hover:bg-orange-600 hover:ease-in hover:duration-200"
          label={t('sendLink')}
        />
      </form>
      <div className="pt-4 w-full text-gray-500">
        <Link to="/">{t('backToLoginPage')}</Link>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
