import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RowDetails from '@hoc/cruds/RowDetails';
import Input from '@core/inputs/Input';
import { BiBadgeCheck } from 'react-icons/bi';
import RedBadge from '@core/badges/RedBadge';

const TenderOfferLot = ({ lot, index, updateBid, bid, disabled, status }) => {
  const { t } = useTranslation();

  const [multiplier, setMultiplier] = useState('');
  const [extraClasses, setExtraClasses] = useState('');

  const handleMultiplierChange = (val) => {
    setMultiplier(val);
    const currentBid = {
      ...bid,
      lotId: Number(lot.id),
      multiplier: parseFloat(val),
      index: lot?.index || index
    };
    updateBid(lot.id, currentBid);
  };

  useEffect(() => {
    if (bid?.length !== 0) {
      setMultiplier(bid?.multiplier);
    }
  }, [bid?.length, bid?.multiplier]);

  useEffect(() => {
    if (status === 'closed') {
      if (bid?.isWinner) {
        setExtraClasses('border-2 border-emerald-500 shadow-[2px_3px_5px_2px_rgba(16,185,129,1)]');
      } else setExtraClasses('border-red-500 shadow-[2px_3px_5px_2px_rgba(239,68,68,1)]');
    } else setExtraClasses('border-gray-300 shadow-lg');
  }, [bid?.isWinner, status]);

  const renderBadge = () => {
    if (status === 'closed') {
      if (bid?.isWinner) {
        return (
          <div className="flex items-center">
            <span className="ml-7 inline-flex items-center justify-center p-2 text-xs font-medium leading-4 rounded-full w-30 text-emerald-800 bg-emerald-100">
              <BiBadgeCheck className="text-xl mr-2" />
              <span>{t('winner')}</span>
            </span>
          </div>
        );
      }
      return (
        <div className="ml-7">
          <RedBadge text={t('notWinner')} />
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`ml-10 mr-5 my-5 bg-white rounded-md font-semibold leading-5 p-5 border ${extraClasses}`}>
      <div className="text-lg font-bold mb-7 flex items-center text-primary px-5 flex justify-between">
        <div>{`${t('lot')} ${index + 1}`}</div>
        <div className="flex items-center">
          <div className="text-gray-800 font-light">{lot.name}</div>
          {renderBadge()}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-3">
        <RowDetails
          color="orange"
          label={t('deliveryProfile')}
          value={`${lot.startDate} - ${lot.endDate}`}
        />
        <RowDetails color="orange" label={t('pickUp')} value={lot.pickUp} />
        <div className="flex flex-col">
          <RowDetails
            color="orange"
            label={t('amount')}
            value={`${lot?.originalUnit === 'bbl' ? lot?.barrels : lot?.amount} ${t(
              lot?.originalUnit
            )}`}
          />
          <div className="mx-10 mb-4 text-xs pt-2 text-semibold">{`${t('itIsEqualTo')}  ${
            lot?.originalUnit === 'bbl' ? lot?.amount : lot?.barrels
          } ${
            // eslint-disable-next-line no-nested-ternary
            lot?.originalUnit === 'bbl'
              ? lot?.product === 'OIL'
                ? t('t')
                : t('nm3')
              : t('barrels')
          }`}</div>
        </div>
        <RowDetails label={t('product')} value={t(lot.product)} color="orange" />
      </div>
      <div className="mx-10 my-7">
        <Input
          disabled={disabled}
          height="h-10"
          label={t('multiplier')}
          placeholder={t('multiplier')}
          value={multiplier}
          onChange={(val) => handleMultiplierChange(val)}
        />
      </div>
    </div>
  );
};
export default TenderOfferLot;
