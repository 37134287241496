import React, { useCallback, useMemo } from 'react';
import { Trash } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Lot from '@components/Tender/tabs/partials/Lot';

const TenderLots = ({ disabled = false, data, onChange, onDelete }) => {
  const { t } = useTranslation();

  const lots = data?.map((lot, index) => {
    return {
      ...lot,
      index
    };
  });

  const handleDelete = useCallback(
    (index) => {
      const newLot = lots.filter((lot) => lot.index !== index);
      onDelete(newLot);
    },
    [lots, onDelete]
  );

  const headers = useMemo(
    () => [
      {
        id: 'rank',
        name: t('rank'),
        cell: (row) => `${t('lot')} ${row.index + 1}`,
        sortable: true
      },
      {
        id: 'name',
        name: t('name'),
        cell: (row) => row.name,
        sortable: true
      },
      {
        id: 'startDate',
        name: t('startDate'),
        cell: (row) => row.startDate,
        sortable: true
      },
      {
        id: 'endDate',
        name: t('endDate'),
        cell: (row) => row.endDate,
        sortable: true
      },
      {
        id: 'product',
        name: t('product'),
        cell: (row) => row.product,
        sortable: true
      },
      {
        id: 'amount',
        name: t('amount'),
        cell: (row) => row.amount,
        sortable: true
      },
      {
        id: 'unit',
        name: t('unit'),
        cell: (row) => t(row.originalUnit),
        sortable: true
      },
      {
        id: 'pickUp',
        name: t('pickUp'),
        cell: (row) => row.pickUp,
        sortable: true
      },
      !disabled && {
        id: 'actions',
        name: t('actions'),
        width: '100px',
        show: true,
        cell: (row) => (
          <div className="flex justify-between items-center">
            <div className="mr-2">
              <Lot lot={row} onEdit onChange={onChange} index={row.index + 1} />
            </div>
            <button
              className="text-red-600 cursor-pointer mr-2"
              onClick={() => handleDelete(row.index + 1)}>
              <Trash />
            </button>
          </div>
        )
      }
    ],
    [disabled, handleDelete, onChange, t]
  );

  return (
    <div>
      <div className="text-sm font-bold mb-7 mt-7 flex items-center">{t('lots')}</div>
      <DataTable columns={headers} data={lots} />
      {!disabled && (
        <div className="pt-3 mt-5 border-t">
          <Lot onChange={onChange} index={lots.length + 1 || 1} />
        </div>
      )}
    </div>
  );
};

export default TenderLots;
