import React from 'react';
import Header from '@hoc/partials/Header';

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      {children}
    </div>
  );
};

export default Layout;
