import React from 'react';
import LoginForm from '@components/Auth/LoginForm';
import AuthLayout from '@hoc/layouts/AuthLayout';

const LoginPage = () => (
  <AuthLayout>
    <LoginForm />
  </AuthLayout>
);

export default LoginPage;
