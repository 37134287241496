import React, { useState } from 'react';
import UserService from '@services/UserService';
import { showError, showSuccess } from '@utils/helpers';
import { FaLink } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import ToggleModal from '@core/modals/ToggleModal';
import { useTranslation } from 'react-i18next';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { admin } from '@constants/roles';

const ToggleUser = ({ user }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    UserService.resendActivationLink(user.id)
      .then(() => {
        showSuccess(t('linkSent'));
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PermissionHandler roles={admin}>
      <DefaultButton
        bgColor="bg-orange-100"
        sm
        bgColorHover="hover:bg-orange-200"
        textColor="text-primary"
        onClick={() => setShowModal(true)}
        label={<FaLink />}
      />
      <ToggleModal
        title={t('resendActivationLink')}
        show={showModal}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={t('send')}>
        <div className="text-lg my-3 ml-5">
          <span>{t('areYouSureYouWantToResendActivationLink')}</span>
        </div>
      </ToggleModal>
    </PermissionHandler>
  );
};
export default ToggleUser;
