import React from 'react';
import { SkeletonItem } from 'react-skeleton-loader-pulse';
import 'react-loading-skeleton/dist/skeleton.css';

const TableStatistic = ({
  value,
  label,
  className,
  valueTextColor = 'text-black',
  showSkeleton = false
}) => {
  return (
    <div className="mx-10 mb-4">
      {showSkeleton ? (
        <div className="flex">
          <SkeletonItem borderRadius={5} pulseTime={1.5} width={140} height={22} />
        </div>
      ) : (
        <h1 className="font-semibold text-xl text-gray-600">
          {label}
          <span className={`pl-4 ${className} ${valueTextColor}`}>{value}</span>
        </h1>
      )}
    </div>
  );
};

export default TableStatistic;
