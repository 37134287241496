import React from 'react';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import ClientService from '@services/ClientService';
import EditClient from '@components/Client/EditClient';
import ShowClient from '@components/Client/ShowClient';
import ToggleClient from '@components/Client/ToggleClient';
import { admin } from '@constants/roles';

const ClientActions = ({ client, onEdited, onDeleted, onToggle }) => {
  return (
    <div className="flex justify-between items-center">
      {client.status !== 'pending' && (
        <div className="mr-2">
          <ToggleClient client={client} onToggle={onToggle} />
        </div>
      )}
      <div className="mr-2">
        <ShowClient client={client} />
      </div>
      <div className="mr-2">
        <EditClient client={client} onEdited={onEdited} />
      </div>
      <DeleteEntity service={ClientService} id={client.id} onDeleted={onDeleted} roles={admin} />
    </div>
  );
};

export default ClientActions;
