import React, { useEffect, useMemo, useState } from 'react';
import Datatable from '@core/table/Datatable';
import UserActions from '@components/User/partials/UserActions';
import UserService from '@services/UserService';
import CreateUser from '@components/User/CreateUser';
import { useTranslation } from 'react-i18next';
import TableStatistic from '@hoc/cruds/TableStatistic';
import UserStatus from '@components/User/partials/UserStatus';

const UsersIndex = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState();
  const [updatedTable, setUpdatedTable] = useState(0);
  const [data, setData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [pendingUsers, setPendingUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const headers = useMemo(
    () => [
      {
        id: 'fullName',
        name: t('fullName'),
        cell: (row) => `${row.firstName} ${row.lastName}`,
        sortable: true
      },
      {
        id: 'email',
        name: t('email'),
        cell: (row) => row.email,
        sortable: true
      },
      {
        id: 'phone',
        name: t('phone'),
        cell: (row) => row.phone,
        sortable: true
      },
      {
        id: 'role',
        name: t('role'),
        cell: (row) => row.role,
        sortable: true,
        minWidth: '300px'
      },
      {
        id: 'status',
        name: 'Status',
        cell: (row) => <UserStatus status={row.status}>{row.status}</UserStatus>
      },
      {
        id: 'actions',
        name: t('actions'),
        cell: (row) => (
          <UserActions
            user={row}
            onToggle={() => setUpdatedTable((prev) => prev + 1)}
            onEdited={() => setUpdatedTable((prev) => prev + 1)}
            onDeleted={() => setUpdatedTable((prev) => prev + 1)}
            onUpdated={() => setUpdatedTable((prev) => prev + 1)}
          />
        )
      }
    ],
    [t]
  );

  const getData = (params) => {
    setLoading(true);
    UserService.all(params).then((response) => {
      setMetaData(response.data.data.totalRecords);
      setData(response.data.data.items);
      setLoading(false);
    });
  };

  useEffect(() => {
    setShowSkeleton(true);
    UserService.statistics().then((response) => {
      setTotalUsers(response.data.data.totalRecords);
      setActiveUsers(response.data.data.totalActive);
      setPendingUsers(response.data.data.totalPending);
      setInactiveUsers(response.data.data.totalInactive);
      setShowSkeleton(false);
    });
  }, [updatedTable]);

  return (
    <div className="px-12">
      <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-2xl">{t('users')}</h1>
      <div className="flex justify-center">
        <TableStatistic
          showSkeleton={showSkeleton}
          value={totalUsers}
          label={t('total')}
          valueTextColor="text-blue-500"
        />
        <TableStatistic
          showSkeleton={showSkeleton}
          value={activeUsers}
          label={t('active')}
          valueTextColor="text-green-600"
        />
        <TableStatistic
          showSkeleton={showSkeleton}
          value={pendingUsers}
          label={t('pending')}
          valueTextColor="text-yellow-500"
        />
        <TableStatistic
          showSkeleton={showSkeleton}
          value={inactiveUsers}
          label={t('inactive')}
          valueTextColor="text-red-500"
        />
      </div>
      <div className="border bg-white rounded justify-between items-center">
        <div className="mx-3 my-5">
          <label className="text-lg font-bold">{t('users')}</label>
          <div className="flex w-1/2 sm:w-1/4 md:w-1/6 mt-3 mr-2">
            <CreateUser
              onAdded={() => setUpdatedTable((prev) => prev + 1)}
              label={`${t('addUser')}`}
            />
          </div>
          <div className="hidden lg:block">
            <Datatable
              data={data}
              headers={headers}
              totalRows={metaData}
              getData={getData}
              isLoading={loading}
              extraDependencies={[updatedTable]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersIndex;
