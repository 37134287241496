import API from '@utils/plugins/API';

const TenderService = {
  all: (params) => {
    return API.get('procurements', { params });
  },
  create: (payload) => {
    return API.post('procurements', {
      ...payload
    });
  },
  update: (id, payload) => {
    return API.put(`procurements/${id}`, {
      ...payload
    });
  },
  find: (id) => {
    return API.get(`procurements/${id}`);
  },
  offers: (id) => {
    return API.get(`procurements/${id}/offers`);
  },
  lotBids: (id) => {
    return API.get(`procurements/${id}/lot-bids`);
  },
  ongoing: (id) => {
    return API.get(`procurements/${id}/ongoing`);
  },
  destroy: (id) => {
    return API.delete(`procurements/${id}`);
  },
  cancel: (id) => {
    return API.get(`procurements/${id}/cancel`);
  },
  canceled: (id) => {
    return API.get(`procurements/${id}/canceled`);
  },
  publish: (id) => {
    return API.put(`procurements/${id}/publish`);
  },
  downloadTermsAndConditions: (id) => {
    return API.get(`procurements/${id}/download-terms`);
  },
  evaluateStatus: (id) => {
    return API.put(`procurements/${id}/evaluate`);
  },
  vote: (id, vote) => {
    return API.post(`offers/lot-bids/${id}/vote`, { vote });
  },
  confirmBid: (id) => {
    return API.post(`offers/lot-bids/${id}/confirm`);
  },
  revertBid: (id) => {
    return API.post(`offers/lot-bids/${id}/revert`);
  },
  complete: (id) => {
    return API.put(`procurements/${id}/complete`);
  },
  generateContract: (id) => {
    return API.post(`contracts/${id}`);
  },
  downloadContract: (id) => {
    return API.get(`contracts/${id}/download`);
  },
  sendContract: (id) => {
    return API.post(`contracts/${id}/send`);
  },
  deleteContract: (id) => {
    return API.delete(`contracts/${id}`);
  }
};

export default TenderService;
