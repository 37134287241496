import React from 'react';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import EditButton from '@core/buttons/atoms/EditButton';
import { useNavigate } from 'react-router';
import TenderService from '@services/TenderService';
import PublishTender from '@components/Tender/PublishTender';
import CancelTender from '@components/Tender/CancelTender';
import ViewButton from '@core/buttons/atoms/ViewButton';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { FaHammer } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import EvaluateTender from '@components/Tender/EvaluateTender';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { admin, internalSystemRoles } from '@constants/roles';

const TenderActions = ({ status, tender, onDeleted, onPublished, onCancelled }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  switch (status) {
    case 'draft':
      return (
        <div className="flex justify-between items-center">
          <div className="mr-2">
            <Tooltip title={t('publish')}>
              <PublishTender id={tender.id} onPublished={onPublished} />
            </Tooltip>
          </div>
          <div className="mr-2">
            <PermissionHandler roles={admin}>
              <EditButton
                title={t('edit')}
                onClick={() => {
                  navigate(`/tenders/edit-tender/${tender.id}`);
                }}
              />
            </PermissionHandler>
          </div>
          <div className="mr-2">
            <DeleteEntity
              service={TenderService}
              id={tender.id}
              onDeleted={onDeleted}
              isTender
              roles={admin}
            />
          </div>
        </div>
      );
    case 'published':
      return (
        <div className="flex justify-between items-center">
          <div className="mr-2">
            <EvaluateTender id={tender.id} onEvaluate={onCancelled} />
          </div>
          <div className="mr-2">
            <PermissionHandler roles={internalSystemRoles}>
              <ViewButton
                onClick={() => {
                  navigate(`/tenders/show-tender/${tender.id}`, {
                    state: {
                      status
                    }
                  });
                }}
              />
            </PermissionHandler>
          </div>
          <div className="mr-2">
            <Tooltip title={t('cancel')}>
              <CancelTender id={tender.id} onCancelled={onCancelled} />
            </Tooltip>
          </div>
        </div>
      );
    case 'canceled':
      return (
        <div className="flex justify-between items-center">
          <div className="mr-2">
            <PermissionHandler roles={internalSystemRoles}>
              <ViewButton
                onClick={() => {
                  navigate(`/tenders/show-tender/${tender.id}`, {
                    state: {
                      status
                    }
                  });
                }}
              />
            </PermissionHandler>
          </div>
        </div>
      );
    case 'closed':
      return (
        <div className="flex justify-between items-center">
          <div className="mr-2">
            <PermissionHandler roles={internalSystemRoles}>
              <ViewButton
                onClick={() => {
                  navigate(`/tenders/evaluate-tender/${tender.id}`, {
                    state: {
                      status
                    }
                  });
                }}
              />
            </PermissionHandler>
          </div>
        </div>
      );
    case 'evaluatingOffers':
      return (
        <div className="flex justify-between items-center">
          <div className="mr-2">
            <PermissionHandler roles={internalSystemRoles}>
              <DefaultButton
                title={t('evaluate')}
                sm
                label={
                  <div className="flex items-center">
                    <FaHammer />
                  </div>
                }
                bgColor="bg-orange-100"
                bgColorHover="hover:bg-orange-300"
                textColor="text-primary"
                onClick={() => {
                  navigate(`/tenders/evaluate-tender/${tender.id}`, {
                    state: {
                      status
                    }
                  });
                }}
              />
            </PermissionHandler>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default TenderActions;
