export const Countries = {
  AK: {
    code: '+7840',
    prefix: '007840',
    country: 'Abkhazia',
    flag: 'AK'
  },
  AF: {
    code: '+93',
    prefix: '0093',
    country: 'Afghanistan',
    flag: 'AF'
  },
  AL: {
    code: '+355',
    prefix: '00355',
    country: 'Albania',
    flag: 'AL'
  },
  DZ: {
    code: '+213',
    prefix: '00213',
    country: 'Algeria',
    flag: 'DZ'
  },
  AS: {
    code: '+1684',
    prefix: '001684',
    country: 'American Samoa',
    flag: 'AS'
  },
  AD: {
    code: '+376',
    prefix: '00376',
    country: 'Andorra',
    flag: 'AD'
  },
  AO: {
    code: '+244',
    prefix: '00244',
    country: 'Angola',
    flag: 'AO'
  },
  AI: {
    code: '+1264',
    prefix: '001264',
    country: 'Anguilla',
    flag: 'AI'
  },
  AG: {
    code: '+1268',
    prefix: '001268',
    country: 'Antigua and Barbuda',
    flag: 'AG'
  },
  AR: {
    code: '+54',
    prefix: '0054',
    country: 'Argentina',
    flag: 'AR'
  },
  AM: {
    code: '+374',
    prefix: '00374',
    country: 'Armenia',
    flag: 'AM'
  },
  AW: {
    code: '+297',
    prefix: '00297',
    country: 'Aruba',
    flag: 'AW'
  },
  AC: {
    code: '+247',
    prefix: '00247',
    country: 'Ascension',
    flag: 'default'
  },
  AU: {
    code: '+61',
    prefix: '0061',
    country: 'Australia',
    flag: 'AU'
  },
  AET: {
    code: '+672',
    prefix: '00672',
    country: 'Australian External Territories',
    flag: 'AU'
  },
  AT: {
    code: '+43',
    prefix: '0043',
    country: 'Austria',
    flag: 'AT'
  },
  AZ: {
    code: '+994',
    prefix: '00994',
    country: 'Azerbaijan',
    flag: 'AZ'
  },
  BS: {
    code: '+1242',
    prefix: '001242',
    country: 'Bahamas',
    flag: 'BS'
  },
  BH: {
    code: '+973',
    prefix: '00973',
    country: 'Bahrain',
    flag: 'BH'
  },
  BD: {
    code: '+880',
    prefix: '00880',
    country: 'Bangladesh',
    flag: 'BD'
  },
  BB: {
    code: '+1246',
    prefix: '001246',
    country: 'Barbados',
    flag: 'BB'
  },
  BY: {
    code: '+375',
    prefix: '00375',
    country: 'Belarus',
    flag: 'BY'
  },
  BE: {
    code: '+32',
    prefix: '0032',
    country: 'Belgium',
    flag: 'BE'
  },
  BZ: {
    code: '+501',
    prefix: '00501',
    country: 'Belize',
    flag: 'BZ'
  },
  BJ: {
    code: '+229',
    prefix: '00229',
    country: 'Benin',
    flag: 'BJ'
  },
  BM: {
    code: '+1441',
    prefix: '001441',
    country: 'Bermuda',
    flag: 'BM'
  },
  BT: {
    code: '+975',
    prefix: '00975',
    country: 'Bhutan',
    flag: 'BT'
  },
  BO: {
    code: '+591',
    prefix: '00591',
    country: 'Bolivia',
    flag: 'BO'
  },
  BA: {
    code: '+387',
    prefix: '00387',
    country: 'Bosnia and Herzegovina',
    flag: 'BA'
  },
  BW: {
    code: '+267',
    prefix: '00267',
    country: 'Botswana',
    flag: 'BW'
  },
  BR: {
    code: '+55',
    prefix: '0055',
    country: 'Brazil',
    flag: 'BR'
  },
  IO: {
    code: '+246',
    prefix: '00246',
    country: 'British Indian Ocean Territory',
    flag: 'IO'
  },
  VG: {
    code: '+1284',
    prefix: '001284',
    country: 'British Virgin Islands',
    flag: 'VG'
  },
  BN: {
    code: '+673',
    prefix: '00673',
    country: 'Brunei',
    flag: 'BN'
  },
  BG: {
    code: '+359',
    prefix: '00359',
    country: 'Bulgaria',
    flag: 'BG'
  },
  BF: {
    code: '+226',
    prefix: '00226',
    country: 'Burkina Faso',
    flag: 'BF'
  },
  BI: {
    code: '+257',
    prefix: '00257',
    country: 'Burundi',
    flag: 'BI'
  },
  KH: {
    code: '+855',
    prefix: '00855',
    country: 'Cambodia',
    flag: 'KH'
  },
  CM: {
    code: '+237',
    prefix: '00237',
    country: 'Cameroon',
    flag: 'CM'
  },
  CA: {
    code: '+1',
    prefix: '001',
    country: 'Canada',
    flag: 'CA'
  },
  CV: {
    code: '+238',
    prefix: '00238',
    country: 'Cape Verde',
    flag: 'CV'
  },
  KY: {
    code: '+345',
    prefix: '00345',
    country: 'Cayman Islands',
    flag: 'KY'
  },
  CF: {
    code: '+236',
    prefix: '00236',
    country: 'Central African Republic',
    flag: 'CF'
  },
  TD: {
    code: '+235',
    prefix: '00235',
    country: 'Chad',
    flag: 'TD'
  },
  CL: {
    code: '+56',
    prefix: '0056',
    country: 'Chile',
    flag: 'CL'
  },
  CN: {
    code: '+86',
    prefix: '0086',
    country: 'China',
    flag: 'CN'
  },
  CX: {
    code: '+61',
    prefix: '0061',
    country: 'Christmas Island',
    flag: 'CX'
  },
  CC: {
    code: '+61',
    prefix: '0061',
    country: 'Cocos-Keeling Islands',
    flag: 'CC'
  },
  CO: {
    code: '+57',
    prefix: '0057',
    country: 'Colombia',
    flag: 'CO'
  },
  KM: {
    code: '+269',
    prefix: '00269',
    country: 'Comoros',
    flag: 'KM'
  },
  CG: {
    code: '+242',
    prefix: '00242',
    country: 'Congo',
    flag: 'CG'
  },
  CD: {
    code: '+243',
    prefix: '00243',
    country: 'Congo, Dem. Rep. of (Zaire)',
    flag: 'CD'
  },
  CK: {
    code: '+682',
    prefix: '00682',
    country: 'Cook Islands',
    flag: 'CK'
  },
  CR: {
    code: '+506',
    prefix: '00506',
    country: 'Costa Rica',
    flag: 'CR'
  },
  HR: {
    code: '+385',
    prefix: '00385',
    country: 'Croatia',
    flag: 'HR'
  },
  CU: {
    code: '+53',
    prefix: '0053',
    country: 'Cuba',
    flag: 'CU'
  },
  CW: {
    code: '+599',
    prefix: '00599',
    country: 'Curacao',
    flag: 'CW'
  },
  CY: {
    code: '+537',
    prefix: '00537',
    country: 'Cyprus',
    flag: 'CY'
  },
  CZ: {
    code: '+420',
    prefix: '00420',
    country: 'Czech Republic',
    flag: 'CZ'
  },
  DK: {
    code: '+45',
    prefix: '0045',
    country: 'Denmark',
    flag: 'DK'
  },
  DJ: {
    code: '+253',
    prefix: '00253',
    country: 'Djibouti',
    flag: 'DJ'
  },
  DM: {
    code: '+1767',
    prefix: '001767',
    country: 'Dominica',
    flag: 'DM'
  },
  DO: {
    code: '+1809',
    prefix: '001809',
    country: 'Dominican Republic',
    flag: 'DO'
  },
  EC: {
    code: '+593',
    prefix: '00593',
    country: 'Ecuador',
    flag: 'EC'
  },
  EG: {
    code: '+20',
    prefix: '0020',
    country: 'Egypt',
    flag: 'EG'
  },
  SV: {
    code: '+503',
    prefix: '00503',
    country: 'El Salvador',
    flag: 'SV'
  },
  GQ: {
    code: '+240',
    prefix: '00240',
    country: 'Equatorial Guinea',
    flag: 'GQ'
  },
  ER: {
    code: '+291',
    prefix: '00291',
    country: 'Eritrea',
    flag: 'ER'
  },
  EE: {
    code: '+372',
    prefix: '00372',
    country: 'Estonia',
    flag: 'EE'
  },
  ET: {
    code: '+251',
    prefix: '00251',
    country: 'Ethiopia',
    flag: 'ET'
  },
  FK: {
    code: '+500',
    prefix: '00500',
    country: 'Falkland Islands',
    flag: 'FK'
  },
  FO: {
    code: '+298',
    prefix: '00298',
    country: 'Faroe Islands',
    flag: 'FO'
  },
  FJ: {
    code: '+679',
    prefix: '00679',
    country: 'Fiji',
    flag: 'FJ'
  },
  FI: {
    code: '+358',
    prefix: '00358',
    country: 'Finland',
    flag: 'FI'
  },
  FR: {
    code: '+33',
    prefix: '0033',
    country: 'France',
    flag: 'FR'
  },
  GF: {
    code: '+594',
    prefix: '00594',
    country: 'French Guiana',
    flag: 'GF'
  },
  PF: {
    code: '+689',
    prefix: '00689',
    country: 'French Polynesia',
    flag: 'PF'
  },
  GA: {
    code: '+241',
    prefix: '00241',
    country: 'Gabon',
    flag: 'GA'
  },
  GM: {
    code: '+220',
    prefix: '00220',
    country: 'Gambia',
    flag: 'GM'
  },
  GE: {
    code: '+995',
    prefix: '00995',
    country: 'Georgia',
    flag: 'GE'
  },
  DE: {
    code: '+49',
    prefix: '0049',
    country: 'Germany',
    flag: 'DE'
  },
  GH: {
    code: '+233',
    prefix: '00233',
    country: 'Ghana',
    flag: 'GH'
  },
  GI: {
    code: '+350',
    prefix: '00350',
    country: 'Gibraltar',
    flag: 'GI'
  },
  GR: {
    code: '+30',
    prefix: '0030',
    country: 'Greece',
    flag: 'GR'
  },
  GL: {
    code: '+299',
    prefix: '00299',
    country: 'Greenland',
    flag: 'GL'
  },
  GD: {
    code: '+1473',
    prefix: '001473',
    country: 'Grenada',
    flag: 'GD'
  },
  GP: {
    code: '+590',
    prefix: '00590',
    country: 'Guadeloupe',
    flag: 'GP'
  },
  GU: {
    code: '+1671',
    prefix: '001671',
    country: 'Guam',
    flag: 'GU'
  },
  GT: {
    code: '+502',
    prefix: '00502',
    country: 'Guatemala',
    flag: 'GT'
  },
  GN: {
    code: '+224',
    prefix: '00224',
    country: 'Guinea',
    flag: 'GN'
  },
  GW: {
    code: '+245',
    prefix: '00245',
    country: 'Guinea-Bissau',
    flag: 'GW'
  },
  GY: {
    code: '+595',
    prefix: '00595',
    country: 'Guyana',
    flag: 'GY'
  },
  HT: {
    code: '+509',
    prefix: '00509',
    country: 'Haiti',
    flag: 'HT'
  },
  HN: {
    code: '+504',
    prefix: '00504',
    country: 'Honduras',
    flag: 'HN'
  },
  HK: {
    code: '+852',
    prefix: '00852',
    country: 'Hong Kong SAR China',
    flag: 'HK'
  },
  HU: {
    code: '+36',
    prefix: '0036',
    country: 'Hungary',
    flag: 'HU'
  },
  IS: {
    code: '+354',
    prefix: '00354',
    country: 'Iceland',
    flag: 'IS'
  },
  IN: {
    code: '+91',
    prefix: '0091',
    country: 'India',
    flag: 'IN'
  },
  ID: {
    code: '+62',
    prefix: '0062',
    country: 'Indonesia',
    flag: 'ID'
  },
  IQ: {
    code: '+964',
    prefix: '00964',
    country: 'Iraq',
    flag: 'IQ'
  },
  IE: {
    code: '+353',
    prefix: '00353',
    country: 'Ireland',
    flag: 'IE'
  },
  IL: {
    code: '+972',
    prefix: '00972',
    country: 'Israel',
    flag: 'IL'
  },
  IT: {
    code: '+39',
    prefix: '0039',
    country: 'Italy',
    flag: 'IT'
  },
  CI: {
    code: '+225',
    prefix: '00225',
    country: 'Ivory Coast',
    flag: 'CI'
  },
  JM: {
    code: '+1876',
    prefix: '001876',
    country: 'Jamaica',
    flag: 'JM'
  },
  JP: {
    code: '+81',
    prefix: '0081',
    country: 'Japan',
    flag: 'JP'
  },
  JO: {
    code: '+962',
    prefix: '00962',
    country: 'Jordan',
    flag: 'JO'
  },
  KZ: {
    code: '+77',
    prefix: '0077',
    country: 'Kazakhstan',
    flag: 'KZ'
  },
  KE: {
    code: '+254',
    prefix: '00254',
    country: 'Kenya',
    flag: 'KE'
  },
  KI: {
    code: '+686',
    prefix: '00686',
    country: 'Kiribati',
    flag: 'KI'
  },
  XK: {
    code: '+383',
    prefix: '00383',
    country: 'Kosovo',
    flag: 'XK'
  },
  KW: {
    code: '+965',
    prefix: '00965',
    country: 'Kuwait',
    flag: 'KW'
  },
  KG: {
    code: '+996',
    prefix: '00996',
    country: 'Kyrgyzstan',
    flag: 'KG'
  },
  LA: {
    code: '+856',
    prefix: '00856',
    country: 'Laos',
    flag: 'LA'
  },
  LV: {
    code: '+371',
    prefix: '00371',
    country: 'Latvia',
    flag: 'LV'
  },
  LB: {
    code: '+961',
    prefix: '00961',
    country: 'Lebanon',
    flag: 'LB'
  },
  LS: {
    code: '+266',
    prefix: '00266',
    country: 'Lesotho',
    flag: 'LS'
  },
  LR: {
    code: '+231',
    prefix: '00231',
    country: 'Liberia',
    flag: 'LR'
  },
  LY: {
    code: '+218',
    prefix: '00218',
    country: 'Libya',
    flag: 'LY'
  },
  LI: {
    code: '+423',
    prefix: '00423',
    country: 'Liechtenstein',
    flag: 'LI'
  },
  LT: {
    code: '+370',
    prefix: '00370',
    country: 'Lithuania',
    flag: 'LT'
  },
  LU: {
    code: '+352',
    prefix: '00352',
    country: 'Luxembourg',
    flag: 'LU'
  },
  SAR: {
    code: '+853',
    prefix: '00853',
    country: 'Macau SAR China',
    flag: 'default'
  },
  MK: {
    code: '+389',
    prefix: '00389',
    country: 'Macedonia',
    flag: 'MK'
  },
  MG: {
    code: '+261',
    prefix: '00261',
    country: 'Madagascar',
    flag: 'MG'
  },
  MW: {
    code: '+265',
    prefix: '00265',
    country: 'Malawi',
    flag: 'MW'
  },
  MY: {
    code: '+60',
    prefix: '0060',
    country: 'Malaysia',
    flag: 'MY'
  },
  MV: {
    code: '+960',
    prefix: '00960',
    country: 'Maldives',
    flag: 'MV'
  },
  ML: {
    code: '+223',
    prefix: '00223',
    country: 'Mali',
    flag: 'ML'
  },
  MT: {
    code: '+356',
    prefix: '00356',
    country: 'Malta',
    flag: 'MT'
  },
  MH: {
    code: '+692',
    prefix: '00692',
    country: 'Marshall Islands',
    flag: 'MH'
  },
  MQ: {
    code: '+596',
    prefix: '00596',
    country: 'Martinique',
    flag: 'MQ'
  },
  MR: {
    code: '+222',
    prefix: '00222',
    country: 'Mauritania',
    flag: 'MR'
  },
  MU: {
    code: '+230',
    prefix: '00230',
    country: 'Mauritius',
    flag: 'MU'
  },
  YT: {
    code: '+262',
    prefix: '00262',
    country: 'Mayotte',
    flag: 'YT'
  },
  MX: {
    code: '+52',
    prefix: '0052',
    country: 'Mexico',
    flag: 'MX'
  },
  FM: {
    code: '+691',
    prefix: '00691',
    country: 'Micronesia',
    flag: 'FM'
  },
  MI: {
    code: '+1808',
    prefix: '001808',
    country: 'Midway Island',
    flag: 'default'
  },
  MD: {
    code: '+373',
    prefix: '00373',
    country: 'Moldova',
    flag: 'MD'
  },
  MC: {
    code: '+377',
    prefix: '00377',
    country: 'Monaco',
    flag: 'MC'
  },
  MN: {
    code: '+976',
    prefix: '00976',
    country: 'Mongolia',
    flag: 'MN'
  },
  ME: {
    code: '+382',
    prefix: '00382',
    country: 'Montenegro',
    flag: 'ME'
  },
  MS: {
    code: '+1664',
    prefix: '001664',
    country: 'Montserrat',
    flag: 'MS'
  },
  MA: {
    code: '+212',
    prefix: '00212',
    country: 'Morocco',
    flag: 'MA'
  },
  MM: {
    code: '+95',
    prefix: '0095',
    country: 'Myanmar',
    flag: 'MM'
  },
  NA: {
    code: '+264',
    prefix: '00264',
    country: 'Namibia',
    flag: 'NA'
  },
  NR: {
    code: '+674',
    prefix: '00674',
    country: 'Nauru',
    flag: 'NR'
  },
  NP: {
    code: '+977',
    prefix: '00977',
    country: 'Nepal',
    flag: 'NP'
  },
  NL: {
    code: '+31',
    prefix: '0031',
    country: 'Netherlands',
    flag: 'NL'
  },
  AN: {
    code: '+599',
    prefix: '00599',
    country: 'Netherlands Antilles',
    flag: 'AN'
  },
  KN: {
    code: '+1869',
    prefix: '001869',
    country: 'Nevis',
    flag: 'KN'
  },
  NC: {
    code: '+687',
    prefix: '00687',
    country: 'New Caledonia',
    flag: 'NC'
  },
  NZ: {
    code: '+64',
    prefix: '0064',
    country: 'New Zealand',
    flag: 'NZ'
  },
  NI: {
    code: '+505',
    prefix: '00505',
    country: 'Nicaragua',
    flag: 'NI'
  },
  NE: {
    code: '+227',
    prefix: '00227',
    country: 'Niger',
    flag: 'NE'
  },
  NG: {
    code: '+234',
    prefix: '00234',
    country: 'Nigeria',
    flag: 'NG'
  },
  NU: {
    code: '+683',
    prefix: '00683',
    country: 'Niue',
    flag: 'NU'
  },
  NF: {
    code: '+672',
    prefix: '00672',
    country: 'Norfolk Island',
    flag: 'NF'
  },
  // KR: {
  //   code: '+850',
  //   prefix: '00850',
  //   country: 'North Korea',
  //   flag: 'KR'
  // },
  MP: {
    code: '+1670',
    prefix: '001670',
    country: 'Northern Mariana Islands',
    flag: 'MP'
  },
  NO: {
    code: '+47',
    prefix: '0047',
    country: 'Norway',
    flag: 'NO'
  },
  OM: {
    code: '+968',
    prefix: '00968',
    country: 'Oman',
    flag: 'OM'
  },
  PK: {
    code: '+92',
    prefix: '0092',
    country: 'Pakistan',
    flag: 'PK'
  },
  PW: {
    code: '+680',
    prefix: '00680',
    country: 'Palau',
    flag: 'PW'
  },
  PS: {
    code: '+970',
    prefix: '00970',
    country: 'Palestinian Territory',
    flag: 'PS'
  },
  PA: {
    code: '+507',
    prefix: '00507',
    country: 'Panama',
    flag: 'PA'
  },
  PG: {
    code: '+675',
    prefix: '00675',
    country: 'Papua New Guinea',
    flag: 'PG'
  },
  PY: {
    code: '+595',
    prefix: '00595',
    country: 'Paraguay',
    flag: 'PY'
  },
  PE: {
    code: '+51',
    prefix: '0051',
    country: 'Peru',
    flag: 'PE'
  },
  PH: {
    code: '+63',
    prefix: '0063',
    country: 'Philippines',
    flag: 'PH'
  },
  PL: {
    code: '+48',
    prefix: '0048',
    country: 'Poland',
    flag: 'PL'
  },
  PT: {
    code: '+351',
    prefix: '00351',
    country: 'Portugal',
    flag: 'PT'
  },
  PR: {
    code: '+1787',
    prefix: '001787',
    country: 'Puerto Rico',
    flag: 'PR'
  },
  QA: {
    code: '+974',
    prefix: '00974',
    country: 'Qatar',
    flag: 'QA'
  },
  RE: {
    code: '+262',
    prefix: '00262',
    country: 'Reunion',
    flag: 'RE'
  },
  RO: {
    code: '+40',
    prefix: '0040',
    country: 'Romania',
    flag: 'RO'
  },
  // RU: {
  //   code: '+7',
  //   country: 'Russia',
  //   flag: 'RU',
  // },
  RW: {
    code: '+250',
    prefix: '00250',
    country: 'Rwanda',
    flag: 'RW'
  },
  WS: {
    code: '+685',
    prefix: '00685',
    country: 'Samoa',
    flag: 'WS'
  },
  SM: {
    code: '+378',
    prefix: '00378',
    country: 'San Marino',
    flag: 'SM'
  },
  SA: {
    code: '+966',
    prefix: '00966',
    country: 'Saudi Arabia',
    flag: 'SA'
  },
  SN: {
    code: '+221',
    prefix: '00221',
    country: 'Senegal',
    flag: 'SN'
  },
  RS: {
    code: '+381',
    prefix: '00381',
    country: 'Serbia',
    flag: 'RS'
  },
  SC: {
    code: '+248',
    prefix: '00248',
    country: 'Seychelles',
    flag: 'SC'
  },
  SL: {
    code: '+232',
    prefix: '00232',
    country: 'Sierra Leone',
    flag: 'SL'
  },
  SG: {
    code: '+65',
    prefix: '0065',
    country: 'Singapore',
    flag: 'SG'
  },
  SK: {
    code: '+421',
    prefix: '00421',
    country: 'Slovakia',
    flag: 'SK'
  },
  SI: {
    code: '+386',
    prefix: '00386',
    country: 'Slovenia',
    flag: 'SI'
  },
  SB: {
    code: '+677',
    prefix: '00677',
    country: 'Solomon Islands',
    flag: 'SB'
  },
  ZA: {
    code: '+27',
    prefix: '0027',
    country: 'South Africa',
    flag: 'ZA'
  },
  GS: {
    code: '+500',
    prefix: '00500',
    country: 'South Georgia and the South Sandwich Islands',
    flag: 'GS'
  },
  KP: {
    code: '+82',
    prefix: '0082',
    country: 'South Korea',
    flag: 'KP'
  },
  ES: {
    code: '+34',
    prefix: '0034',
    country: 'Spain',
    flag: 'ES'
  },
  LK: {
    code: '+94',
    prefix: '0094',
    country: 'Sri Lanka',
    flag: 'LK'
  },
  SS: {
    code: '+249',
    prefix: '00249',
    country: 'Sudan',
    flag: 'SS'
  },
  SR: {
    code: '+597',
    prefix: '00597',
    country: 'Suricountry',
    flag: 'SR'
  },
  SZ: {
    code: '+268',
    prefix: '00268',
    country: 'Swaziland',
    flag: 'SZ'
  },
  SE: {
    code: '+46',
    prefix: '0046',
    country: 'Sweden',
    flag: 'SE'
  },
  CH: {
    code: '+41',
    prefix: '0041',
    country: 'Switzerland',
    flag: 'CH'
  },
  SY: {
    code: '+963',
    prefix: '00963',
    country: 'Syria',
    flag: 'SY'
  },
  TW: {
    code: '+886',
    prefix: '00886',
    country: 'Taiwan',
    flag: 'TW'
  },
  TJ: {
    code: '+992',
    prefix: '00992',
    country: 'Tajikistan',
    flag: 'TJ'
  },
  TZ: {
    code: '+255',
    prefix: '00255',
    country: 'Tanzania',
    flag: 'TZ'
  },
  TH: {
    code: '+66',
    prefix: '0066',
    country: 'Thailand',
    flag: 'TH'
  },
  TL: {
    code: '+670',
    prefix: '00670',
    country: 'Timor Leste',
    flag: 'TL'
  },
  TG: {
    code: '+228',
    prefix: '00228',
    country: 'Togo',
    flag: 'TG'
  },
  TK: {
    code: '+690',
    prefix: '00690',
    country: 'Tokelau',
    flag: 'TK'
  },
  TO: {
    code: '+676',
    prefix: '00676',
    country: 'Tonga',
    flag: 'TO'
  },
  TT: {
    code: '+1868',
    prefix: '001868',
    country: 'Trinidad and Tobago',
    flag: 'TT'
  },
  TN: {
    code: '+216',
    prefix: '00216',
    country: 'Tunisia',
    flag: 'TN'
  },
  TR: {
    code: '+90',
    prefix: '0090',
    country: 'Turkey',
    flag: 'TR'
  },
  TM: {
    code: '+993',
    prefix: '00993',
    country: 'Turkmenistan',
    flag: 'TM'
  },
  TC: {
    code: '+1649',
    prefix: '001649',
    country: 'Turks and Caicos Islands',
    flag: 'TC'
  },
  TV: {
    code: '+688',
    prefix: '00688',
    country: 'Tuvalu',
    flag: 'TV'
  },
  VI: {
    code: '+1340',
    prefix: '001340',
    country: 'U.S. Virgin Islands',
    flag: 'VI'
  },
  UG: {
    code: '+256',
    prefix: '00256',
    country: 'Uganda',
    flag: 'UG'
  },
  UA: {
    code: '+380',
    prefix: '00380',
    country: 'Ukraine',
    flag: 'UA'
  },
  AE: {
    code: '+971',
    prefix: '00971',
    country: 'United Arab Emirates',
    flag: 'AE'
  },
  GB: {
    code: '+44',
    prefix: '0044',
    country: 'United Kingdom',
    flag: 'GB'
  },
  US: {
    code: '+1',
    prefix: '001',
    country: 'United States',
    flag: 'US'
  },
  UY: {
    code: '+598',
    prefix: '00598',
    country: 'Uruguay',
    flag: 'UY'
  },
  UZ: {
    code: '+998',
    prefix: '00998',
    country: 'Uzbekistan',
    flag: 'UZ'
  },
  VU: {
    code: '+678',
    prefix: '00678',
    country: 'Vanuatu',
    flag: 'VU'
  },
  VE: {
    code: '+58',
    prefix: '0058',
    country: 'Venezuela',
    flag: 'VE'
  },
  VN: {
    code: '+84',
    prefix: '0084',
    country: 'Vietnam',
    flag: 'VN'
  },
  WF: {
    code: '+681',
    prefix: '00681',
    country: 'Wallis and Futuna',
    flag: 'WF'
  },
  YE: {
    code: '+967',
    prefix: '00967',
    country: 'Yemen',
    flag: 'YE'
  },
  ZM: {
    code: '+260',
    prefix: '00260',
    country: 'Zambia',
    flag: 'ZM'
  },
  ZW: {
    code: '+263',
    prefix: '00263',
    country: 'Zimbabwe',
    flag: 'ZW'
  }
};

export const CountriesArray = Object.values(Countries);
