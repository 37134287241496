import React from 'react';
import ShowInvoice from '@components/Invoice/ShowInvoice';

const InvoiceActions = ({ invoice, currentRole }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="mr-2">
        <ShowInvoice invoice={invoice} currentRole={currentRole} />
      </div>
    </div>
  );
};

export default InvoiceActions;
