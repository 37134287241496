import React, { useMemo, useState } from 'react';
import Datatable from '@core/table/Datatable';
import { useTranslation } from 'react-i18next';
import TableStatistic from '@hoc/cruds/TableStatistic';
import ClientTenderService from '@services/ClientTenderService';
import PublishedTenderActions from '@components/PublishedTender/partials/PublishedTenderActions';

const PublishedTendersIndex = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState();
  const [data, setData] = useState([]);

  const headers = useMemo(
    () => [
      {
        id: 'name',
        name: t('name'),
        cell: (row) => row.name,
        sortable: true
      },
      {
        id: 'publishDate',
        name: t('publishDate'),
        cell: (row) => row.publishDate,
        sortable: true,
        minWidth: '300px'
      },
      {
        id: 'closureDate',
        name: t('closureDate'),
        cell: (row) => row.endDate,
        sortable: true,
        minWidth: '300px'
      },
      {
        id: 'evaluationDuration',
        name: t('evaluationDuration'),
        cell: (row) => <div>{`${row.offerValidityInterval} ${t('days')}`}</div>,
        sortable: true,
        minWidth: '300px'
      },
      {
        id: 'actions',
        name: t('actions'),
        cell: (row) => <PublishedTenderActions tender={row} hasOwnBids={row.hasOwnBids} />
      }
    ],
    [t]
  );

  const getData = (params) => {
    setLoading(true);
    ClientTenderService.all(params).then((response) => {
      setMetaData(response.data.data.totalRecords);
      setData(response.data.data.items);
      setLoading(false);
    });
  };

  return (
    <div className="px-12">
      <h1 className="flex border-b-2 pt-4 pb-2 mb-6 font-semibold text-2xl">
        {t('publishedTenders')}
        <div className="mt-1">
          <TableStatistic value={metaData} valueTextColor="text-primary" />
        </div>
      </h1>
      <div className="border bg-white rounded justify-between items-center">
        <div className="mx-3 my-5">
          <div className="flex w-1/2 sm:w-1/4 md:w-1/6 mt-3 mr-2" />
          <div className="hidden lg:block">
            <Datatable
              data={data}
              headers={headers}
              totalRows={metaData}
              getData={getData}
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishedTendersIndex;
