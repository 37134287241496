import React from 'react';

const Input = (props) => {
  const { id, label, placeholder, onChange, error, onFocus, value } = props;
  return (
    <div>
      <label
        className={`w-full font-inter text-base font-normal ${
          error ? 'text-red-500' : 'text-darkest-gray'
        }`}
        htmlFor={id}>
        {label}
      </label>
      <textarea
        id={id}
        autoComplete="off"
        className={`appearance-none relative block w-full mt-2 px-3 py-2 border ${
          error ? 'border-red-500' : 'border-gray-400'
        } shadow-sm placeholder-gray-500 rounded text-gray-900 focus:outline-none focus:ring-primary-500 focus:border-indigo-500 focus:z-10 text-xs sm:text-sm`}
        rows="4"
        onChange={(e) => onChange(e.target.value)}
        onClick={onFocus}
        value={value}
        placeholder={placeholder}
        aria-label={label}
      />
      {error && <div className="text-xs text-red-500 pt-2">{error}</div>}
    </div>
  );
};

export default Input;
