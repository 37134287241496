import React, { useEffect, useState } from 'react';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import { useTranslation } from 'react-i18next';
import PasswordInput from '@core/inputs/PasswordInput';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { hideSpinner, showSpinner } from '@redux/spinner/Action';
import { useDispatch } from 'react-redux';
import UserService from '@services/UserService';
import { showError, showSuccess } from '@utils/helpers';
import logo from '@assets/images/logo.png';
import useFormErrors from '@hooks/useFormErrors';
import changePasswordValidator from '@utils/validators/changePasswordValidator';

const PasswordConfirmationForm = () => {
  const query = new URLSearchParams(useLocation().search);
  const accessToken = query.get('token');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { clearError, getError, validateErrors } = useFormErrors();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = validateErrors(
      {
        password,
        confirmPassword: passwordConfirmation
      },
      changePasswordValidator
    );
    if (errors) {
      return;
    }

    dispatch(showSpinner(t('pleaseWait')));
    UserService.activate(password, passwordConfirmation, accessToken)
      .then(() => {
        showSuccess();
        navigate('/');
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  };

  useEffect(() => {
    dispatch(showSpinner(t('pleaseWait')));
    UserService.validate(accessToken)
      .catch((err) => {
        navigate('/login');
        showError(err.response.data.message);
      })
      .finally(() => {
        dispatch(hideSpinner());
      });
  }, [navigate, accessToken, dispatch, t]);

  return (
    <div className="flex justify-center min-h-screen items-center ">
      <div className="w-full shadow-xl border rounded-2xl p-6">
        <img src={logo} alt="Alb petrol logo" className="w-50 mx-auto pb-8" />
        <div className="space-y-1">
          <h2 className="text-lg font-semibold text-gray-800">{t('activateUser')}</h2>
          <p className="text-xs font-light text-gray-500">{t('enterPassword')}</p>
        </div>
        <form className=" space-y-2" onSubmit={onSubmit}>
          <div className="mt-4 mb-6 space-y-4">
            <PasswordInput
              placeholder={t('password')}
              textSize="text-xs"
              value={password}
              onChange={(value) => clearError('password', value, setPassword)}
              error={getError('password')}
            />
            <PasswordInput
              placeholder={t('confirmPassword')}
              textSize="text-xs"
              value={passwordConfirmation}
              onChange={(value) => clearError('confirmPassword', value, setPasswordConfirmation)}
              error={getError('confirmPassword')}
            />
          </div>
          <DefaultButton
            md
            type="submit"
            bgColorHover="bg-primary"
            bgColor="hover:bg-orange-600 hover:ease-in hover:duration-200"
            label={t('submit')}
          />
          <div className="pt-2 w-full text-gray-500">
            <Link to="/">{t('backToLoginPage')}</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordConfirmationForm;
