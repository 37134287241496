import React, { useCallback, useMemo } from 'react';
import { Trash } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import SearchCommission from '@components/Tender/tabs/partials/SearchCommission';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import UserStatus from '@components/User/partials/UserStatus';

const TenderCommission = ({
  disabled = false,
  data,
  onChange,
  onDelete,
  commissionDirector,
  onChangeDirector
}) => {
  const { t } = useTranslation();

  const addCommission = (commission) => onChange(commission);

  const handleDelete = useCallback(
    (id) => {
      const newCommission = data.filter((commission) => commission.id !== id);
      onDelete(newCommission);
    },
    [data, onDelete]
  );

  const handleDeleteDirector = useCallback(() => {
    onChangeDirector([]);
  }, [onChangeDirector]);

  const handleMakeDirector = useCallback(
    (director) => {
      const newCommission = [
        ...data.filter((commission) => commission.id !== director.id),
        ...commissionDirector
      ];
      onChangeDirector([{ ...director, isDirector: true }]);
      onDelete(newCommission);
    },
    [commissionDirector, data, onChangeDirector, onDelete]
  );

  const handleMakeCommissioner = useCallback(
    (director) => {
      delete director.isDirector;
      onChangeDirector([]);
      onChange(director);
    },
    [onChange, onChangeDirector]
  );

  const headers = useMemo(
    () => [
      {
        id: 'fullName',
        name: t('fullName'),
        cell: (row) => `${row.firstName} ${row.lastName}`,
        sortable: true
      },
      {
        id: 'email',
        name: t('email'),
        cell: (row) => row.email,
        sortable: true
      },
      {
        id: 'phone',
        name: t('phone'),
        cell: (row) => row.phone,
        sortable: true
      },
      {
        id: 'role',
        name: t('role'),
        cell: (row) => row.role,
        sortable: true,
        minWidth: '300px'
      },
      {
        id: 'status',
        name: t('status'),
        cell: (row) => <UserStatus status={row.status}>{row.status}</UserStatus>,
        sortable: true,
        width: '150px'
      },
      !disabled && {
        id: 'actions',
        name: t('actions'),
        width: '100px',
        show: true,
        cell: (row) => (
          <div className="flex justify-between items-center">
            <Tooltip title={t('makeDirector')}>
              <button
                className="text-emerald-600 cursor-pointer"
                onClick={() => handleMakeDirector(row)}>
                <FaArrowUp className="text-lg" />
              </button>
            </Tooltip>
            <Tooltip title={t('remove')}>
              <button
                className="ml-2 text-red-600 cursor-pointer"
                onClick={() => handleDelete(row.id)}>
                <Trash />
              </button>
            </Tooltip>
          </div>
        )
      }
    ],
    [disabled, handleDelete, handleMakeDirector, t]
  );

  const directorHeaders = useMemo(
    () => [
      {
        id: 'fullName',
        name: t('fullName'),
        cell: (row) => `${row.firstName} ${row.lastName}`,
        sortable: true
      },
      {
        id: 'email',
        name: t('email'),
        cell: (row) => row.email,
        sortable: true
      },
      {
        id: 'phone',
        name: t('phone'),
        cell: (row) => row.phone,
        sortable: true
      },
      {
        id: 'role',
        name: t('role'),
        cell: (row) => row.role,
        sortable: true,
        minWidth: '300px'
      },
      {
        id: 'status',
        name: t('status'),
        cell: (row) => <UserStatus status={row.status}>{row.status}</UserStatus>,
        sortable: true,
        width: '150px'
      },
      !disabled && {
        id: 'actions',
        name: t('actions'),
        width: '100px',
        show: true,
        cell: (row) => (
          <div className="flex justify-between items-center">
            <Tooltip title={t('makeCommissioner')}>
              <button
                className="text-blue-600 cursor-pointer"
                onClick={() => handleMakeCommissioner(row)}>
                <FaArrowDown className="text-lg" />
              </button>
            </Tooltip>
            <Tooltip title={t('remove')}>
              <button
                className="ml-2 text-red-600 cursor-pointer"
                onClick={() => handleDeleteDirector()}>
                <Trash />
              </button>
            </Tooltip>
          </div>
        )
      }
    ],
    [disabled, handleDeleteDirector, handleMakeCommissioner, t]
  );

  return (
    <div>
      <div className="text-sm font-bold mb-7 mt-7 flex items-center">{t('commissionDirector')}</div>
      <DataTable columns={directorHeaders} data={commissionDirector} />
      <div className="text-sm font-bold mb-7 mt-7 flex items-center">{t('commission')}</div>
      <DataTable columns={headers} data={data} />
      {!disabled && (
        <div className="pt-3 mt-5 border-t">
          <SearchCommission
            onAdded={addCommission}
            commissions={data}
            commissionDirector={commissionDirector}
          />
        </div>
      )}
    </div>
  );
};

export default TenderCommission;
