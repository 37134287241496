const shareHolderDataConstraints = {
  shareHolderFirstName: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_name'
    }
  },
  shareHolderLastName: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_lastname'
    }
  },
  citizenship: {
    presence: {
      allowEmpty: false,
      message: 'errors.empty_citizenship'
    }
  }
};
export default shareHolderDataConstraints;
