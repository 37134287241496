import React from 'react';
import UserDropdown from '@components/User/partials/UserDropdown';
import NavbarItem from '@core/items/NavbarItem';
import {
  FaFileInvoiceDollar,
  FaHome,
  FaNewspaper,
  FaPassport,
  FaPeopleArrows,
  FaSuitcase,
  FaTag,
  FaTags,
  FaUsers
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import logo from '@assets/images/logo.png';
import Time from '@hoc/partials/Time';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { admin, allRoles, client, internalSystemRoles } from '@constants/roles';

const Header = () => {
  const { t } = useTranslation();

  const currentRole = useSelector((state) => _.get(state, 'meReducer.role', null));

  return (
    <header className="bg-white">
      <div className="relative flex items-center h-20 shadow px-8">
        <div className="flex">
          <img src={logo} alt="Alb petrol logo" className="h-10" />
          <div className="ml-8">
            <Time />
          </div>
        </div>
        <div className="flex w-full justify-end">
          <div className="flex mr-6">
            <UserDropdown />
          </div>
        </div>
      </div>
      <nav
        className={`flex ${
          currentRole === 'klient' ? 'justify-center space-x-24' : 'justify-around'
        } items-center py-4 px-8`}>
        <NavbarItem
          label={t('home')}
          icon={<FaHome />}
          link="/"
          exact
          roles={internalSystemRoles}
        />
        <NavbarItem
          label={t('clients')}
          icon={<FaSuitcase />}
          link="/clients"
          roles={internalSystemRoles}
        />
        <NavbarItem
          label={t('tenders')}
          icon={<FaNewspaper />}
          link="/tenders"
          exact
          roles={internalSystemRoles}
        />
        <NavbarItem
          label={t('publishedTenders')}
          icon={<FaTag />}
          link="/published-tenders"
          exact
          roles={client}
        />
        <NavbarItem
          label={t('myTenders')}
          icon={<FaTags />}
          link="/my-tenders"
          exact
          roles={client}
        />
        <NavbarItem
          label={t('users')}
          icon={<FaUsers />}
          link="/users"
          exact
          roles={internalSystemRoles}
        />
        <NavbarItem
          label={t('invoices')}
          icon={<FaFileInvoiceDollar />}
          link="/invoices"
          exact
          roles={allRoles}
        />
        <NavbarItem label={t('myTenders')} icon={<FaTags />} link="/my-tenders" exact />
        <NavbarItem label={t('users')} icon={<FaUsers />} link="/users" exact />
        <NavbarItem label={t('invoices')} icon={<FaFileInvoiceDollar />} link="/invoices" exact />
        <NavbarItem label={t('documentTypes')} icon={<FaPassport />} link="/documents" exact />
        <NavbarItem
          label={t('deliveries')}
          icon={<FaPeopleArrows />}
          link="/deliveries"
          exact
          roles={admin}
        />
        <NavbarItem
          label={t('documentTypes')}
          icon={<FaPassport />}
          link="/documents"
          exact
          roles={internalSystemRoles}
        />
      </nav>
    </header>
  );
};

export default Header;
