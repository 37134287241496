import API from '@utils/plugins/API';

const DeliveryService = {
  all: (params, lotId) => {
    return API.get('deliveries', { params: { ...params, lotId } });
  },
  create: (amount, originalUnit, name, price, date, status, lotId, procurementId) => {
    return API.post('deliveries', {
      amount,
      originalUnit,
      name,
      price,
      date,
      status,
      lotId,
      procurementId
    });
  },
  update: (id, amount, originalUnit, name, price, date, status) => {
    return API.put(`deliveries/${id}`, {
      amount,
      originalUnit,
      name,
      price,
      date,
      status
    });
  },
  find: (id) => {
    return API.get(`deliveries/${id}`);
  },
  destroy: (id) => {
    return API.delete(`deliveries/${id}`);
  },
  generateInvoice: (id) => {
    return API.post(`contracts/invoices/${id}`);
  },
  sendInvoice: (id) => {
    return API.post(`contracts/invoices/${id}/send`);
  },
  downloadInvoice: (id) => {
    return API.post(`contracts/invoices/${id}/download`);
  },
  deleteInvoice: (id) => {
    return API.delete(`contracts/invoices/${id}`);
  }
};

export default DeliveryService;
