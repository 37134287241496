import React, { useState } from 'react';
import { showError, showSuccess } from '@utils/helpers';
import { FaCheck, FaTimes } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import ToggleModal from '@core/modals/ToggleModal';
import { useTranslation } from 'react-i18next';
import PermissionHandler from '@hoc/cruds/PermissionHandler';
import { admin } from '@constants/roles';
import UserService from '@services/UserService';

const ToggleUser = ({ user, onToggle }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (user.status === 'activated') {
      UserService.deactivate(user.id)
        .then(() => {
          showSuccess(t('userDeactivatedSuccessfully'));
          onToggle();
          closeModal();
        })
        .catch((err) => {
          showError(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else
      UserService.activateUser(user.id)
        .then(() => {
          showSuccess(t('userActivatedSuccessfully'));
          onToggle();
          closeModal();
        })
        .catch((err) => {
          showError(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  return (
    <PermissionHandler roles={admin}>
      {user.status === 'activated' ? (
        <DefaultButton
          bgColor="bg-red-100"
          sm
          bgColorHover="hover:bg-red-300"
          textColor="text-red-700"
          onClick={() => setShowModal(true)}
          label={<FaTimes />}
        />
      ) : (
        <DefaultButton
          bgColor="bg-emerald-100"
          sm
          bgColorHover="hover:bg-emerald-200"
          textColor="text-emerald-700"
          onClick={() => setShowModal(true)}
          label={<FaCheck />}
        />
      )}
      <ToggleModal
        title={`${user.status === 'activated' ? t('deactivateUser') : t('activateUser')}`}
        show={showModal}
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label={`${user.status === 'activated' ? t('deactivate') : t('activate')}`}>
        <div className="my-3 ml-5">
          <div className="text-lg mb-5">
            <span>{`${
              user.status === 'activated'
                ? t('areYouSureYouWantToDeactivateThisUser')
                : t('areYouSureYouWantToActivateThisUser')
            }`}</span>
          </div>
        </div>
      </ToggleModal>
    </PermissionHandler>
  );
};
export default ToggleUser;
