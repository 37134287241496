import React, { useEffect } from 'react';
import logo from '@assets/images/logo.png';
import { useTranslation } from 'react-i18next';
import { FaMobileAlt } from 'react-icons/fa';
import LanguageSelector from '@components/LanguageSelector/LanguageSelector';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const MobilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile === false) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div>
      <div className="min-h-screen flex bg-gray-100">
        <div className="flex flex-col relative h-full">
          <div className="p-3 flex justify-end">
            <LanguageSelector />
          </div>
          <img
            src={logo}
            alt="Alb petrol logo"
            className="w-60 mt-6 flex items-center mx-auto justify-center"
          />
          <div className="mt-10 mx-4 py-6 pl-6 bg-orange-100 flex">
            <FaMobileAlt className="text-7xl flex justify-center items-center self-center" />
            <div className="ml-5">{t('thisSystemOnlySupportsDesktopBrowser')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePage;
