import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Navigate, Outlet } from 'react-router';
import Layout from '@hoc/layouts/Layout';
import { isMobile } from 'react-device-detect';

const AuthRoute = () => {
  const accessToken = useSelector((state) =>
    _.get(state, 'authenticationReducer.accessToken', null)
  );

  if (isMobile) {
    return <Navigate to="/mobile" />;
  }

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default AuthRoute;
